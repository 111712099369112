import React, { useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { editAdmin, getAllRoles } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import { adminTypes } from "data";
import { getSingleAdmin } from "store/admins/actions";
import { errorMessage } from "helpers/functions";

const EditAdmin = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  // fetch driver data
  useEffect(() => {
    dispatch(getSingleAdmin(id));
    dispatch(getAllRoles());
  }, [dispatch, id]);

  const { roles } = useSelector((state) => state?.roles);

  const { loading, error, singleAdmin } = useSelector((state) => state?.admins);

  const { name, phone, email, type, role, address } = singleAdmin || {};

  const defaultValues = useMemo(() => {
    return {
      name,
      phone,
      email,
      type,
      role,
      address,
    };
  }, [name, phone, email, type, role, address]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
  });

  // setValue of the input fields when data is fetched
  useEffect(() => {
    for (const key in defaultValues) {
      if (Object.hasOwnProperty.call(defaultValues, key)) {
        const value = defaultValues[key];
        setValue(key, value);
      }
    }
  }, [singleAdmin, defaultValues, setValue]);

  const onSubmit = async (data) => {
    data["type"] = "admin";
    dispatch(editAdmin({ id, data, navigate }));
  };

  return (
    <>
      {error ? (
        "error happened"
      ) : loading ? (
        <Loader />
      ) : (
        <div className="add-project-wrp">
          <div className="add-form">
            <div className="card">
              <div className="card-head">
                <h4> تعديل بيانات سائق</h4>
              </div>
              <div className="card-body">
                <form action="#" method="get" onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col lg={6} xs={12}>
                      <div className="form-group required">
                        <h5>الإسم </h5>
                        <div>
                          <svg
                            width="13"
                            height="16"
                            viewBox="0 0 13 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5415 4.33333C2.5415 2.14721 4.31371 0.375 6.49984 0.375C8.68596 0.375 10.4582 2.14721 10.4582 4.33333C10.4582 6.51946 8.68596 8.29167 6.49984 8.29167C4.31371 8.29167 2.5415 6.51946 2.5415 4.33333ZM6.49984 6.70833C7.81151 6.70833 8.87484 5.64501 8.87484 4.33333C8.87484 3.02166 7.81151 1.95833 6.49984 1.95833C5.18816 1.95833 4.12484 3.02166 4.12484 4.33333C4.12484 5.64501 5.18816 6.70833 6.49984 6.70833Z"
                              fill="#838383"
                            />
                            <path
                              d="M2.02149 10.9383C0.833764 12.1261 0.166504 13.737 0.166504 15.4167H1.74984C1.74984 14.1569 2.25028 12.9487 3.14108 12.0579C4.03188 11.1671 5.24006 10.6667 6.49984 10.6667C7.75962 10.6667 8.9678 11.1671 9.85859 12.0579C10.7494 12.9487 11.2498 14.1569 11.2498 15.4167H12.8332C12.8332 13.737 12.1659 12.1261 10.9782 10.9383C9.79045 9.75059 8.17954 9.08333 6.49984 9.08333C4.82013 9.08333 3.20922 9.75059 2.02149 10.9383Z"
                              fill="#838383"
                            />
                          </svg>
                          <input
                            type="text"
                            className="form-control form-outline"
                            placeholder=" الإسم الأول "
                            {...register("name", { required: errorMessage })}
                          />
                        </div>
                      </div>
                      {errors?.name && (
                        <p className="error-message">{errors?.name?.message}</p>
                      )}
                    </Col>
                    <Col lg={6} xs={12}>
                      <div className="form-group required">
                        <h5>كلمه السر </h5>
                        <div>
                          <svg
                            width="13"
                            height="16"
                            viewBox="0 0 13 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5415 4.33333C2.5415 2.14721 4.31371 0.375 6.49984 0.375C8.68596 0.375 10.4582 2.14721 10.4582 4.33333C10.4582 6.51946 8.68596 8.29167 6.49984 8.29167C4.31371 8.29167 2.5415 6.51946 2.5415 4.33333ZM6.49984 6.70833C7.81151 6.70833 8.87484 5.64501 8.87484 4.33333C8.87484 3.02166 7.81151 1.95833 6.49984 1.95833C5.18816 1.95833 4.12484 3.02166 4.12484 4.33333C4.12484 5.64501 5.18816 6.70833 6.49984 6.70833Z"
                              fill="#838383"
                            />
                            <path
                              d="M2.02149 10.9383C0.833764 12.1261 0.166504 13.737 0.166504 15.4167H1.74984C1.74984 14.1569 2.25028 12.9487 3.14108 12.0579C4.03188 11.1671 5.24006 10.6667 6.49984 10.6667C7.75962 10.6667 8.9678 11.1671 9.85859 12.0579C10.7494 12.9487 11.2498 14.1569 11.2498 15.4167H12.8332C12.8332 13.737 12.1659 12.1261 10.9782 10.9383C9.79045 9.75059 8.17954 9.08333 6.49984 9.08333C4.82013 9.08333 3.20922 9.75059 2.02149 10.9383Z"
                              fill="#838383"
                            />
                          </svg>
                          <input
                            type="password"
                            className="form-control form-outline"
                            placeholder="  كلمه السر "
                            {...register("password", {
                              required: errorMessage,
                            })}
                          />
                        </div>
                      </div>

                      {errors?.password && (
                        <p className="error-message">
                          {errors?.password?.message}
                        </p>
                      )}
                    </Col>

                    <Col lg={6} xs={12}>
                      <div className="form-group required">
                        <h5> رقم الهاتف</h5>
                        <div>
                          <input
                            type="tel"
                            className="form-control form-outline"
                            {...register("phone", { required: errorMessage })}
                          />
                        </div>
                      </div>

                      {errors?.phone && (
                        <p className="error-message">
                          {errors?.phone?.message}
                        </p>
                      )}
                    </Col>
                    <Col lg={6} xs={12}>
                      <div className="form-group required">
                        <h5>العنوان </h5>
                        <div>
                          <input
                            type="text"
                            className="form-control form-outline"
                            {...register("address", { required: errorMessage })}
                          />
                        </div>
                      </div>
                      {errors?.address && (
                        <p className="error-message">
                          {errors?.address?.message}
                        </p>
                      )}
                    </Col>
                    <Col lg={6} xs={12}>
                      <div className="form-group required">
                        <h5> الايميل</h5>
                        <div>
                          <input
                            type="email"
                            className="form-control form-outline"
                            {...register("email", { required: errorMessage })}
                          />
                        </div>
                      </div>
                      {errors?.email && (
                        <p className="error-message">
                          {errors?.email?.message}
                        </p>
                      )}
                    </Col>

                    {/* <Col lg={6} xs={12}>
                      <div className="form-group required">
                        <h5> النوع</h5>
                        <div>
                          <select
                            className="form-control "
                            {...register("type", { required: errorMessage })}
                          >
                            <option value="" selected disabled hidden>
                              choose status
                            </option>
                            {adminTypes?.map((adminType) => (
                              <option
                                key={adminType?.id}
                                value={adminType?.type}
                              >
                                {adminType?.type}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {errors?.type && (
                        <p className="error-message">{errors?.type?.message}</p>
                      )}
                    </Col> */}

                    <Col lg={6} xs={12}>
                      <div className="form-group required">
                        <h5> الصلاحيه</h5>
                        <div>
                          <select
                            className="form-control "
                            {...register("role", { required: errorMessage })}
                          >
                            <option value="" selected disabled hidden>
                              choose role
                            </option>
                            {roles.map((role) => (
                              <option key={role?.id} value={role?.id}>
                                {role?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      {errors?.role && (
                        <p className="error-message">{errors?.role?.message}</p>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} xs={12}>
                      <div className="form-group">
                        <button type="submit" className="btn btn-blue">
                          حفظ
                        </button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditAdmin;
