import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addContactApi,
  deleteContactApi,
  editContactApi,
  getAllContactsApi,
  getSingleContactApi,
} from "api/contacts";

// Login Redux States
import {
  ADD_CONTACT,
  DELETE_CONTACT,
  EDIT_CONTACT,
  GET_ALL_CONTACTS,
  GET_SINGLE_CONTACT,
} from "./actionTypes";
import {
  getAllContactsSuccess,
  getAllContactsFailure,
  getSingleContactSuccess,
  getSingleContactFailure,
  deleteContactSuccess,
  deleteContactFailure,
  editContactSuccess,
  editContactFailure,
  addContactSuccess,
  addContactFailure,
} from "./actions";
import { createToaster } from "../toastify/actions";

const errorMessage = (error) =>
  error.response.data.errors[0].msg || error.response.data.errors || "error";

function* getAllContacts({ payload }) {
  try {
    const { data } = yield call(getAllContactsApi, payload);
    yield put(getAllContactsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getAllContactsFailure(error.response.data || error));
  }
}

function* getSingleContact({ payload }) {
  try {
    const { data } = yield call(getSingleContactApi, payload);
    yield put(getSingleContactSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleContactFailure(error.response.data || error));
  }
}

function* addContact({ payload }) {
  const id = Math.random();

  try {
    const { data } = yield call(addContactApi, payload.data);
    yield put(addContactSuccess(data));

    yield payload.navigate("/contacts");
  } catch (error) {
    console.log(error);
    yield put(addContactFailure(error.response.data.message || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

function* deleteContact({ payload }) {
  const id = Math.random();

  try {
    yield call(deleteContactApi, payload);
    yield put(deleteContactSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteContactFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

function* editContact({ payload }) {
  const id = Math.random();

  try {
    yield call(editContactApi, payload);
    yield put(editContactSuccess(payload));

    yield payload.navigate("/contacts");
  } catch (error) {
    console.log(error);
    yield put(editContactFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

export function* watchGetAllContacts() {
  yield takeEvery(GET_ALL_CONTACTS, getAllContacts);
}
export function* watchGetSingleContact() {
  yield takeEvery(GET_SINGLE_CONTACT, getSingleContact);
}
export function* watchDeleteContact() {
  yield takeEvery(DELETE_CONTACT, deleteContact);
}
export function* watchAddContact() {
  yield takeEvery(ADD_CONTACT, addContact);
}
export function* watchEditContact() {
  yield takeEvery(EDIT_CONTACT, editContact);
}

function* contactsSaga() {
  yield all([fork(watchGetAllContacts)]);
  yield all([fork(watchAddContact)]);
  yield all([fork(watchEditContact)]);
  yield all([fork(watchDeleteContact)]);
  yield all([fork(watchGetSingleContact)]);
}

export default contactsSaga;
