import server from "./server";

export const getAllAdminsApi = async (search) => {
  const response = await server().get(`/users${search}`);
  return response.data;
};

export const getSingleAdminApi = async (id) => {
  const response = await server().get(`/users/${id}`);
  return response.data;
};

export const addAdminApi = async ({ data }) => {
  const response = await server().post(`/users`, data);
  return response.data;
};
export const deleteAdminApi = async (id) => {
  const response = await server().delete(`/users/${id}`);
  return response.data;
};

export const editAdminApi = async ({ id, data }) => {
  const response = await server().put(`/users/${id}`, data);
  return response.data;
};
