import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { v4 } from "uuid";
import { useSelector } from "react-redux";

const COLORS = ["#2C84F3", "#4ACA6E", " #FFC353", "#f89b99"];

const barData = [
  {
    id: v4(),
    name: "ordersCount",
    color: "#2D83F4",
  },
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const ReportBlocks = () => {
  const {
    orderCompleted,
    orderUncompleted,
    orderRejected,
    orderPending,
    moreOrdersWeekDrivers: barChartData,
  } = useSelector((state) => state?.home?.data?.statistics) || {};

  const pieData = [
    { name: "طلبات تمت", value: orderCompleted },
    { name: "طلبات غير مكتمله", value: orderUncompleted },
    { name: "طلبات معلقه", value: orderPending },
    { name: "طلبات مرفوضه", value: orderRejected },
  ];

  const showBarChartComponent = barChartData?.length >= 1;
  const showBieChartComponent =
    pieData?.filter((data) => data?.value || data?.value === 0).length >=
      pieData?.length
      ? true
      : false;

  return (
    <>
      <div className="reports-blocks">
        <Row>
          {showBarChartComponent ? (
            <Col xxl={6} xl={12}>
              <div className="card">
                <h4>تقرير الطلبات</h4>
                <div
                  className="chart-body barChart"
                  style={{ width: "100%", height: 320 }}
                >
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={barChartData} barSize={20}>
                      <CartesianGrid stroke="#EFF3F9" />
                      <XAxis dataKey="driver" stroke="#979797" />
                      <YAxis dataKey="ordersCount" stroke="#979797" />
                      <Tooltip />
                      <Legend />
                      {barData?.map((bar) => (
                        <Bar
                          dataKey={bar?.name}
                          fill={bar?.color}
                          key={bar?.id}
                        />
                      ))}
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </Col>
          ) : null}

          {showBieChartComponent ? (
            <Col xxl={6} xl={12}>
              <div className="card">
                <h4>تقرير الطلبات</h4>
                <div
                  className="chart-body pieChart"
                  style={{ width: "100%", height: 320 }}
                >
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={400} height={400}>
                      <Pie
                        data={pieData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {pieData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </Col>
          ) : null}
        </Row>
      </div>
    </>
  );
};

export default ReportBlocks;
