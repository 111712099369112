import StatusLabel from "components/shared/StatusLabel/StatusLabel";
import { handlePrice } from "helpers/functions";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import styles from "./OrderDetails.module.css";
export const OrderDetails = ({ order }) => {
  const {
    status,
    user,
    driver,
    referenceId: id,
    payment: paymentName,
    totalAmount,
    totalDelivery,
    totalOffers,
    totalProducts,
  } = order || {};

  const { name: userName, phone: userPhone, email: userEmail } = user || {};
  const {
    name: driverName,
    phone: driverPhone,
    email: driverEmail,
    id: driverId,
  } = driver || {};

  const noDriverExist = !!driver;

  const statusLabelColor =
    status === "drivered" || status === "pending" || status === "noDrivers"
      ? "red"
      : "green";

  return (
    <div className="card">
      <div className={styles["card-header"]}>
        <h1>ملخص الطلب</h1>
        <p>يعرض هنا ملخص حاله الطلب و بيانات السائق</p>
      </div>
      <div className={styles["card-content"]}>
        <div className={styles["first-section"]}>
          <div className={styles["justify-between"]}>
            <p className={styles["title"]}>الحاله</p>
            <StatusLabel text={status} color={statusLabelColor} />
          </div>
          <div className={styles["justify-between"]}>
            <p className={styles["title"]}>كود الطلب</p>
            <span className={styles["sub-title"]}>{id}#</span>
          </div>

          <div className={styles["justify-between"]}>
            <p className={styles["title"]}>الدفع</p>
            <span className={styles["sub-title"]}>
              {paymentName === "cash" && <FormattedMessage id="cash" />}
              {paymentName === "online" && <FormattedMessage id="online" />}
            </span>
          </div>
          <div className={styles["justify-between"]}>
            <p className={styles["title"]}>سعر المنتجات</p>
            <span className={styles["sub-title"]}>
              {handlePrice(totalProducts)}
            </span>
          </div>
          <div className={styles["justify-between"]}>
            <p className={styles["title"]}>الخصم</p>
            <span className={styles["sub-title"]}>
              {handlePrice(totalOffers)}
            </span>
          </div>
          <div className={styles["justify-between"]}>
            <p className={styles["title"]}>رسوم الشحن</p>
            <span className={styles["sub-title"]}>
              {handlePrice(totalDelivery)}
            </span>
          </div>
          <div className={styles["justify-between"]}>
            <p className={styles["title"]}>الاجمالي</p>
            <span className={styles["sub-title"]}>
              {handlePrice(totalAmount)}
            </span>
          </div>
        </div>
        <div className={styles["second-section"]}>
          <div className={styles["user-header"]}>
            <h4>بيانات العميل</h4>
          </div>
          <div>
            <p className={styles["title"]}>اسم العميل</p>
            <span className={styles["sub-title"]}>{userName}</span>
          </div>
          <div className={styles["justify-between"]}>
            <div>
              <p className={styles["title"]}>ايميل العميل</p>
              <span className={styles["sub-title"]}>{userEmail}</span>
            </div>
            <div>
              <p className={styles["title"]}>هاتف العميل</p>
              <span className={styles["sub-title"]}>{userPhone}</span>
            </div>
          </div>
        </div>

        <div className={styles["third-section"]}>
          <div className={styles["driver-header"]}>
            <h4>بيانات السائق</h4>
          </div>
          {noDriverExist ? (
            <>
              <div>
                <p className={styles["title"]}>اسم السائق</p>
                <span className={styles["sub-title"]}>
                  <Link to={`/drivers/showdriver/${driverId}`}>
                    {driverName}
                  </Link>
                </span>
              </div>
              <div className={styles["justify-between"]}>
                <div>
                  <p className={styles["title"]}>ايميل السائق</p>
                  <span className={styles["sub-title"]}>{driverEmail}</span>
                </div>
                <div>
                  <p className={styles["title"]}>هاتف السائق</p>
                  <span className={styles["sub-title"]}>{driverPhone}</span>
                </div>
              </div>
            </>
          ) : (
            <span>لا يوجد سائق بعد</span>
          )}
        </div>
        {/* // this button will be hide until it has a functionality */}
        {false && (
          <div className={styles["button"]}>
            <button>استعلام</button>
          </div>
        )}
      </div>
    </div>
  );
};
