import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import Table from "views/drivers/Table";
import ControlArea from "components/Layout/ControlArea";
import ProjectsIcon from "assets/svgs/projectsIcon.svg";
import { getAllDrivers } from "store/actions";
import Loader from "components/shared/Loader";
import { useLocation } from "react-router-dom";
import BootyPagination from "components/shared/Pagination";

const Drivers = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const { loading } = useSelector((state) => state?.drivers) || {};
  const { drivers, metaData } = useSelector((state) => state?.drivers) || {};
  const { totalResults } = metaData || {};
  useEffect(() => {
    dispatch(getAllDrivers(search));
  }, [dispatch, search]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <ControlArea
        btnTxt="اضافه سائق"
        cardTxt={`${intl.formatMessage({
          id: "total-drivers",
        })} ${totalResults ?? ""} `}
        icon={ProjectsIcon}
        url="adddriver"
      />
      <Table data={drivers} />
      {totalResults > 10 && <BootyPagination metaData={metaData} />}
    </>
  );
};

export default Drivers;
