import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addBankApi,
  deleteBankApi,
  editBankApi,
  getAllBanksApi,
  getSingleBankApi,
} from "api/banks";

// Login Redux States
import {
  ADD_BANK,
  DELETE_BANK,
  EDIT_BANK,
  GET_ALL_BANKS,
  GET_SINGLE_BANK,
} from "./actionTypes";
import {
  getAllBanksSuccess,
  getAllBanksFailure,
  getSingleBankSuccess,
  getSingleBankFailure,
  deleteBankSuccess,
  deleteBankFailure,
  editBankSuccess,
  editBankFailure,
  addBankSuccess,
  addBankFailure,
} from "./actions";
import { createToaster } from "../toastify/actions";

const errorMessage = (error) =>
  error.response.data.errors[0].msg || error.response.data.errors || "error";

function* getAllBanks({ payload }) {
  try {
    const { data } = yield call(getAllBanksApi, payload);
    yield put(getAllBanksSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getAllBanksFailure(error.response.data || error));
  }
}

function* getSingleBank({ payload }) {
  try {
    const { data } = yield call(getSingleBankApi, payload);
    yield put(getSingleBankSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleBankFailure(error.response.data || error));
  }
}

function* addBank({ payload }) {
  const id = Math.random();

  try {
    const { data } = yield call(addBankApi, payload.data);
    yield put(addBankSuccess(data));

    yield payload.navigate("/settings/banks");
  } catch (error) {
    console.log(error);
    yield put(addBankFailure(error.response.data.message || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

function* deleteBank({ payload }) {
  const id = Math.random();

  try {
    yield call(deleteBankApi, payload);
    yield put(deleteBankSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteBankFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

function* editBank({ payload }) {
  const id = Math.random();

  try {
    yield call(editBankApi, payload);
    yield put(editBankSuccess(payload));

    yield payload.navigate("/settings/banks");
  } catch (error) {
    console.log(error);
    yield put(editBankFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

export function* watchGetAllBanks() {
  yield takeEvery(GET_ALL_BANKS, getAllBanks);
}
export function* watchGetSingleBank() {
  yield takeEvery(GET_SINGLE_BANK, getSingleBank);
}
export function* watchDeleteBank() {
  yield takeEvery(DELETE_BANK, deleteBank);
}
export function* watchAddBank() {
  yield takeEvery(ADD_BANK, addBank);
}
export function* watchEditBank() {
  yield takeEvery(EDIT_BANK, editBank);
}

function* banksSaga() {
  yield all([fork(watchGetAllBanks)]);
  yield all([fork(watchAddBank)]);
  yield all([fork(watchEditBank)]);
  yield all([fork(watchDeleteBank)]);
  yield all([fork(watchGetSingleBank)]);
}

export default banksSaga;
