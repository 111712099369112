import React from "react";
import { forwardRef } from "react";
import DataTable from "react-data-table-component";
import { Col } from "react-bootstrap";
import { FormattedDate } from "react-intl";

import styles from "./SubscriberBankAccountsDetails.module.css";
export const SubscriberBankAccountsDetails = ({ tableData }) => {
  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  const columns = [
    {
      name: "اسم الحساب",
      selector: (row) => (
        <>
          <h6>{row?.userName}</h6>
        </>
      ),
      sortable: true,
    },

    {
      name: "رمز السويفت",
      selector: (row) => <h6>{row?.swift}</h6>,
      sortable: true,
    },
    {
      name: "رقم الحساب المصرفي ",
      selector: (row) => <h6>{row?.iban}</h6>,
      sortable: true,
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>الحسابات البنكيه </h4>
                <p>
                  يمكنك الجدول من عرض البيانات الخاصه بالحسابات البنكيه الخاصه
                  بالمشترك
                </p>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={tableData}
              defaultSortField="name"
              selectableRows
              subHeader
              selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  );
};
