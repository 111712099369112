import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./StatusLabel.module.css";
const StatusLabel = ({ text, color }) => {
  const statusClasses = classNames({
    [styles["order-status"]]: true,
    [styles["green"]]: color === "green",
    [styles["orange"]]: color === "orange",
    [styles["red"]]: color === "red",
  });
  return (
    <span className={statusClasses}>
      <FormattedMessage id={`${text}`} />
    </span>
  );
};

export default StatusLabel;
