import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import { getAllPermissionsApi } from "api/permissions";

// Login Redux States
import { GET_ALL_PERMISSIONS } from "./actionTypes";
import { getAllPermissionsSuccess, getAllPermissionsFailure } from "./actions";
// import { createToaster } from "../toastify/actions";

function* getAllPermissions({ payload }) {
  try {
    const { data } = yield call(getAllPermissionsApi, payload);
    yield put(getAllPermissionsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getAllPermissionsFailure(error.response.data || error));
  }
}

export function* watchGetAllPermissions() {
  yield takeEvery(GET_ALL_PERMISSIONS, getAllPermissions);
}

function* permissionsSaga() {
  yield all([fork(watchGetAllPermissions)]);
}

export default permissionsSaga;
