import React from "react";

// helper

// styles
import styles from "./OrderLocation.module.css";
const OrderLocation = ({ destinationLocation, originLocation }) => {
  return (
    <div className={`${styles["wrapper"]} order-wrapper `}>
      <div className={styles["right-section"]}>
        <svg
          width="13"
          height="15"
          viewBox="0 0 13 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.50065 14.625C5.50072 13.7721 4.57388 12.837 3.72982 11.8296C2.46315 10.3168 0.958985 8.06368 0.958985 5.91668C0.957862 3.67432 2.30818 1.65228 4.37983 0.794116C6.45148 -0.0640456 8.83612 0.410825 10.421 1.99713C11.4632 3.0347 12.047 4.44607 12.0423 5.91668C12.0423 8.06368 10.5382 10.3168 9.27149 11.8296C8.42742 12.837 7.50058 13.7721 6.50065 14.625ZM6.50065 3.54168C5.65215 3.54168 4.8681 3.99435 4.44384 4.72918C4.01959 5.464 4.01959 6.36935 4.44384 7.10418C4.8681 7.839 5.65215 8.29168 6.50065 8.29168C7.81233 8.29168 8.87565 7.22835 8.87565 5.91668C8.87565 4.605 7.81233 3.54168 6.50065 3.54168Z"
            fill="#A3AED0"
          />
        </svg>
        <span> الشحن :</span>
        <span>{originLocation?.address}</span>
      </div>
      <div className={styles["left-section"]}>
        <svg
          width="13"
          height="15"
          viewBox="0 0 13 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.50065 14.625C5.50072 13.7721 4.57388 12.837 3.72982 11.8296C2.46315 10.3168 0.958985 8.06368 0.958985 5.91668C0.957862 3.67432 2.30818 1.65228 4.37983 0.794116C6.45148 -0.0640456 8.83612 0.410825 10.421 1.99713C11.4632 3.0347 12.047 4.44607 12.0423 5.91668C12.0423 8.06368 10.5382 10.3168 9.27149 11.8296C8.42742 12.837 7.50058 13.7721 6.50065 14.625ZM6.50065 3.54168C5.65215 3.54168 4.8681 3.99435 4.44384 4.72918C4.01959 5.464 4.01959 6.36935 4.44384 7.10418C4.8681 7.839 5.65215 8.29168 6.50065 8.29168C7.81233 8.29168 8.87565 7.22835 8.87565 5.91668C8.87565 4.605 7.81233 3.54168 6.50065 3.54168Z"
            fill="#A3AED0"
          />
        </svg>
        <span> توصيل الي :</span>
        <span>{destinationLocation?.address}</span>
      </div>
    </div>
  );
};

export default OrderLocation;
