import React from "react";
import styles from "./OrdersList.module.css";
import OrderHeader from "components/OrderShow/OrderHeader/OrderHeader";
import OrderProduct from "components/OrderShow/OrderProduct/OrderProduct";
import OrderLocation from "../OrderLocation/OrderLocation";
const OrdersList = ({ order }) => {
  const {
    products,
    referenceId,
    totalAmount,
    distinationLocation: destinationLocation,
    originLocation,
  } = order || {};

  return (
    <div className="card">
      {/* <div className={styles["card-header"]}>
        <h1>الطلبات</h1>
      </div> */}
      <div className={styles["card-content"]}>
        <div>
          <OrderHeader id={referenceId} total={totalAmount} />
        </div>
        <div>
          <OrderLocation
            destinationLocation={destinationLocation}
            originLocation={originLocation}
          />
        </div>
        <div className={styles["orders"]}>
          {products?.map((product) => (
            <OrderProduct
              singleProduct={product}
              address={destinationLocation}
              key={product?._id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrdersList;
