import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "react-bootstrap";

import ControlArea from "components/Layout/ControlArea";
import { getAllPayments } from "store/actions";
import Loader from "components/shared/Loader";
import PaymentIcon from "assets/svgs/paymentIcon.svg";
import PaymentCard from "components/Payment/PaymentCard";

const Payments = () => {
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.payments);

  const { payments } = useSelector((state) => state.payments);
  useEffect(() => {
    dispatch(getAllPayments());
  }, [dispatch]);

  const renderPaymentCards = payments.map((payment) => {
    return <PaymentCard key={payment.id} payment={payment} />;
  });

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="payment-wrap">
        <ControlArea
          btnTxt="إضافة طريقه دفع"
          cardTxt="طرق الدفع"
          icon={PaymentIcon}
          url="addpayment"
        />
        <Row>{renderPaymentCards}</Row>
      </div>
    </>
  );
};

export default Payments;
