import React from "react";
import { Modal } from "react-bootstrap";

const ModalSuccess = (props) => {
  const { show, handleClose, icon, title, children } = props;
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modal-wrp">
            {icon && (
              <div className="modal-icon">
                <img src={icon} alt="" />
              </div>
            )}
            <h4>{title}</h4>
            <div>{children}</div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalSuccess;
