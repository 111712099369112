import React from "react";
import { Col, Row } from "react-bootstrap";

// helper
import { handleImage, handlePrice } from "helpers/functions";

// styles
import styles from "./OrderProduct.module.css";
const OrderProduct = ({ singleProduct }) => {
  const { price, quantity, name, image } = singleProduct || {};

  return (
    <div className={`${styles["wrapper"]} order-wrapper `}>
      <div className={styles["product"]}>
        <Row>
          <Col xs={{ order: 1, span: 6 }} lg={2} md={3}>
            <div className={styles["image"]}>
              <img src={handleImage(image)} alt="product" />
            </div>
          </Col>
          <Col xs={{ order: 2, span: 6 }} lg={4} md={3}>
            <div>
              <p>{"اسم المنتج"} </p>
              <span>{name}</span>
            </div>
          </Col>
          <Col xs={{ order: 3, span: 6 }} lg={3} md={3}>
            <div>
              <p>العدد : </p>
              <span>{quantity}</span>
            </div>
          </Col>
          <Col xs={{ order: 4, span: 6 }} lg={3} md={3}>
            <div className={styles["price"]}>{handlePrice(price)}</div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OrderProduct;
