import {
  GET_ALL_SUBSCRIBERS,
  GET_ALL_SUBSCRIBERS_SUCCESS,
  GET_ALL_SUBSCRIBERS_FAILURE,
  GET_SINGLE_SUBSCRIBER,
  GET_SINGLE_SUBSCRIBER_SUCCESS,
  GET_SINGLE_SUBSCRIBER_FAILURE,
  DELETE_SUBSCRIBER,
  DELETE_SUBSCRIBER_SUCCESS,
  DELETE_SUBSCRIBER_FAILURE,
  ADD_SUBSCRIBER,
  ADD_SUBSCRIBER_SUCCESS,
  ADD_SUBSCRIBER_FAILURE,
  CREATE_SUBSCRIBER_TRANSACTION,
  CREATE_SUBSCRIBER_TRANSACTION_SUCCESS,
  CREATE_SUBSCRIBER_TRANSACTION_FAILURE,
  UPDATE_SUBSCRIBER_TRANSACTION,
  UPDATE_SUBSCRIBER_TRANSACTION_SUCCESS,
  UPDATE_SUBSCRIBER_TRANSACTION_FAILURE,
  DELETE_SUBSCRIBER_TRANSACTION,
  DELETE_SUBSCRIBER_TRANSACTION_SUCCESS,
  DELETE_SUBSCRIBER_TRANSACTION_FAILURE,
} from "./actionTypes";

const initialState = {
  subscribers: [],
  metaData: {},
  singleSubscriber: {},
  loading: false,
  error: "",
};

const subscriber = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_SUBSCRIBERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ALL_SUBSCRIBERS_SUCCESS: {
      const { subscribers, metadata: metaData } = payload;
      state = {
        ...state,
        loading: false,
        subscribers: subscribers,
        metaData: metaData,
      };
      break;
    }
    case GET_ALL_SUBSCRIBERS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    case GET_SINGLE_SUBSCRIBER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_SUBSCRIBER_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleSubscriber: payload.subscriber,
      };
      break;
    case GET_SINGLE_SUBSCRIBER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
        singleSubscriber: {},
      };
      break;

    case ADD_SUBSCRIBER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_SUBSCRIBER_SUCCESS: {
      const subscriberArrayAfterAddingSubscribers = [
        ...state?.subscribers,
        payload.subscriber,
      ];

      state = {
        ...state,
        loading: false,
        subscribers: subscriberArrayAfterAddingSubscribers,
        error: "",
      };
      break;
    }
    case ADD_SUBSCRIBER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    case DELETE_SUBSCRIBER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_SUBSCRIBER_SUCCESS:
      const subscriberArrayAfterDeletingSubscribers = [
        ...state?.subscribers?.filter(
          (subscriber) => subscriber?.id !== payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        subscribers: subscriberArrayAfterDeletingSubscribers,
        metaData: {
          ...state?.metaData,
          totalResults: state?.metaData?.totalResults - 1,
        },
      };
      break;
    case DELETE_SUBSCRIBER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    // transactions

    case CREATE_SUBSCRIBER_TRANSACTION: {
      state = {
        ...state,
        loading: true,
      };
      break;
    }
    case CREATE_SUBSCRIBER_TRANSACTION_SUCCESS: {
      const { transaction } = payload || {};
      const subscriberTransactionsArrayAfterAddingTransaction = [
        ...state?.singleSubscriber?.transactions,
        transaction,
      ];
      state = {
        ...state,
        loading: false,
        singleSubscriber: {
          ...state?.singleSubscriber,
          transactions: subscriberTransactionsArrayAfterAddingTransaction,
        },
        error: "",
      };
      break;
    }
    case CREATE_SUBSCRIBER_TRANSACTION_FAILURE: {
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    }

    case UPDATE_SUBSCRIBER_TRANSACTION: {
      state = {
        ...state,
        loading: true,
      };
      break;
    }
    case UPDATE_SUBSCRIBER_TRANSACTION_SUCCESS: {
      const { transactionId } = payload || {};
      const subscribersArrayAfterUpdatingSubscriber =
        state.singleSubscriber?.transactions.map((transaction) => {
          if (transaction?.id === transactionId) {
            return payload;
          } else {
            return transaction;
          }
        });

      state = {
        ...state,
        loading: false,
        singleSubscriber: subscribersArrayAfterUpdatingSubscriber,
        error: "",
      };
      break;
    }
    case UPDATE_SUBSCRIBER_TRANSACTION_FAILURE: {
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    }
    case DELETE_SUBSCRIBER_TRANSACTION: {
      state = {
        ...state,
        loading: true,
      };
      break;
    }
    case DELETE_SUBSCRIBER_TRANSACTION_SUCCESS: {
      const subscriberTransactionsArrayAfterDeletingTransaction =
        state.singleSubscriber?.transactions.filter(
          (transaction) => transaction?.id !== payload
        );

      state = {
        ...state,
        loading: false,
        singleSubscriber: {
          ...state?.singleSubscriber,
          transactions: subscriberTransactionsArrayAfterDeletingTransaction,
        },
      };
      break;
    }
    case DELETE_SUBSCRIBER_TRANSACTION_FAILURE: {
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    }

    default:
      state = { ...state };
      break;
  }

  return state;
};

export default subscriber;
