import server from "./server";

export const getAllContactsApi = async (search) => {
  const response = await server().get(`/contacts${search}`);
  return response.data;
};

export const getSingleContactApi = async (id) => {
  const response = await server().get(`/contacts/${id}`);
  return response.data;
};

export const addContactApi = async (payload) => {
  const response = await server().post(`/contacts`, payload);
  return response.data;
};
export const deleteContactApi = async (id) => {
  const response = await server().delete(`/contacts/${id}`);
  return response.data;
};

export const editContactApi = async ({ id, data }) => {
  const response = await server().put(`/contacts/${id}`, data);
  return response.data;
};
