import server from "./server";

export const getAllTransactionRequestsApi = async (search) => {
  if (!search) search = "";
  const response = await server().get(`/drivers/requestTransactions${search}`);
  return response.data;
};

export const getSingleTransactionRequestApi = async (id) => {
  const response = await server().get(`/drivers/requestTransactions/${id}`);
  return response.data;
};

// approve TransactionRequest api
export const approveTransactionRequestApi = async ({ id }) => {
  const response = await server().post(
    `/drivers/requestTransactions/approve/${id}`
  );
  return response.data;
};

// reject TransactionRequest api
export const rejectTransactionRequestApi = async ({ id }) => {
  const response = await server().post(
    `/drivers/requestTransactions/reject/${id}`
  );
  return response.data;
};
