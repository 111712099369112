import {
  GET_ALL_PAYMENTS,
  GET_ALL_PAYMENTS_SUCCESS,
  GET_ALL_PAYMENTS_FAILURE,
  GET_SINGLE_PAYMENT,
  GET_SINGLE_PAYMENT_SUCCESS,
  GET_SINGLE_PAYMENT_FAILURE,
  DELETE_PAYMENT,
  DELETE_PAYMENT_SUCCESS,
  DELETE_PAYMENT_FAILURE,
  ADD_PAYMENT,
  ADD_PAYMENT_SUCCESS,
  ADD_PAYMENT_FAILURE,
} from "./actionTypes";

const initialState = {
  payments: [],
  singlePayment: {},
  loading: false,
  error: "",
};

const payment = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_PAYMENTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ALL_PAYMENTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        payments: payload.payments,
      };
      break;
    case GET_ALL_PAYMENTS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    case GET_SINGLE_PAYMENT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_PAYMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        singlePayment: payload.payment,
      };
      break;
    case GET_SINGLE_PAYMENT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
        singlePayment: {},
      };
      break;

    case ADD_PAYMENT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_PAYMENT_SUCCESS:
      const paymentArrayAfterAddingPayment = [
        ...state?.payments,
        payload.payments,
      ];
      state = {
        ...state,
        loading: false,
        payments: paymentArrayAfterAddingPayment,
        error: "",
      };
      break;
    case ADD_PAYMENT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    case DELETE_PAYMENT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_PAYMENT_SUCCESS:
      const paymentArrayAfterDeletingPayment = [
        ...state?.payments?.filter((payment) => payment?.id !== payload),
      ];
      state = {
        ...state,
        loading: false,
        payments: paymentArrayAfterDeletingPayment,
      };
      break;
    case DELETE_PAYMENT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default payment;
