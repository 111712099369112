export const GET_ALL_SUBSCRIBERS = "GET_ALL_SUBSCRIBERS";
export const GET_ALL_SUBSCRIBERS_SUCCESS = "GET_ALL_SUBSCRIBERS_SUCCESS";
export const GET_ALL_SUBSCRIBERS_FAILURE = "GET_ALL_SUBSCRIBERS_FAILURE";

export const GET_SINGLE_SUBSCRIBER = "GET_SINGLE_SUBSCRIBER";
export const GET_SINGLE_SUBSCRIBER_SUCCESS = "GET_SINGLE_SUBSCRIBER_SUCCESS";
export const GET_SINGLE_SUBSCRIBER_FAILURE = "GET_SINGLE_SUBSCRIBER_FAILURE";

export const ADD_SUBSCRIBER = "ADD_SUBSCRIBER";
export const ADD_SUBSCRIBER_SUCCESS = "ADD_SUBSCRIBER_SUCCESS";
export const ADD_SUBSCRIBER_FAILURE = "ADD_SUBSCRIBER_FAILURE";

export const EDIT_SUBSCRIBER = "EDIT_SUBSCRIBER";
export const EDIT_SUBSCRIBER_SUCCESS = "EDIT_SUBSCRIBER_SUCCESS";
export const EDIT_SUBSCRIBER_FAILURE = "EDIT_SUBSCRIBER_FAILURE";

export const DELETE_SUBSCRIBER = "DELETE_SUBSCRIBER";
export const DELETE_SUBSCRIBER_SUCCESS = "DELETE_SUBSCRIBER_SUCCESS";
export const DELETE_SUBSCRIBER_FAILURE = "DELETE_SUBSCRIBER_FAILURE";

// code generation
export const REGENERATE_SUBSCRIBER_TOKEN = "REGENERATE_SUBSCRIBER_TOKEN";
export const REGENERATE_SUBSCRIBER_TOKEN_SUCCESS =
  "REGENERATE_SUBSCRIBER_TOKEN_SUCCESS";
export const REGENERATE_SUBSCRIBER_TOKEN_FAILURE =
  "REGENERATE_SUBSCRIBER_TOKEN_FAILURE";

// subscriber transaction

export const CREATE_SUBSCRIBER_TRANSACTION = "CREATE_SUBSCRIBER_TRANSACTION";
export const CREATE_SUBSCRIBER_TRANSACTION_SUCCESS =
  "CREATE_SUBSCRIBER_TRANSACTION_SUCCESS";
export const CREATE_SUBSCRIBER_TRANSACTION_FAILURE =
  "CREATE_SUBSCRIBER_TRANSACTION_FAILURE";

export const UPDATE_SUBSCRIBER_TRANSACTION = "UPDATE_SUBSCRIBER_TRANSACTION";
export const UPDATE_SUBSCRIBER_TRANSACTION_SUCCESS =
  "UPDATE_SUBSCRIBER_TRANSACTION_SUCCESS";
export const UPDATE_SUBSCRIBER_TRANSACTION_FAILURE =
  "UPDATE_SUBSCRIBER_TRANSACTION_FAILURE";

export const DELETE_SUBSCRIBER_TRANSACTION = "DELETE_SUBSCRIBER_TRANSACTION";
export const DELETE_SUBSCRIBER_TRANSACTION_SUCCESS =
  "DELETE_SUBSCRIBER_TRANSACTION_SUCCESS";
export const DELETE_SUBSCRIBER_TRANSACTION_FAILURE =
  "DELETE_SUBSCRIBER_TRANSACTION_FAILURE";
