import React from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addContact } from "store/actions";
import { useNavigate } from "react-router-dom";
import { errorMessage } from "helpers/functions";

const AddContact = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    dispatch(addContact({ data, navigate }));
  };

  return (
    <>
      <div className="add-project-wrp">
        <div className="add-form">
          <div className="card">
            <div className="card-head">
              <h4> اضافه بيان اتصال</h4>
            </div>
            <div className="card-body">
              <form action="#" method="get" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col lg={12}>
                    <div className="form-group required">
                      <h5>الرساله </h5>
                      <div>
                        <svg
                          width="13"
                          height="16"
                          viewBox="0 0 13 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.5415 4.33333C2.5415 2.14721 4.31371 0.375 6.49984 0.375C8.68596 0.375 10.4582 2.14721 10.4582 4.33333C10.4582 6.51946 8.68596 8.29167 6.49984 8.29167C4.31371 8.29167 2.5415 6.51946 2.5415 4.33333ZM6.49984 6.70833C7.81151 6.70833 8.87484 5.64501 8.87484 4.33333C8.87484 3.02166 7.81151 1.95833 6.49984 1.95833C5.18816 1.95833 4.12484 3.02166 4.12484 4.33333C4.12484 5.64501 5.18816 6.70833 6.49984 6.70833Z"
                            fill="#838383"
                          />
                          <path
                            d="M2.02149 10.9383C0.833764 12.1261 0.166504 13.737 0.166504 15.4167H1.74984C1.74984 14.1569 2.25028 12.9487 3.14108 12.0579C4.03188 11.1671 5.24006 10.6667 6.49984 10.6667C7.75962 10.6667 8.9678 11.1671 9.85859 12.0579C10.7494 12.9487 11.2498 14.1569 11.2498 15.4167H12.8332C12.8332 13.737 12.1659 12.1261 10.9782 10.9383C9.79045 9.75059 8.17954 9.08333 6.49984 9.08333C4.82013 9.08333 3.20922 9.75059 2.02149 10.9383Z"
                            fill="#838383"
                          />
                        </svg>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder=" الإسم  "
                          {...register("message", { required: errorMessage })}
                        />
                      </div>
                    </div>
                    {errors?.name && (
                      <p className="error-message">{errors?.name?.message}</p>
                    )}
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        {"حفظ"}
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddContact;
