import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router";

import GuestRoute from "helpers/authentication/guestRoute";
import AuthRoute from "helpers/authentication/authRoute";

import Layout from "./components/Layout/Layout";
import Dashboard from "views/dashboard";
import NotFound from "views/NotFound";
import Login from "views/login";
import Logout from "views/Logout";
import { getCurrentUser, getSingleSetting } from "store/actions";
import Loader from "./components/shared/Loader";

import DriversIndex from "views/drivers/Index";
import AddDriver from "views/drivers/AddDriver";
import EditDriver from "views/drivers/EditDriver";
import ShowDriver from "views/drivers/ShowDriver";

import CreateDriverTransaction from "views/drivers/driverTransaction/CreateDriverTransaction";
import UpdateDriverTransaction from "views/drivers/driverTransaction/UpdateDriverTransaction";
import ShowDriverTransaction from "views/drivers/driverTransaction/ShowDriverTransaction";

import BanksIndex from "views/banks/Index";
import AddBank from "views/banks/AddBank";
import EditBank from "views/banks/EditBank";

import UnderReviewDrivers from "views/drivers/underReviewDrivers/UnderReviewDrivers";
import ApproveDriver from "views/drivers/underReviewDrivers/ApproveDriver";

import Payments from "views/payments/Index";
import AddPayment from "views/payments/AddPayment";

import AdminsIndex from "views/admins/Index";
import AddAdmin from "views/admins/AddAdmin";
import EditAdmin from "views/admins/EditAdmin";

import OrdersIndex from "views/orders/Index";
import AddOrder from "views/orders/AddOrder";
import ShowOrder from "views/orders/ShowOrder";

import AddRole from "views/roles/AddRole";
import EditRole from "views/roles/EditRole";
import RolesIndex from "views/roles/Index";

import SubscribersIndex from "views/subscribers/Index";
import AddSubscriber from "views/subscribers/AddSubscriber";
import EditSubscriber from "views/subscribers/EditSubscriber";
import ShowSubscriber from "views/subscribers/ShowSubscriber";

import { checkIfUserHasPermission } from "helpers/functions";
import GeneralSettings from "views/settings/GeneralSettings";
import SocialSettings from "views/settings/SocialSettings";
import SettingsIndex from "views/settings/Index";

import CreateSubscriberTransaction from "views/subscribers/subscriberTransaction/CreateSubscriberTransaction";
import UpdateSubscriberTransaction from "views/subscribers/subscriberTransaction/UpdateSubscriberTransaction";
import ShowSubscriberTransaction from "views/subscribers/subscriberTransaction/ShowSubscriberTransaction";
import Contacts from "views/contacts/Index";
import AddContact from "views/contacts/AddContact";
import TransactionRequests from "views/transactionRequests/Index";
import ShowTransactionRequest from "views/transactionRequests/ShowTransactionRequest";

const App = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  //selectors
  const { isLoggedIn, user, loading } = useSelector(
    (state) => state?.authentication
  );
  const { singleSetting } = useSelector((state) => state?.settings);

  let logo = {};

  if (Object.keys(singleSetting).length > 0) {
    logo = singleSetting?.find((obj) => obj?.title === "appLogo") || {};
  }

  const permissions = user?.role?.permissions;

  useEffect(() => {
    dispatch(getSingleSetting("main"));
    if (token) dispatch(getCurrentUser());
  }, [token, dispatch]);
  const RenderApp = () => {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout logo={logo?.value} />}>
              <Route
                path="/"
                element={
                  <AuthRoute>
                    <Dashboard />
                  </AuthRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <AuthRoute>
                    <Dashboard />
                  </AuthRoute>
                }
              />
              {/*  payment  */}
              <Route
                path="/payments"
                element={
                  <AuthRoute>
                    <Payments />
                  </AuthRoute>
                }
              />
              <Route
                path="/payments/addpayment"
                element={
                  <AuthRoute>
                    <AddPayment />
                  </AuthRoute>
                }
              />
              {/*  drivers  */}
              {checkIfUserHasPermission(permissions, "drivers-index") && (
                <Route
                  path="/drivers"
                  element={
                    <AuthRoute>
                      <DriversIndex />
                    </AuthRoute>
                  }
                ></Route>
              )}
              {/* // under review drivers   */}
              <Route
                path="/drivers/underreview"
                element={
                  <AuthRoute>
                    <UnderReviewDrivers />
                  </AuthRoute>
                }
              />
              {checkIfUserHasPermission(permissions, "drivers-approve") && (
                <Route
                  path="/drivers/underreview/:id"
                  element={
                    <AuthRoute>
                      <ApproveDriver />
                    </AuthRoute>
                  }
                />
              )}
              {checkIfUserHasPermission(permissions, "drivers-update") && (
                <Route
                  path="/drivers/:id"
                  element={
                    <AuthRoute>
                      <EditDriver />
                    </AuthRoute>
                  }
                />
              )}
              {checkIfUserHasPermission(permissions, "drivers-store") && (
                <Route
                  path="/drivers/adddriver"
                  element={
                    <AuthRoute>
                      <AddDriver />
                    </AuthRoute>
                  }
                />
              )}
              {checkIfUserHasPermission(permissions, "drivers-show") && (
                <Route
                  path="/drivers/showdriver/:id"
                  element={
                    <AuthRoute>
                      <ShowDriver />
                    </AuthRoute>
                  }
                />
              )}
              {checkIfUserHasPermission(
                permissions,
                "driverTransactions-store"
              ) && (
                  <Route
                    path="/drivers/addtransaction/:id"
                    element={
                      <AuthRoute>
                        <CreateDriverTransaction />
                      </AuthRoute>
                    }
                  />
                )}
              {checkIfUserHasPermission(
                permissions,
                "driverTransactions-update"
              ) && (
                  <Route
                    path="/drivers/:driverId/updatetransaction/:transactionId"
                    element={
                      <AuthRoute>
                        <UpdateDriverTransaction />
                      </AuthRoute>
                    }
                  />
                )}
              {checkIfUserHasPermission(
                permissions,
                "driverTransactions-index"
              ) && (
                  <Route
                    path="/drivers/:driverId/showtransaction/:transactionId"
                    element={
                      <AuthRoute>
                        <ShowDriverTransaction />
                      </AuthRoute>
                    }
                  />
                )}

              {/*  banks  */}
              {checkIfUserHasPermission(permissions, "banks-index") && (
                <Route
                  path="/settings/banks"
                  element={
                    <AuthRoute>
                      <BanksIndex />
                    </AuthRoute>
                  }
                ></Route>
              )}
              {checkIfUserHasPermission(permissions, "banks-update") && (
                <Route
                  path="/settings/banks/:id"
                  element={
                    <AuthRoute>
                      <EditBank />
                    </AuthRoute>
                  }
                />
              )}
              {checkIfUserHasPermission(permissions, "banks-store") && (
                <Route
                  path="/settings/banks/addbank"
                  element={
                    <AuthRoute>
                      <AddBank />
                    </AuthRoute>
                  }
                />
              )}

              {/*  admins  */}
              {checkIfUserHasPermission(permissions, "admins-index") && (
                <Route
                  path="/admins"
                  element={
                    <AuthRoute>
                      <AdminsIndex />
                    </AuthRoute>
                  }
                />
              )}
              {checkIfUserHasPermission(permissions, "admins-store") && (
                <Route
                  path="/admins/addadmin"
                  element={
                    <AuthRoute>
                      <AddAdmin />
                    </AuthRoute>
                  }
                />
              )}
              {checkIfUserHasPermission(permissions, "admins-update") && (
                <Route
                  path="/admins/:id"
                  element={
                    <AuthRoute>
                      <EditAdmin />
                    </AuthRoute>
                  }
                />
              )}
              {/*  orders  */}
              {checkIfUserHasPermission(permissions, "orders-index") && (
                <Route
                  path="/orders"
                  element={
                    <AuthRoute>
                      <OrdersIndex />
                    </AuthRoute>
                  }
                />
              )}
              {checkIfUserHasPermission(permissions, "orders-store") && (
                <Route
                  path="/orders/addorder"
                  element={
                    <AuthRoute>
                      <AddOrder />
                    </AuthRoute>
                  }
                />
              )}
              {checkIfUserHasPermission(permissions, "orders-show") && (
                <Route
                  path="/orders/:id"
                  element={
                    <AuthRoute>
                      <ShowOrder />
                    </AuthRoute>
                  }
                />
              )}

              {/*  roles  */}
              <Route
                path="/settings/roles"
                element={
                  <AuthRoute>
                    <RolesIndex />
                  </AuthRoute>
                }
              />
              <Route
                path="/settings/roles/addrole"
                element={
                  <AuthRoute>
                    <AddRole />
                  </AuthRoute>
                }
              />
              <Route
                path="/settings/roles/:id"
                element={
                  <AuthRoute>
                    <EditRole />
                  </AuthRoute>
                }
              />
              {/*  subscribers  */}
              <Route
                path="/subscribers"
                element={
                  <AuthRoute>
                    <SubscribersIndex />
                  </AuthRoute>
                }
              />
              <Route
                path="/subscribers/addsubscriber"
                element={
                  <AuthRoute>
                    <AddSubscriber />
                  </AuthRoute>
                }
              />
              <Route
                path="/subscribers/:id"
                element={
                  <AuthRoute>
                    <EditSubscriber />
                  </AuthRoute>
                }
              />
              <Route
                path="/subscribers/showsubscriber/:subscriberId"
                element={
                  <AuthRoute>
                    <ShowSubscriber />
                  </AuthRoute>
                }
              />

              {/* // transaction  */}

              <Route
                path="/subscribers/:subscriberId/addtransaction"
                element={
                  <AuthRoute>
                    <CreateSubscriberTransaction />
                  </AuthRoute>
                }
              />
              <Route
                path="/subscribers/:subscriberId/updatetransaction/:transactionId"
                element={
                  <AuthRoute>
                    <UpdateSubscriberTransaction />
                  </AuthRoute>
                }
              />
              <Route
                path="/subscribers/:subscriberId/showtransaction/:transactionId"
                element={
                  <AuthRoute>
                    <ShowSubscriberTransaction />
                  </AuthRoute>
                }
              />

              {/*  settings  */}
              {checkIfUserHasPermission(permissions, "settings-index") && (
                <Route
                  path="/settings"
                  element={
                    <AuthRoute>
                      {/* <SettingsIndex /> */}
                      <GeneralSettings />
                    </AuthRoute>
                  }
                />
              )}
              {checkIfUserHasPermission(permissions, "settings-update") && (
                <>
                  <Route
                    path="/settings/main"
                    element={
                      <AuthRoute>
                        <GeneralSettings />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/settings/social"
                    element={
                      <AuthRoute>
                        <SocialSettings />
                      </AuthRoute>
                    }
                  />
                </>
              )}

              {/* // contacts  */}
              <Route
                path="/settings/contacts"
                element={
                  <AuthRoute>
                    <Contacts />
                  </AuthRoute>
                }
              />
              <Route
                path="/settings/contacts/addcontact"
                element={
                  <AuthRoute>
                    <AddContact />
                  </AuthRoute>
                }
              />

              {/* // transaction requests  */}
              <Route
                path="/transactionrequests"
                element={
                  <AuthRoute>
                    <TransactionRequests />
                  </AuthRoute>
                }
              />
              <Route
                path="/transactionrequests/:transactionRequestId"
                element={
                  <AuthRoute>
                    <ShowTransactionRequest />
                  </AuthRoute>
                }
              />

              <Route path="*" element={<NotFound />} />
            </Route>
            <Route
              path="/logout"
              element={
                <AuthRoute>
                  <Logout />
                </AuthRoute>
              }
            />
            <Route
              path="/login"
              element={
                <GuestRoute>
                  <Login logo={logo?.value} />
                </GuestRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </Suspense>
    );
  };
  if (!token) return RenderApp();
  if (isLoggedIn !== null && loading === false) return RenderApp();
  return <Loader />;
};

export default App;
