import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./SubscriberPersonalInfo.module.css";
import { FormattedDate } from "react-intl";

const SubscriberPersonalInfo = ({ singleSubscriber }) => {
  const { name, createdAt, balance, logo, address, status } =
    singleSubscriber || {};
  return (
    // SubscriberPersonalInfo
    <div className={`${styles["card-container"]} subscriber-personal-info `}>
      <div className={`card ${styles["card-margin"]}`}>
        <Row>
          <Col>
            <div className={styles["wrapper"]}>
              <div className={styles["image"]}>
                <img src={logo} alt="driver" />
              </div>
              <div className={styles["info"]}>
                <h4>{name}</h4>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={6} xs={12} style={{ marginTop: "10px" }}>
            <div className={styles["details"]}>
              <h4>الاسم</h4>
              <p>{name}</p>
            </div>
            <div className={`${styles["details"]} ${styles["top-margin"]}  `}>
              <h4>العنوان : </h4>
              <p>{address}</p>
            </div>
          </Col>
          <Col lg={6} md={6} xs={12} style={{ marginTop: "10px" }}>
            <div className={styles["details"]}>
              <h4>الحاله</h4>
              <p>{status}</p>
            </div>

            <div className={`${styles["details"]} ${styles["top-margin"]}  `}>
              <h4>تاريخ الانضمام : </h4>
              <p>{<FormattedDate value={createdAt} />}</p>
            </div>
          </Col>
          <Col lg={2} md={6} xs={12} style={{ marginTop: "10px" }}>
            <div
              className={`${styles["details"]} ${styles["red-text"]} ${styles["top-margin"]}`}
            >
              <h4>الرصيد : </h4>
              <p>{balance}</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SubscriberPersonalInfo;
