export const GET_ALL_TRANSACTION_REQUESTS = "GET_ALL_TRANSACTION_REQUESTS";
export const GET_ALL_TRANSACTION_REQUESTS_SUCCESS =
  "GET_ALL_TRANSACTION_REQUESTS_SUCCESS";
export const GET_ALL_TRANSACTION_REQUESTS_FAILURE =
  "GET_ALL_TRANSACTION_REQUESTS_FAILURE";

export const GET_SINGLE_TRANSACTION_REQUEST = "GET_SINGLE_TRANSACTION_REQUEST";
export const GET_SINGLE_TRANSACTION_REQUEST_SUCCESS =
  "GET_SINGLE_TRANSACTION_REQUEST_SUCCESS";
export const GET_SINGLE_TRANSACTION_REQUEST_FAILURE =
  "GET_SINGLE_TRANSACTION_REQUEST_FAILURE";

// approve transaction request

export const APPROVE_TRANSACTION_REQUEST = "APPROVE_TRANSACTION_REQUEST";
export const APPROVE_TRANSACTION_REQUEST_SUCCESS =
  "APPROVE_TRANSACTION_REQUEST_SUCCESS";
export const APPROVE_TRANSACTION_REQUEST_FAILURE =
  "APPROVE_TRANSACTION_REQUEST_FAILURE";

// reject transaction request

export const REJECT_TRANSACTION_REQUEST = "REJECT_TRANSACTION_REQUEST";
export const REJECT_TRANSACTION_REQUEST_SUCCESS =
  "REJECT_TRANSACTION_REQUEST_SUCCESS";
export const REJECT_TRANSACTION_REQUEST_FAILURE =
  "REJECT_TRANSACTION_REQUEST_FAILURE";
