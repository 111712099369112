import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useIntl } from "react-intl";

import Table from "views/contacts/Table";
// import ControlArea from "components/Layout/ControlArea";
// import ProjectsIcon from "assets/svgs/projectsIcon.svg";
import { getAllContacts } from "store/actions";
import Loader from "components/shared/Loader";
import { useLocation } from "react-router-dom";
import BootyPagination from "components/shared/Pagination";

const Contacts = () => {

  const dispatch = useDispatch();
  let { search } = useLocation();
  const { loading } = useSelector((state) => state?.contacts) || {};
  const { contacts, metaData } = useSelector((state) => state?.contacts) || {};
  const { totalResults } = metaData || {};

  useEffect(() => {
    dispatch(getAllContacts(search));
  }, [dispatch, search]);

  return loading ? (
    <Loader />
  ) : (
    <>
      {/* <ControlArea
        btnTxt="اضافه متصل"
        cardTxt={`${intl.formatMessage({
          id: "total-contacts",
        })} ${totalResults ?? ""} `}
        icon={ProjectsIcon}
        url="addcontact"
      /> */}
      <Table data={contacts} />
      {totalResults > 10 && <BootyPagination metaData={metaData} />}
    </>
  );
};

export default Contacts;
