import React, { useMemo, useState, forwardRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router";

import { projectsTableHead, SelectFilterOptions } from "data";
import { useDispatch, useSelector } from "react-redux";
import { deleteTransactionRequest } from "store/actions";
import {
  approveTransactionRequest,
  rejectTransactionRequest,
} from "store/transactionRequests/actions";

// components
import Filter from "components/shared/Filter";
import ModalSuccess from "components/Layout/ModalSuccess";
import ModalFailure from "components/Layout/ModalFailure";

// assets
import CheckIcon from "assets/svgs/checkIcon.svg";
import DangerIcon from "assets/svgs/dangerIcon.svg";
import { FormattedMessage } from "react-intl";
import { handlePrice } from "helpers/functions";
import { useSearchParams } from "react-router-dom";

import styles from "./index.module.css";

const Table = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const noDataMessage = "no data";
  const onChange = (event) => {
    const { name, value } = event?.target || {};
    if (!value) return setSearchParams({ ...searchParams });
    const limit = searchParams.get("limit");
    setSearchParams({ ...searchParams, [name]: value, limit: limit });
  };

  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  // const filteredItems = data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );
  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <div className={`${styles["data-table-select-filter"]} select-filter`}>
          <select name="status" id="" onChange={onChange}>
            <option name="status" selected disabled hidden value="">
              الحالة
            </option>
            {SelectFilterOptions?.map((option) => (
              <option name="status" value={option?.value} key={option?.value}>
                {option?.name}
              </option>
            ))}
          </select>
        </div>

        <Filter
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  // handle the permission checked
  const { permissions } = useSelector(
    (state) => state.authentication.user.role
  );

  // handle edit and delete buttons of the banks
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleApproveTransactionRequestClick = (id) => {
    dispatch(approveTransactionRequest({ id, navigate }));
  };
  const handleRejectTransactionRequestClick = (id) => {
    dispatch(rejectTransactionRequest({ id, navigate }));
  };

  const handleShowTransactionRequestClick = (id) => {
    navigate(`/transactionrequests/${id}`);
  };

  const [show, setShow] = useState({
    approveModal: false,
    rejectModal: false,
  });
  const [selectedTransactionRequest, setSelectedTransactionRequest] = useState(
    {}
  );

  const { amount, bankAccount, driver } = selectedTransactionRequest || {};
  const { name: driverName } = driver || {};
  const { userName: bankAccountUserName, bank } = bankAccount || {};
  const { name: bankName } = bank || {};

  const handleClose = () =>
    setShow({
      approveModal: false,
      rejectModal: false,
    });
  const handleShow = (transactionRequest, modalType) => {
    const showApproveOrRejectModal = modalType === "approve" ? true : false;
    setShow({
      approveModal: showApproveOrRejectModal,
      rejectModal: !showApproveOrRejectModal,
    });
    setSelectedTransactionRequest(transactionRequest);
  };

  const columns = [
    {
      name: "السائق",
      selector: (row) => row?.driver?.name,
      sortable: true,
    },
    {
      name: "المبلغ",
      selector: (row) => row?.amount,
      sortable: true,
    },
    {
      name: "الحاله",
      selector: (row) => row.status,
      cell: (row) => {
        return (
          <>
            {row?.status === "approved" && (
              <p>
                <FormattedMessage id="approved" />
              </p>
            )}
            {row?.status === "pending" && (
              <p>
                <FormattedMessage id="pending" />
              </p>
            )}
            {row?.status === "rejected" && (
              <p>
                <FormattedMessage id="rejected" />
              </p>
            )}
          </>
        );
      },
      sortable: true,
    },

    {
      name: "",
      button: true,
      minWidth: "150px",
      center: true,

      cell: (row) => (
        <>
          {row?.status === "pending" &&
            <>
              <div
                className="show-btn  "
                onClick={(e) => handleShow(row, "approve")}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.8389 6.69459L8.81811 18.7154L3.16125 13.0586L4.57125 11.6486L8.81811 15.8854L19.4289 5.28459L20.8389 6.69459Z"
                    fill="#fff"
                  />
                </svg>
              </div> <div
                className="delete-btn mx-3"
                onClick={(e) => handleShow(row, "reject")}
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.625 13.75H4.375C3.68464 13.75 3.125 13.1904 3.125 12.5V4.375H1.875V3.125H4.375V2.5C4.375 1.80964 4.93464 1.25 5.625 1.25H9.375C10.0654 1.25 10.625 1.80964 10.625 2.5V3.125H13.125V4.375H11.875V12.5C11.875 13.1904 11.3154 13.75 10.625 13.75ZM4.375 4.375V12.5H10.625V4.375H4.375ZM5.625 2.5V3.125H9.375V2.5H5.625Z"
                    fill="white"
                  />
                </svg>
              </div>
            </>
          }



          <div
            className="edit-btn m-0"
            onClick={(e) => handleShowTransactionRequestClick(row.id)}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 20 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 14C8.35987 14.0204 6.7367 13.6664 5.254 12.965C4.10469 12.4042 3.07265 11.6297 2.213 10.683C1.30243 9.7041 0.585467 8.56164 0.1 7.31598L0 6.99998L0.105 6.68398C0.590815 5.43941 1.30624 4.29725 2.214 3.31698C3.07334 2.37029 4.10504 1.59584 5.254 1.03498C6.73671 0.333567 8.35988 -0.0204101 10 -2.11214e-05C11.6401 -0.0203749 13.2633 0.333601 14.746 1.03498C15.8953 1.59571 16.9274 2.37017 17.787 3.31698C18.6993 4.29453 19.4165 5.43734 19.9 6.68398L20 6.99998L19.895 7.31598C18.3262 11.3998 14.3742 14.0693 10 14ZM10 1.99998C6.59587 1.89331 3.47142 3.87507 2.117 6.99998C3.4712 10.1251 6.59579 12.1069 10 12C13.4041 12.1064 16.5284 10.1247 17.883 6.99998C16.5304 3.87356 13.4047 1.89106 10 1.99998ZM10 9.99998C8.55733 10.0095 7.30937 8.99734 7.02097 7.58375C6.73256 6.17017 7.48427 4.75 8.81538 4.19364C10.1465 3.63728 11.6852 4.10011 12.4885 5.29849C13.2919 6.49686 13.1354 8.09606 12.115 9.11598C11.5563 9.68124 10.7948 9.99954 10 9.99998Z"
                fill="#fff"
              />
            </svg>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>جدول البيانات </h4>
                <p className="">
                  يمكنك الجدول من عرض البيانات الخاصه والقيام بمختلف العمليات
                </p>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={filteredItems}
              defaultSortField="name"
              selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
              selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>

      <ModalSuccess
        show={show?.approveModal}
        handleClose={handleClose}
        icon={CheckIcon}
        title="تأكيد الموافقه علي العمليه "
        children={
          <>
            <h5 className="my-5 ">
              {`
              
              التأكيد علي اتمام عمليه التحويل للسائق
               ${driverName ?? noDataMessage}
               بمبلغ وقدره 
               ${handlePrice(amount)}
               علي الحساب البنكي 
               ${bankName ?? noDataMessage}
                لحساب 
                ${bankAccountUserName ?? noDataMessage}

               `}
            </h5>
            <button
              onClick={() =>
                handleApproveTransactionRequestClick(
                  selectedTransactionRequest?.id
                )
              }
              className="btn btn-success "
            >
              اتمام العمليه
            </button>
          </>
        }
      />
      <ModalFailure
        show={show?.rejectModal}
        handleClose={handleClose}
        icon={DangerIcon}
        title="تأكيد الموافقه علي الغاء العمليه "
        children={
          <>
            <h5 className="my-5 ">
              {`
              
              التأكيد علي الغاء عمليه التحويل للسائق
              ${driverName ?? noDataMessage}
              بمبلغ وقدره 
              ${handlePrice(amount)}
              علي الحساب البنكي 
              ${bankName ?? noDataMessage}
              لحساب 
              ${bankAccountUserName ?? noDataMessage}
              `}
            </h5>
            <button
              onClick={() =>
                handleRejectTransactionRequestClick(
                  selectedTransactionRequest?.id
                )
              }
              className="btn btn-red "
            >
              اتمام العمليه
            </button>
          </>
        }
      />
    </>
  );
};

export default Table;
