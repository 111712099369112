import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addPaymentApi,
  deletePaymentApi,
  editPaymentApi,
  getAllPaymentsApi,
  getSinglePaymentApi,
} from "../../api/payments";

// Login Redux States
import {
  ADD_PAYMENT,
  DELETE_PAYMENT,
  EDIT_PAYMENT,
  GET_ALL_PAYMENTS,
  GET_SINGLE_PAYMENT,
} from "./actionTypes";
import {
  getAllPaymentsSuccess,
  getAllPaymentsFailure,
  getSinglePaymentSuccess,
  getSinglePaymentFailure,
  deletePaymentSuccess,
  deletePaymentFailure,
  editPaymentSuccess,
  editPaymentFailure,
  addPaymentSuccess,
  addPaymentFailure,
} from "./actions";
import { createToaster } from "../toastify/actions";

function* getAllPayments({ payload }) {
  try {
    const { data } = yield call(getAllPaymentsApi, payload);
    yield put(getAllPaymentsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getAllPaymentsFailure(error.response.data || error));
  }
}

function* getSinglePayment({ payload }) {
  try {
    const { data } = yield call(getSinglePaymentApi, payload);
    yield put(getSinglePaymentSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSinglePaymentFailure(error.response.data || error));
  }
}

function* addPayment({ payload }) {
  const id = Math.random();

  try {
    const { data } = yield call(addPaymentApi, payload);
    yield put(addPaymentSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(addPaymentFailure(error.response.data.message || error));
    yield put(
      createToaster({ type: "error", message: "error", toasterID: id })
    );
  }
}

function* deletePayment({ payload }) {
  const id = Math.random();

  try {
    yield call(deletePaymentApi, payload);
    yield put(deletePaymentSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deletePaymentFailure(error.response.data || error));
    yield put(
      createToaster({ type: "error", message: "error", toasterID: id })
    );
  }
}

function* editPayment({ payload }) {
  const id = Math.random();

  try {
    yield call(editPaymentApi, payload);
    yield put(editPaymentSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(editPaymentFailure(error.response.data || error));
    yield put(
      createToaster({ type: "error", message: "error", toasterID: id })
    );
  }
}

export function* watchGetAllPayments() {
  yield takeEvery(GET_ALL_PAYMENTS, getAllPayments);
}
export function* watchGetSinglePayment() {
  yield takeEvery(GET_SINGLE_PAYMENT, getSinglePayment);
}
export function* watchDeletePayment() {
  yield takeEvery(DELETE_PAYMENT, deletePayment);
}
export function* watchAddPayment() {
  yield takeEvery(ADD_PAYMENT, addPayment);
}
export function* watchEditPayment() {
  yield takeEvery(EDIT_PAYMENT, editPayment);
}

function* paymentsSaga() {
  yield all([fork(watchGetAllPayments)]);
  yield all([fork(watchAddPayment)]);
  yield all([fork(watchEditPayment)]);
  yield all([fork(watchDeletePayment)]);
  yield all([fork(watchGetSinglePayment)]);
}

export default paymentsSaga;
