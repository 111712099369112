import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Table from "./Table";

import { getUnderReviewDrivers } from "store/actions";
import Loader from "components/shared/Loader";
import BootyPagination from "components/shared/Pagination";

const UnderReviewDrivers = () => {
  const dispatch = useDispatch();
  const { underReviewDrivers, metaData, loading } = useSelector(
    (state) => state?.drivers || {}
  );
  const { totalResults } = metaData || {};
  useEffect(() => {
    dispatch(getUnderReviewDrivers());
  }, [dispatch]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Table data={underReviewDrivers} />
      {totalResults > 10 && <BootyPagination metaData={metaData} />}
    </>
  );
};

export default UnderReviewDrivers;
