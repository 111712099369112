import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Table from "views/transactionRequests/Table";
import { getAllTransactionRequests } from "store/actions";
import Loader from "components/shared/Loader";
import { useLocation } from "react-router-dom";
import BootyPagination from "components/shared/Pagination";

const TransactionRequests = () => {
  const dispatch = useDispatch();
  let { search } = useLocation();
  const { loading } = useSelector((state) => state.transactionRequests) || {};
  const { transactionRequests, metaData } =
    useSelector((state) => state.transactionRequests) || {};
  const { totalResults } = metaData || {};

  useEffect(() => {
    dispatch(getAllTransactionRequests(search));
  }, [dispatch, search]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Table data={transactionRequests} />
      {totalResults > 10 && <BootyPagination metaData={metaData} />}
    </>
  );
};

export default TransactionRequests;
