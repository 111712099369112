import {
  GET_ALL_DRIVERS,
  GET_ALL_DRIVERS_SUCCESS,
  GET_ALL_DRIVERS_FAILURE,
  DELETE_DRIVER,
  DELETE_DRIVER_SUCCESS,
  DELETE_DRIVER_FAILURE,
  GET_SINGLE_DRIVER,
  GET_SINGLE_DRIVER_SUCCESS,
  GET_SINGLE_DRIVER_FAILURE,
  ADD_DRIVER,
  ADD_DRIVER_SUCCESS,
  ADD_DRIVER_FAILURE,
  EDIT_DRIVER,
  EDIT_DRIVER_SUCCESS,
  EDIT_DRIVER_FAILURE,
  GET_UNDER_REVIEW_DRIVERS,
  GET_UNDER_REVIEW_DRIVERS_SUCCESS,
  GET_UNDER_REVIEW_DRIVERS_FAILURE,
  APPROVE_DRIVER,
  APPROVE_DRIVER_SUCCESS,
  APPROVE_DRIVER_FAILURE,
  CREATE_DRIVER_TRANSACTION,
  CREATE_DRIVER_TRANSACTION_SUCCESS,
  CREATE_DRIVER_TRANSACTION_FAILURE,
  UPDATE_DRIVER_TRANSACTION,
  UPDATE_DRIVER_TRANSACTION_SUCCESS,
  UPDATE_DRIVER_TRANSACTION_FAILURE,
  DELETE_DRIVER_TRANSACTION,
  DELETE_DRIVER_TRANSACTION_SUCCESS,
  DELETE_DRIVER_TRANSACTION_FAILURE,
  BLOCK_DRIVER,
  BLOCK_DRIVER_SUCCESS,
  BLOCK_DRIVER_FAILURE,
} from "./actionTypes";

export const getAllDrivers = (search) => {
  return {
    type: GET_ALL_DRIVERS,
    payload: search,
  };
};

export const getAllDriversSuccess = (drivers) => {
  return {
    type: GET_ALL_DRIVERS_SUCCESS,
    payload: drivers,
  };
};

export const getAllDriversFailure = (error) => {
  return {
    type: GET_ALL_DRIVERS_FAILURE,
    payload: error,
  };
};

export const getSingleDriver = (id) => {
  return {
    type: GET_SINGLE_DRIVER,
    payload: id,
  };
};

export const getSingleDriverSuccess = (driver) => {
  return {
    type: GET_SINGLE_DRIVER_SUCCESS,
    payload: driver,
  };
};

export const getSingleDriverFailure = (error) => {
  return {
    type: GET_SINGLE_DRIVER_FAILURE,
    payload: error,
  };
};

export const addDriver = (payload) => {
  return {
    type: ADD_DRIVER,
    payload,
  };
};

export const addDriverSuccess = (payload) => {
  return {
    type: ADD_DRIVER_SUCCESS,
    payload,
  };
};

export const addDriverFailure = (error) => {
  return {
    type: ADD_DRIVER_FAILURE,
    payload: error,
  };
};

export const editDriver = (payload) => {
  return {
    type: EDIT_DRIVER,
    payload,
  };
};

export const editDriverSuccess = (payload) => {
  return {
    type: EDIT_DRIVER_SUCCESS,
    payload,
  };
};

export const editDriverFailure = (error) => {
  return {
    type: EDIT_DRIVER_FAILURE,
    payload: error,
  };
};

export const deleteDriver = (id) => {
  return {
    type: DELETE_DRIVER,
    payload: id,
  };
};

export const deleteDriverSuccess = (id) => {
  return {
    type: DELETE_DRIVER_SUCCESS,
    payload: id,
  };
};

export const deleteDriverFailure = (error) => {
  return {
    type: DELETE_DRIVER_FAILURE,
    payload: error,
  };
};

// under review drivers

export const getUnderReviewDrivers = () => {
  return {
    type: GET_UNDER_REVIEW_DRIVERS,
  };
};

export const getUnderReviewDriversSuccess = (drivers) => {
  return {
    type: GET_UNDER_REVIEW_DRIVERS_SUCCESS,
    payload: drivers,
  };
};

export const getUnderReviewDriversFailure = (error) => {
  return {
    type: GET_UNDER_REVIEW_DRIVERS_FAILURE,
    payload: error,
  };
};

// approve driver

export const approveDriver = (payload) => {
  return {
    type: APPROVE_DRIVER,
    payload,
  };
};

export const approveDriverSuccess = (payload) => {
  return {
    type: APPROVE_DRIVER_SUCCESS,
    payload,
  };
};

export const approveDriverFailure = (error) => {
  return {
    type: APPROVE_DRIVER_FAILURE,
    payload: error,
  };
};

// block driver
export const blockDriver = (payload) => {
  return {
    type: BLOCK_DRIVER,
    payload,
  };
};

export const blockDriverSuccess = (payload) => {
  return {
    type: BLOCK_DRIVER_SUCCESS,
    payload,
  };
};

export const blockDriverFailure = (error) => {
  return {
    type: BLOCK_DRIVER_FAILURE,
    payload: error,
  };
};

//  driver transaction

export const createDriverTransaction = (payload) => {
  return {
    type: CREATE_DRIVER_TRANSACTION,
    payload,
  };
};

export const createDriverTransactionSuccess = (payload) => {
  return {
    type: CREATE_DRIVER_TRANSACTION_SUCCESS,
    payload,
  };
};

export const createDriverTransactionFailure = (error) => {
  return {
    type: CREATE_DRIVER_TRANSACTION_FAILURE,
    payload: error,
  };
};

export const updateDriverTransaction = (payload) => {
  return {
    type: UPDATE_DRIVER_TRANSACTION,
    payload,
  };
};

export const updateDriverTransactionSuccess = (payload) => {
  return {
    type: UPDATE_DRIVER_TRANSACTION_SUCCESS,
    payload,
  };
};

export const updateDriverTransactionFailure = (error) => {
  return {
    type: UPDATE_DRIVER_TRANSACTION_FAILURE,
    payload: error,
  };
};

export const deleteDriverTransaction = (payload) => {
  return {
    type: DELETE_DRIVER_TRANSACTION,
    payload,
  };
};

export const deleteDriverTransactionSuccess = (payload) => {
  return {
    type: DELETE_DRIVER_TRANSACTION_SUCCESS,
    payload,
  };
};

export const deleteDriverTransactionFailure = (error) => {
  return {
    type: DELETE_DRIVER_TRANSACTION_FAILURE,
    payload: error,
  };
};
