export const GET_ALL_DRIVERS = "GET_ALL_DRIVERS";
export const GET_ALL_DRIVERS_SUCCESS = "GET_ALL_DRIVERS_SUCCESS";
export const GET_ALL_DRIVERS_FAILURE = "GET_ALL_DRIVERS_FAILURE";

export const GET_SINGLE_DRIVER = "GET_SINGLE_DRIVER";
export const GET_SINGLE_DRIVER_SUCCESS = "GET_SINGLE_DRIVER_SUCCESS";
export const GET_SINGLE_DRIVER_FAILURE = "GET_SINGLE_DRIVER_FAILURE";

export const ADD_DRIVER = "ADD_DRIVER";
export const ADD_DRIVER_SUCCESS = "ADD_DRIVER_SUCCESS";
export const ADD_DRIVER_FAILURE = "ADD_DRIVER_FAILURE";

export const EDIT_DRIVER = "EDIT_DRIVER";
export const EDIT_DRIVER_SUCCESS = "EDIT_DRIVER_SUCCESS";
export const EDIT_DRIVER_FAILURE = "EDIT_DRIVER_FAILURE";

export const DELETE_DRIVER = "DELETE_DRIVER";
export const DELETE_DRIVER_SUCCESS = "DELETE_DRIVER_SUCCESS";
export const DELETE_DRIVER_FAILURE = "DELETE_DRIVER_FAILURE";

// under review drivers

export const GET_UNDER_REVIEW_DRIVERS = "GET_UNDER_REVIEW_DRIVERS";
export const GET_UNDER_REVIEW_DRIVERS_SUCCESS =
  "GET_UNDER_REVIEW_DRIVERS_SUCCESS";
export const GET_UNDER_REVIEW_DRIVERS_FAILURE =
  "GET_UNDER_REVIEW_DRIVERS_FAILURE";

// approve driver

export const APPROVE_DRIVER = "APPROVE_DRIVER";
export const APPROVE_DRIVER_SUCCESS = "APPROVE_DRIVER_SUCCESS";
export const APPROVE_DRIVER_FAILURE = "APPROVE_DRIVER_FAILURE";

// block driver

export const BLOCK_DRIVER = "BLOCK_DRIVER";
export const BLOCK_DRIVER_SUCCESS = "BLOCK_DRIVER_SUCCESS";
export const BLOCK_DRIVER_FAILURE = "BLOCK_DRIVER_FAILURE";

// driver transaction

export const CREATE_DRIVER_TRANSACTION = "CREATE_DRIVER_TRANSACTION";
export const CREATE_DRIVER_TRANSACTION_SUCCESS =
  "CREATE_DRIVER_TRANSACTION_SUCCESS";
export const CREATE_DRIVER_TRANSACTION_FAILURE =
  "CREATE_DRIVER_TRANSACTION_FAILURE";

export const UPDATE_DRIVER_TRANSACTION = "UPDATE_DRIVER_TRANSACTION";
export const UPDATE_DRIVER_TRANSACTION_SUCCESS =
  "UPDATE_DRIVER_TRANSACTION_SUCCESS";
export const UPDATE_DRIVER_TRANSACTION_FAILURE =
  "UPDATE_DRIVER_TRANSACTION_FAILURE";

export const DELETE_DRIVER_TRANSACTION = "DELETE_DRIVER_TRANSACTION";
export const DELETE_DRIVER_TRANSACTION_SUCCESS =
  "DELETE_DRIVER_TRANSACTION_SUCCESS";
export const DELETE_DRIVER_TRANSACTION_FAILURE =
  "DELETE_DRIVER_TRANSACTION_FAILURE";
