import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addSettingApi,
  deleteSettingApi,
  editSettingApi,
  getAllSettingsApi,
  getSingleSettingApi,
} from "api/settings";

// Login Redux States
import {
  ADD_SETTING,
  DELETE_SETTING,
  EDIT_SETTING,
  GET_ALL_SETTINGS,
  GET_SINGLE_SETTING,
} from "./actionTypes";
import {
  getAllSettingsSuccess,
  getAllSettingsFailure,
  getSingleSettingSuccess,
  getSingleSettingFailure,
  deleteSettingSuccess,
  deleteSettingFailure,
  editSettingSuccess,
  editSettingFailure,
  addSettingSuccess,
  addSettingFailure,
} from "./actions";
import { createToaster } from "../toastify/actions";

function* getAllSettings({ payload }) {
  try {
    const { data } = yield call(getAllSettingsApi, payload);
    yield put(getAllSettingsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getAllSettingsFailure(error.response.data || error));
  }
}

function* getSingleSetting({ payload }) {
  try {
    const { data } = yield call(getSingleSettingApi, payload);
    yield put(getSingleSettingSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleSettingFailure(error.response.data || error));
  }
}

function* addSetting({ payload }) {
  const id = Math.random();

  try {
    const { data } = yield call(addSettingApi, payload);
    yield put(addSettingSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(addSettingFailure(error.response.data.message || error));
    yield put(
      createToaster({ type: "error", message: "error", toasterID: id })
    );
  }
}

function* deleteSetting({ payload }) {
  const id = Math.random();

  try {
    yield call(deleteSettingApi, payload);
    yield put(deleteSettingSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteSettingFailure(error.response.data || error));
    yield put(
      createToaster({ type: "error", message: "error", toasterID: id })
    );
  }
}

function* editSetting({ payload }) {
  const id = Math.random();

  try {
    const { data } = yield call(editSettingApi, payload);
    yield put(editSettingSuccess(data));
    yield payload.navigate(`/settings`);
  } catch (error) {
    console.log(error);
    yield put(editSettingFailure(error.response.data || error));
    yield put(
      createToaster({ type: "error", message: "error", toasterID: id })
    );
  }
}

export function* watchGetAllSettings() {
  yield takeEvery(GET_ALL_SETTINGS, getAllSettings);
}
export function* watchGetSingleSetting() {
  yield takeEvery(GET_SINGLE_SETTING, getSingleSetting);
}
export function* watchDeleteSetting() {
  yield takeEvery(DELETE_SETTING, deleteSetting);
}
export function* watchAddSetting() {
  yield takeEvery(ADD_SETTING, addSetting);
}
export function* watchEditSetting() {
  yield takeEvery(EDIT_SETTING, editSetting);
}

function* settingsSaga() {
  yield all([fork(watchGetAllSettings)]);
  yield all([fork(watchAddSetting)]);
  yield all([fork(watchEditSetting)]);
  yield all([fork(watchDeleteSetting)]);
  yield all([fork(watchGetSingleSetting)]);
}

export default settingsSaga;
