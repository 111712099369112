import React from "react";
import { Col, Row } from "react-bootstrap";
import ProjectBlockItem from "./ProjectBlockItem";
import { v4 } from "uuid";
// import Loader from "../shared/Loader";

const ProjectsBlocks = ({ statistics }) => {
  // const dispatch = useDispatch();
  // const { data } = useSelector((state) => state?.home) || {};
  // const { statistics } = data || {};

  // useEffect(() => {
  //   dispatch(getHomeData());
  // }, [dispatch]);

  const projectsBlocksData = [
    {
      id: v4(),
      title: "الطلبات المكتمله",
      count: statistics?.orderCompleted,
      className: "card-blue-bg",
    },
    {
      id: v4(),
      title: "الطلبات الغير مكتمله",
      count: statistics?.orderUncompleted,
      className: "card-red-bg",
    },
    {
      id: v4(),
      title: "الطلبات المعلقه",
      count: statistics?.orderPending,
      className: "card-green-bg",
    },
    {
      id: v4(),
      title: "الطلبات المرفوضه",
      count: statistics?.orderRejected,
      className: "card-red-bg",
    },
  ];

  const projBlocks = projectsBlocksData.map((proj) => {
    if (!proj?.count) return ""
    return (
      <Col lg={3} md={6} sm={12}>
        <ProjectBlockItem key={proj.id} proj={proj} />
      </Col>
    );
  });

  return (
    <>
      <div className="projects-blocks">
        <Row>{projBlocks}</Row>
      </div>
    </>
  );
};

export default ProjectsBlocks;
