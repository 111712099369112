import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router";

import Filter from "components/shared/Filter";
import { projectsTableHeadForReports } from "data";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";

import styles from "./index.module.css";
import { Link } from "react-router-dom";
const Table = (props) => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  // const filteredItems = data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );
  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e?.target?.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  // handle edit and delete buttons of the orders
  let navigate = useNavigate();

  const handleShowReportClick = (id) => {
    navigate(`/orders/${id}`);
  };

  const columns = [
    {
      name: "المشترك",
      selector: (row) => (
        <>
          <h6 title={row?.subscriber?.name} className="ellipsis-text">
            <Link to={`/subscribers/showsubscriber/${row?.subscriber?.id}`}>
              {row?.subscriber?.name}
            </Link>
          </h6>
        </>
      ),
      sortable: true,
    },

    // {
    //   name: "من",
    //   selector: (row) => (
    //     <>
    //       <h6 title={row?.originLocation?.address} className="ellipsis-text">
    //         {row?.originLocation?.address}
    //       </h6>
    //     </>
    //   ),
    //   sortable: true,
    // },
    // {
    //   name: "الي",
    //   selector: (row) => (
    //     <>
    //       <h6
    //         title={row?.distinationLocation?.address}
    //         className="ellipsis-text"
    //       >
    //         {row?.distinationLocation?.address}
    //       </h6>
    //     </>
    //   ),
    //   sortable: true,
    // },
    {
      name: "حاله الطلب ",
      selector: (row) => (
        <span>
          <FormattedMessage id={row?.status || ""} />
        </span>
      ),
      sortable: true,
    },
    {
      name: "المبلغ",
      selector: (row) => <span>{`${row?.totalAmount} ${row?.currency}`}</span>,
      sortable: true,
    },

    {
      name: " الدفع",
      selector: (row) => (
        <span>
          <FormattedMessage id={row?.payment || ""} />
        </span>
      ),
      sortable: true,
    },
    {
      name: projectsTableHeadForReports[2]?.date,
      selector: (row) => (
        <>
          <FormattedTime value={row?.createdAt} />
          ,&nbsp;
          <FormattedDate
            value={row?.createdAt}
            day="numeric"
            month="long"
            year="numeric"
          />
        </>
      ),

      sortable: true,
    },

    {
      name: "",
      button: true,
      cell: (row) => (
        <>
          <div
            className="show-btn"
            onClick={(e) => handleShowReportClick(row?.id)}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 20 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 14C8.35987 14.0204 6.7367 13.6664 5.254 12.965C4.10469 12.4042 3.07265 11.6297 2.213 10.683C1.30243 9.7041 0.585467 8.56164 0.1 7.31598L0 6.99998L0.105 6.68398C0.590815 5.43941 1.30624 4.29725 2.214 3.31698C3.07334 2.37029 4.10504 1.59584 5.254 1.03498C6.73671 0.333567 8.35988 -0.0204101 10 -2.11214e-05C11.6401 -0.0203749 13.2633 0.333601 14.746 1.03498C15.8953 1.59571 16.9274 2.37017 17.787 3.31698C18.6993 4.29453 19.4165 5.43734 19.9 6.68398L20 6.99998L19.895 7.31598C18.3262 11.3998 14.3742 14.0693 10 14ZM10 1.99998C6.59587 1.89331 3.47142 3.87507 2.117 6.99998C3.4712 10.1251 6.59579 12.1069 10 12C13.4041 12.1064 16.5284 10.1247 17.883 6.99998C16.5304 3.87356 13.4047 1.89106 10 1.99998ZM10 9.99998C8.55733 10.0095 7.30937 8.99734 7.02097 7.58375C6.73256 6.17017 7.48427 4.75 8.81538 4.19364C10.1465 3.63728 11.6852 4.10011 12.4885 5.29849C13.2919 6.49686 13.1354 8.09606 12.115 9.11598C11.5563 9.68124 10.7948 9.99954 10 9.99998Z"
                fill="#fff"
              />
            </svg>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>جدول البيانات </h4>
                <p>
                  يمكنك الجدول من عرض البيانات الخاصه والقيام بمختلف العمليات
                  حسب الصلاحيه المتاحه:
                </p>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={filteredItems}
              defaultSortField="name"
              selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
