import {
  GET_ALL_CONTACTS,
  GET_ALL_CONTACTS_SUCCESS,
  GET_ALL_CONTACTS_FAILURE,
  GET_SINGLE_CONTACT,
  GET_SINGLE_CONTACT_SUCCESS,
  GET_SINGLE_CONTACT_FAILURE,
  DELETE_CONTACT,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
  ADD_CONTACT,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAILURE,
} from "./actionTypes";

const initialState = {
  contacts: [],
  metaData: {},
  singleContact: {},
  loading: false,
  error: "",
};

const contacts = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_CONTACTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ALL_CONTACTS_SUCCESS: {
      const { contacts, metadata: metaData } = payload || {};
      state = {
        ...state,
        loading: false,
        contacts: contacts,
        metaData: metaData,
      };
      break;
    }
    case GET_ALL_CONTACTS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    case GET_SINGLE_CONTACT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_CONTACT_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleContact: payload.contact,
      };
      break;
    case GET_SINGLE_CONTACT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
        singleContact: {},
      };
      break;

    case ADD_CONTACT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_CONTACT_SUCCESS:
      const contactsArrayAfterAddingContact = [
        ...state?.contacts,
        payload.contact,
      ];
      state = {
        ...state,
        loading: false,
        contacts: contactsArrayAfterAddingContact,
        error: "",
      };
      break;
    case ADD_CONTACT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    case DELETE_CONTACT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_CONTACT_SUCCESS:
      const contactsArrayAfterDeletingContact = [
        ...state?.contacts?.filter((contact) => contact?.id !== payload),
      ];
      state = {
        ...state,
        loading: false,
        contacts: contactsArrayAfterDeletingContact,
        metaData: {
          ...state?.metaData,
          totalResults: state?.metaData?.totalResults - 1,
        },
      };
      break;
    case DELETE_CONTACT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default contacts;
