import React from "react";
import { FormattedDate } from "react-intl";
import styles from "./DriverLocation.module.css";
const DriverLocation = ({ location }) => {
  const { lat, lng, updatedAt: date } = location || {};

  const googleApiKey = process?.env?.REACT_APP_GOOGLE_API_KEY;

  return (
    <div className={`card `}>
      <div className="card-head">
        <div>
          <h4>أخر موقع للسائق </h4>
          {location && <p>{<FormattedDate id={date} />}</p>}
        </div>
      </div>
      {lat && lng ? (
        <div className={`card-body ${styles["map"]}`}>
          <iframe
            title="driver-location"
            width="300"
            height="400"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src={`https://www.google.com/maps/embed/v1/place?key=${googleApiKey}
            &q=${lat},${lng}`}
          ></iframe>
        </div>
      ) : (
        <p>مكان السائق غير محدد </p>
      )}
    </div>
  );
};

export default DriverLocation;
