import {
  GET_ALL_ROLES,
  GET_ALL_ROLES_SUCCESS,
  GET_ALL_ROLES_FAILURE,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  GET_SINGLE_ROLE,
  GET_SINGLE_ROLE_SUCCESS,
  GET_SINGLE_ROLE_FAILURE,
  ADD_ROLE,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILURE,
  EDIT_ROLE,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_FAILURE,
} from "./actionTypes";

export const getAllRoles = () => {
  return {
    type: GET_ALL_ROLES,
  };
};

export const getAllRolesSuccess = (roles) => {
  return {
    type: GET_ALL_ROLES_SUCCESS,
    payload: roles,
  };
};

export const getAllRolesFailure = (error) => {
  return {
    type: GET_ALL_ROLES_FAILURE,
    payload: error,
  };
};

export const getSingleRole = (id) => {
  return {
    type: GET_SINGLE_ROLE,
    payload: id,
  };
};

export const getSingleRoleSuccess = (role) => {
  return {
    type: GET_SINGLE_ROLE_SUCCESS,
    payload: role,
  };
};

export const getSingleRoleFailure = (error) => {
  return {
    type: GET_SINGLE_ROLE_FAILURE,
    payload: error,
  };
};

export const addRole = (payload) => {
  return {
    type: ADD_ROLE,
    payload,
  };
};

export const addRoleSuccess = (payload) => {
  return {
    type: ADD_ROLE_SUCCESS,
    payload,
  };
};

export const addRoleFailure = (error) => {
  return {
    type: ADD_ROLE_FAILURE,
    payload: error,
  };
};

export const editRole = (payload) => {
  return {
    type: EDIT_ROLE,
    payload,
  };
};

export const editRoleSuccess = (payload) => {
  return {
    type: EDIT_ROLE_SUCCESS,
    payload,
  };
};

export const editRoleFailure = (error) => {
  return {
    type: EDIT_ROLE_FAILURE,
    payload: error,
  };
};

export const deleteRole = (id) => {
  return {
    type: DELETE_ROLE,
    payload: id,
  };
};

export const deleteRoleSuccess = (id) => {
  return {
    type: DELETE_ROLE_SUCCESS,
    payload: id,
  };
};

export const deleteRoleFailure = (error) => {
  return {
    type: DELETE_ROLE_FAILURE,
    payload: error,
  };
};
