import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addDriver } from "store/actions";
const AddPayment = () => {
  // images uploader helpers
  const [image, setImage] = useState({});
  // const [imageURL, setImageURL] = useState({});
  useEffect(() => {
    if (image.length < 1) return;
  }, [image]);

  function onImageChange(e) {
    setImage(URL.createObjectURL(e.target.files[0]));
  }

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    dispatch(addDriver(data));
  };

  return (
    <>
      <div className="add-project-wrp">
        <div className="add-form">
          <div className="card">
            <div className="card-head">
              <h4> اضافه طريقه دفع </h4>
            </div>
            <div className="card-body">
              <form action="#" method="get" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5> الاسم بالعربيه </h5>
                      <div>
                        <svg
                          width="13"
                          height="16"
                          viewBox="0 0 13 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.5415 4.33333C2.5415 2.14721 4.31371 0.375 6.49984 0.375C8.68596 0.375 10.4582 2.14721 10.4582 4.33333C10.4582 6.51946 8.68596 8.29167 6.49984 8.29167C4.31371 8.29167 2.5415 6.51946 2.5415 4.33333ZM6.49984 6.70833C7.81151 6.70833 8.87484 5.64501 8.87484 4.33333C8.87484 3.02166 7.81151 1.95833 6.49984 1.95833C5.18816 1.95833 4.12484 3.02166 4.12484 4.33333C4.12484 5.64501 5.18816 6.70833 6.49984 6.70833Z"
                            fill="#838383"
                          />
                          <path
                            d="M2.02149 10.9383C0.833764 12.1261 0.166504 13.737 0.166504 15.4167H1.74984C1.74984 14.1569 2.25028 12.9487 3.14108 12.0579C4.03188 11.1671 5.24006 10.6667 6.49984 10.6667C7.75962 10.6667 8.9678 11.1671 9.85859 12.0579C10.7494 12.9487 11.2498 14.1569 11.2498 15.4167H12.8332C12.8332 13.737 12.1659 12.1261 10.9782 10.9383C9.79045 9.75059 8.17954 9.08333 6.49984 9.08333C4.82013 9.08333 3.20922 9.75059 2.02149 10.9383Z"
                            fill="#838383"
                          />
                        </svg>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder=" الإسم  "
                          {...register("name", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5> الاسم بالأنجليزيه </h5>
                      <div>
                        <svg
                          width="13"
                          height="16"
                          viewBox="0 0 13 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.5415 4.33333C2.5415 2.14721 4.31371 0.375 6.49984 0.375C8.68596 0.375 10.4582 2.14721 10.4582 4.33333C10.4582 6.51946 8.68596 8.29167 6.49984 8.29167C4.31371 8.29167 2.5415 6.51946 2.5415 4.33333ZM6.49984 6.70833C7.81151 6.70833 8.87484 5.64501 8.87484 4.33333C8.87484 3.02166 7.81151 1.95833 6.49984 1.95833C5.18816 1.95833 4.12484 3.02166 4.12484 4.33333C4.12484 5.64501 5.18816 6.70833 6.49984 6.70833Z"
                            fill="#838383"
                          />
                          <path
                            d="M2.02149 10.9383C0.833764 12.1261 0.166504 13.737 0.166504 15.4167H1.74984C1.74984 14.1569 2.25028 12.9487 3.14108 12.0579C4.03188 11.1671 5.24006 10.6667 6.49984 10.6667C7.75962 10.6667 8.9678 11.1671 9.85859 12.0579C10.7494 12.9487 11.2498 14.1569 11.2498 15.4167H12.8332C12.8332 13.737 12.1659 12.1261 10.9782 10.9383C9.79045 9.75059 8.17954 9.08333 6.49984 9.08333C4.82013 9.08333 3.20922 9.75059 2.02149 10.9383Z"
                            fill="#838383"
                          />
                        </svg>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder=" name  "
                          {...register("name", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>إرفاق صورة المشكلة</h5>
                      <div>
                        <label className="form-uploader">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={onImageChange}
                            id="filo"
                          />
                          <svg
                            width="24"
                            height="16"
                            viewBox="0 0 24 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19 16.0001H6.00004C2.87441 16.002 0.270931 13.604 0.0164008 10.4887C-0.238129 7.37347 1.94156 4.58465 5.02604 4.07911C6.44575 1.562 9.11015 0.00362534 12 5.65992e-05C13.8021 -0.00675485 15.5525 0.601415 16.962 1.72411C18.3461 2.82197 19.3301 4.34509 19.762 6.05811C22.3459 6.45514 24.1878 8.77563 23.9881 11.3822C23.7883 13.9888 21.6143 16.0015 19 16.0001ZM12 2.0001C9.83175 2.00267 7.83271 3.17221 6.76804 5.06111L6.30004 5.90011L5.35104 6.05511C3.30132 6.39852 1.85605 8.25441 2.02525 10.3258C2.19446 12.3972 3.92176 13.9939 6.00004 14.0001H19C20.5686 14.0017 21.8736 12.7947 21.9942 11.2308C22.1149 9.66685 21.0103 8.27401 19.46 8.03511L18.144 7.83511L17.822 6.54311C17.1573 3.86992 14.7546 1.99507 12 2.0001ZM13.45 12.0001H10.55V9.00011H8.00004L12 5.00011L16 9.00011H13.45V12.0001Z"
                              fill="#2E3A59"
                            />
                          </svg>
                          <span className="txt"></span>
                        </label>
                        {image?.keys && (
                          <div className="images-wrp">
                            <div className="img-holder">
                              <img
                                src={image}
                                width={100}
                                height={100}
                                alt=""
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPayment;
