import {
  GET_ALL_SETTINGS,
  GET_ALL_SETTINGS_SUCCESS,
  GET_ALL_SETTINGS_FAILURE,
  DELETE_SETTING,
  DELETE_SETTING_SUCCESS,
  DELETE_SETTING_FAILURE,
  GET_SINGLE_SETTING,
  GET_SINGLE_SETTING_SUCCESS,
  GET_SINGLE_SETTING_FAILURE,
  ADD_SETTING,
  ADD_SETTING_SUCCESS,
  ADD_SETTING_FAILURE,
  EDIT_SETTING,
  EDIT_SETTING_SUCCESS,
  EDIT_SETTING_FAILURE,
} from "./actionTypes";

export const getAllSettings = () => {
  return {
    type: GET_ALL_SETTINGS,
  };
};

export const getAllSettingsSuccess = (settings) => {
  return {
    type: GET_ALL_SETTINGS_SUCCESS,
    payload: settings,
  };
};

export const getAllSettingsFailure = (error) => {
  return {
    type: GET_ALL_SETTINGS_FAILURE,
    payload: error,
  };
};

export const getSingleSetting = (id) => {
  return {
    type: GET_SINGLE_SETTING,
    payload: id,
  };
};

export const getSingleSettingSuccess = (setting) => {
  return {
    type: GET_SINGLE_SETTING_SUCCESS,
    payload: setting,
  };
};

export const getSingleSettingFailure = (error) => {
  return {
    type: GET_SINGLE_SETTING_FAILURE,
    payload: error,
  };
};

export const addSetting = (payload) => {
  return {
    type: ADD_SETTING,
    payload,
  };
};

export const addSettingSuccess = (payload) => {
  return {
    type: ADD_SETTING_SUCCESS,
    payload,
  };
};

export const addSettingFailure = (error) => {
  return {
    type: ADD_SETTING_FAILURE,
    payload: error,
  };
};

export const editSetting = (payload) => {
  return {
    type: EDIT_SETTING,
    payload,
  };
};

export const editSettingSuccess = (payload) => {
  return {
    type: EDIT_SETTING_SUCCESS,
    payload,
  };
};

export const editSettingFailure = (error) => {
  return {
    type: EDIT_SETTING_FAILURE,
    payload: error,
  };
};

export const deleteSetting = (id) => {
  return {
    type: DELETE_SETTING,
    payload: id,
  };
};

export const deleteSettingSuccess = (id) => {
  return {
    type: DELETE_SETTING_SUCCESS,
    payload: id,
  };
};

export const deleteSettingFailure = (error) => {
  return {
    type: DELETE_SETTING_FAILURE,
    payload: error,
  };
};
