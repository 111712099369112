import React from "react";
import { forwardRef } from "react";
import DataTable from "react-data-table-component";
import { Col } from "react-bootstrap";
import { FormattedDate } from "react-intl";

import styles from "./SubscriberOrdersDetails.module.css";
import { useNavigate } from "react-router-dom";
export const SubscriberOrdersDetails = ({ tableData }) => {
  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  let navigate = useNavigate();

  const handleShowOrderClick = (id) => {
    navigate(`/orders/${id}`);
  };

  const columns = [
    {
      name: "رقم الطلب",
      selector: (row) => (
        <>
          <h6 title={row?.referenceId}>{row?.referenceId}</h6>
        </>
      ),
      sortable: true,
    },
    {
      name: "من",
      selector: (row) => (
        <>
          <h6 className="ellipsis-text" title={row?.originLocation?.address}>
            {row?.originLocation?.address}
          </h6>
        </>
      ),
      sortable: true,
    },

    {
      name: "الي",
      selector: (row) => (
        <>
          <h6 className="ellipsis-text" title={row?.originLocation?.address}>
            {row?.distinationLocation?.address}
          </h6>
        </>
      ),
      sortable: true,
    },

    {
      name: "التوقيت",
      selector: (row) => (
        <>
          <FormattedDate value={row?.date} />
        </>
      ),
      sortable: true,
    },

    {
      name: "وقت الوصول",
      selector: (row) => (
        <>
          <FormattedDate value={row?.date} />
        </>
      ),
      sortable: true,
    },

    {
      name: "المبلغ",
      selector: (row) => (
        <>
          <h6>{`${row?.totalAmount} ${row?.currency}`}</h6>
        </>
      ),
      sortable: true,
    },
    {
      name: "تفاصيل الطلب",
      center: true,
      selector: (row) => (
        <>
          <div
            className="show-btn"
            onClick={(e) => handleShowOrderClick(row?.id)}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 20 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 14C8.35987 14.0204 6.7367 13.6664 5.254 12.965C4.10469 12.4042 3.07265 11.6297 2.213 10.683C1.30243 9.7041 0.585467 8.56164 0.1 7.31598L0 6.99998L0.105 6.68398C0.590815 5.43941 1.30624 4.29725 2.214 3.31698C3.07334 2.37029 4.10504 1.59584 5.254 1.03498C6.73671 0.333567 8.35988 -0.0204101 10 -2.11214e-05C11.6401 -0.0203749 13.2633 0.333601 14.746 1.03498C15.8953 1.59571 16.9274 2.37017 17.787 3.31698C18.6993 4.29453 19.4165 5.43734 19.9 6.68398L20 6.99998L19.895 7.31598C18.3262 11.3998 14.3742 14.0693 10 14ZM10 1.99998C6.59587 1.89331 3.47142 3.87507 2.117 6.99998C3.4712 10.1251 6.59579 12.1069 10 12C13.4041 12.1064 16.5284 10.1247 17.883 6.99998C16.5304 3.87356 13.4047 1.89106 10 1.99998ZM10 9.99998C8.55733 10.0095 7.30937 8.99734 7.02097 7.58375C6.73256 6.17017 7.48427 4.75 8.81538 4.19364C10.1465 3.63728 11.6852 4.10011 12.4885 5.29849C13.2919 6.49686 13.1354 8.09606 12.115 9.11598C11.5563 9.68124 10.7948 9.99954 10 9.99998Z"
                fill="#fff"
              />
            </svg>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={12} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4> عمليات التوصيل الأخيره </h4>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={tableData}
              defaultSortField="name"
              selectableRows
              subHeader
              selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  );
};
