import React from "react";
import { Link } from "react-router-dom";
import styles from "./OrderHeader.module.css";
const OrderHeader = ({ id, total }) => {
  return (
    <div className={`${styles["wrapper"]} order-wrapper`}>
      <div className={styles["first-section"]}>
        <Link to={"/orders"} className={styles["icon"]}>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.4531 9.20833L8.91727 11.7513L9.91602 12.75L14.166 8.5L9.91602 4.25L8.91727 5.24875L11.4531 7.79167L2.83268 7.79167V9.20833L11.4531 9.20833Z"
              fill="white"
            />
          </svg>
        </Link>

        <div className={styles["order-number"]}>
          <p>#{id}</p>
          <p>رقم الطلب</p>
        </div>
      </div>

      <div className={styles["second-section"]}>
        <p>{total} ر.س </p>
        <span>{" الأجمالي "}</span>
      </div>
    </div>
  );
};

export default OrderHeader;
