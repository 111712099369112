import {
  GET_ALL_PAYMENTS,
  GET_ALL_PAYMENTS_SUCCESS,
  GET_ALL_PAYMENTS_FAILURE,
  DELETE_PAYMENT,
  DELETE_PAYMENT_SUCCESS,
  DELETE_PAYMENT_FAILURE,
  GET_SINGLE_PAYMENT,
  GET_SINGLE_PAYMENT_SUCCESS,
  GET_SINGLE_PAYMENT_FAILURE,
  ADD_PAYMENT,
  ADD_PAYMENT_SUCCESS,
  ADD_PAYMENT_FAILURE,
  EDIT_PAYMENT,
  EDIT_PAYMENT_SUCCESS,
  EDIT_PAYMENT_FAILURE,
} from "./actionTypes";

export const getAllPayments = () => {
  return {
    type: GET_ALL_PAYMENTS,
  };
};

export const getAllPaymentsSuccess = (payments) => {
  return {
    type: GET_ALL_PAYMENTS_SUCCESS,
    payload: payments,
  };
};

export const getAllPaymentsFailure = (error) => {
  return {
    type: GET_ALL_PAYMENTS_FAILURE,
    payload: error,
  };
};

export const getSinglePayment = (id) => {
  return {
    type: GET_SINGLE_PAYMENT,
    payload: id,
  };
};

export const getSinglePaymentSuccess = (driver) => {
  return {
    type: GET_SINGLE_PAYMENT_SUCCESS,
    payload: driver,
  };
};

export const getSinglePaymentFailure = (error) => {
  return {
    type: GET_SINGLE_PAYMENT_FAILURE,
    payload: error,
  };
};

export const addPayment = (payload) => {
  return {
    type: ADD_PAYMENT,
    payload,
  };
};

export const addPaymentSuccess = (payload) => {
  return {
    type: ADD_PAYMENT_SUCCESS,
    payload,
  };
};

export const addPaymentFailure = (error) => {
  return {
    type: ADD_PAYMENT_FAILURE,
    payload: error,
  };
};

export const editPayment = (payload) => {
  return {
    type: EDIT_PAYMENT,
    payload,
  };
};

export const editPaymentSuccess = (payload) => {
  return {
    type: EDIT_PAYMENT_SUCCESS,
    payload,
  };
};

export const editPaymentFailure = (error) => {
  return {
    type: EDIT_PAYMENT_FAILURE,
    payload: error,
  };
};

export const deletePayment = (id) => {
  return {
    type: DELETE_PAYMENT,
    payload: id,
  };
};

export const deletePaymentSuccess = (id) => {
  return {
    type: DELETE_PAYMENT_SUCCESS,
    payload: id,
  };
};

export const deletePaymentFailure = (error) => {
  return {
    type: DELETE_PAYMENT_FAILURE,
    payload: error,
  };
};
