import upload from "api/upload";
import { useState } from "react";

const useUploadImage = () => {
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: "",
  });
  const [loading, setLoading] = useState(false);

  const imageChange = async (e) => {
    const fileInput = e.target.files[0];
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage({ ...selectedImage, preview: fileInput });
    }
    const formData = new FormData();
    formData.append("file", fileInput);
    try {
      setLoading(true);
      const uploadImage = await upload.post(`/upload`, formData);
      setSelectedImage({
        preview: fileInput,
        path: uploadImage?.data?.data?.files[0]?.path,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const removeSelectedImage = () => {
    setSelectedImage({
      preview: null,
      path: "",
    });
  };

  return {
    selectedImage,
    setSelectedImage,
    loading,
    removeSelectedImage,
    imageChange,
  };
};

export default useUploadImage;
