import { combineReducers } from "redux";

// Authentication
import authentication from "./authentication/reducer";
import drivers from "./drivers/reducer";
import admins from "./admins/reducer";
import orders from "./orders/reducer";
import roles from "./roles/reducer";
import subscribers from "./subscribers/reducer";
import permissions from "./permissions/reducer";
import payments from "./payments/reducer";
import home from "./home/reducer";
import banks from "./banks/reducer";
import settings from "./settings/reducer";
import contacts from "./contacts/reducer";
import transactionRequests from "./transactionRequests/reducer";

const rootReducer = combineReducers({
  authentication,
  drivers,
  admins,
  orders,
  roles,
  permissions,
  payments,
  subscribers,
  home,
  settings,
  banks,
  contacts,
  transactionRequests,
});

export default rootReducer;
