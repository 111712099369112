import React, { useEffect } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSingleDriver } from "store/actions";
import { useParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import DriverPersonalInfo from "components/DriverProfile/DriverPersonalInfo/DriverPersonalInfo";
import DriverOrders from "components/DriverProfile/DriverOrders/DriverOrders";
import { DriverOrdersDetails } from "components/DriverProfile/DriverOrdersDetails/DriverOrdersDetails";
import { DriverTransactionDetails } from "components/DriverProfile/DriverTransactionDetails/DriverTransactionDetails";
import { DriverBankAccountsDetails } from "components/DriverProfile/DriverBankAccountsDetails/DriverBankAccountsDetails";
import DriverLocation from "components/DriverProfile/DriverLocation/DriverLocation";

const ShowDriver = () => {
  // instances
  const dispatch = useDispatch();
  const { id: driverId } = useParams();

  const { loading, error, singleDriver } = useSelector(
    (state) => state?.drivers || {}
  );

  // fetch driver data
  useEffect(() => {
    dispatch(getSingleDriver(driverId));
  }, [dispatch, driverId]);

  const { transactions, orders, bankAccounts, location, statistics } =
    singleDriver || {};

  const {
    pendingOrdersCount,
    finishedOrdersCount,
    rejectedByDriverOrdersCount,
    rejectedBySystemOrdersCount,
  } = statistics || {};

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="add-project-wrp">
          <div className="add-form">
            <div className="">
              <Container>



                <Row style={{ marginBottom: "20px" }}>

                  <Col lg={12} xs={12}>
                    <div className="mb-4">
                      <Row>
                        <Col md={6} xs={12}>
                          <DriverOrders
                            number={finishedOrdersCount}
                            typeOfOrder={"تم التوصيل"}
                            color="#36AD89"
                          />
                        </Col>
                        <Col md={6} xs={12}>
                          <DriverOrders
                            number={pendingOrdersCount}
                            typeOfOrder={"قيد الانتظار"}
                            color="#EE7A4B"
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "10px" }}>
                        <Col md={6} xs={12}>
                          <DriverOrders
                            number={rejectedBySystemOrdersCount}
                            typeOfOrder={"مرفوضه من النظام"}
                            color="#36AD89"
                          />
                        </Col>
                        <Col md={6} xs={12}>
                          <DriverOrders
                            number={rejectedByDriverOrdersCount}
                            typeOfOrder={"مرفوضه من السائق"}
                            color="#7A96F5"
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <DriverPersonalInfo singleDriver={singleDriver} />
                  </Col>
                </Row>
                <Tabs
                  defaultActiveKey="transications"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="transications" title="المحفظه">
                    <DriverTransactionDetails tableData={transactions} />
                  </Tab>
                  <Tab eventKey="orders" title="عمليات التوصيل">
                    <DriverOrdersDetails tableData={orders} />
                  </Tab>
                  <Tab eventKey="bankAccounts" title="الحسابات البنكيه"   >
                    <DriverBankAccountsDetails tableData={bankAccounts} />
                  </Tab>
                  <Tab eventKey="location" title="أخر موقع للسائق"   >
                    <DriverLocation location={location} />
                  </Tab>
                </Tabs>

              </Container>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShowDriver;
