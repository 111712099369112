import {
  GET_ALL_ADMINS,
  GET_ALL_ADMINS_SUCCESS,
  GET_ALL_ADMINS_FAILURE,
  DELETE_ADMIN,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILURE,
  GET_SINGLE_ADMIN,
  GET_SINGLE_ADMIN_SUCCESS,
  GET_SINGLE_ADMIN_FAILURE,
  ADD_ADMIN,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAILURE,
  EDIT_ADMIN,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_FAILURE,
} from "./actionTypes";

export const getAllAdmins = (search) => {
  return {
    type: GET_ALL_ADMINS,
    payload: search,
  };
};

export const getAllAdminsSuccess = (admins) => {
  return {
    type: GET_ALL_ADMINS_SUCCESS,
    payload: admins,
  };
};

export const getAllAdminsFailure = (error) => {
  return {
    type: GET_ALL_ADMINS_FAILURE,
    payload: error,
  };
};

export const getSingleAdmin = (id) => {
  return {
    type: GET_SINGLE_ADMIN,
    payload: id,
  };
};

export const getSingleAdminSuccess = (admin) => {
  return {
    type: GET_SINGLE_ADMIN_SUCCESS,
    payload: admin,
  };
};

export const getSingleAdminFailure = (error) => {
  return {
    type: GET_SINGLE_ADMIN_FAILURE,
    payload: error,
  };
};

export const addAdmin = (payload) => {
  return {
    type: ADD_ADMIN,
    payload,
  };
};

export const addAdminSuccess = (payload) => {
  return {
    type: ADD_ADMIN_SUCCESS,
    payload,
  };
};

export const addAdminFailure = (error) => {
  return {
    type: ADD_ADMIN_FAILURE,
    payload: error,
  };
};

export const editAdmin = (payload) => {
  return {
    type: EDIT_ADMIN,
    payload,
  };
};

export const editAdminSuccess = (payload) => {
  return {
    type: EDIT_ADMIN_SUCCESS,
    payload,
  };
};

export const editAdminFailure = (error) => {
  return {
    type: EDIT_ADMIN_FAILURE,
    payload: error,
  };
};

export const deleteAdmin = (id) => {
  return {
    type: DELETE_ADMIN,
    payload: id,
  };
};

export const deleteAdminSuccess = (id) => {
  return {
    type: DELETE_ADMIN_SUCCESS,
    payload: id,
  };
};

export const deleteAdminFailure = (error) => {
  return {
    type: DELETE_ADMIN_FAILURE,
    payload: error,
  };
};
