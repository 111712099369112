import axios from "axios";

const token = localStorage.getItem("token");
const upload = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  headers: {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  },
});

export default upload;
