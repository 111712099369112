import {
  GET_ALL_DRIVERS,
  GET_ALL_DRIVERS_SUCCESS,
  GET_ALL_DRIVERS_FAILURE,
  GET_SINGLE_DRIVER,
  GET_SINGLE_DRIVER_SUCCESS,
  GET_SINGLE_DRIVER_FAILURE,
  DELETE_DRIVER,
  DELETE_DRIVER_SUCCESS,
  DELETE_DRIVER_FAILURE,
  ADD_DRIVER,
  ADD_DRIVER_SUCCESS,
  ADD_DRIVER_FAILURE,
  GET_UNDER_REVIEW_DRIVERS_SUCCESS,
  GET_UNDER_REVIEW_DRIVERS,
  GET_UNDER_REVIEW_DRIVERS_FAILURE,
  APPROVE_DRIVER,
  APPROVE_DRIVER_SUCCESS,
  APPROVE_DRIVER_FAILURE,
  CREATE_DRIVER_TRANSACTION,
  CREATE_DRIVER_TRANSACTION_SUCCESS,
  CREATE_DRIVER_TRANSACTION_FAILURE,
  DELETE_DRIVER_TRANSACTION,
  DELETE_DRIVER_TRANSACTION_SUCCESS,
  DELETE_DRIVER_TRANSACTION_FAILURE,
  UPDATE_DRIVER_TRANSACTION,
  UPDATE_DRIVER_TRANSACTION_SUCCESS,
  UPDATE_DRIVER_TRANSACTION_FAILURE,
  BLOCK_DRIVER,
  BLOCK_DRIVER_SUCCESS,
  BLOCK_DRIVER_FAILURE,
} from "./actionTypes";

const initialState = {
  drivers: [],
  underReviewDrivers: [],
  metaData: {},
  singleDriver: {},
  loading: false,
  error: "",
};

const drivers = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_DRIVERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ALL_DRIVERS_SUCCESS: {
      const { drivers, metadata: metaData } = payload || {};
      state = {
        ...state,
        loading: false,
        drivers: drivers,
        metaData: metaData,
      };
      break;
    }
    case GET_ALL_DRIVERS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    case GET_SINGLE_DRIVER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_DRIVER_SUCCESS: {
      state = {
        ...state,
        loading: false,
        singleDriver: payload.driver,
      };
      break;
    }
    case GET_SINGLE_DRIVER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
        singleDriver: {},
      };
      break;

    case ADD_DRIVER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_DRIVER_SUCCESS:
      const driversArrayAfterAddingDriver = [...state?.drivers, payload.driver];
      state = {
        ...state,
        loading: false,
        drivers: driversArrayAfterAddingDriver,
        error: "",
      };
      break;
    case ADD_DRIVER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    case DELETE_DRIVER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_DRIVER_SUCCESS:
      const driversArrayAfterDeletingDriver = [
        ...state?.drivers?.filter((driver) => driver?.id !== payload),
      ];

      state = {
        ...state,
        loading: false,
        drivers: driversArrayAfterDeletingDriver,
        metaData: {
          ...state?.metaData,
          totalResults: state?.metaData?.totalResults - 1,
        },
      };
      break;
    case DELETE_DRIVER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    // under review drivers

    case GET_UNDER_REVIEW_DRIVERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_UNDER_REVIEW_DRIVERS_SUCCESS: {
      const { drivers, metadata: metaData } = payload;
      state = {
        ...state,
        loading: false,
        underReviewDrivers: drivers,
        metaData: metaData,
      };
      break;
    }
    case GET_UNDER_REVIEW_DRIVERS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    // approve driver
    case APPROVE_DRIVER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case APPROVE_DRIVER_SUCCESS: {
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    case APPROVE_DRIVER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    // block driver
    case BLOCK_DRIVER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case BLOCK_DRIVER_SUCCESS: {
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    case BLOCK_DRIVER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    // transactions

    case CREATE_DRIVER_TRANSACTION: {
      state = {
        ...state,
        loading: true,
      };
      break;
    }
    case CREATE_DRIVER_TRANSACTION_SUCCESS: {
      const { transaction } = payload || {};
      const driverTransactionsArrayAfterAddingTransaction = [
        ...state?.singleDriver?.transactions,
        transaction,
      ];
      state = {
        ...state,
        loading: false,
        singleDriver: {
          ...state?.singleDriver,
          transactions: driverTransactionsArrayAfterAddingTransaction,
        },
        error: "",
      };
      break;
    }
    case CREATE_DRIVER_TRANSACTION_FAILURE: {
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    }

    case UPDATE_DRIVER_TRANSACTION: {
      state = {
        ...state,
        loading: true,
      };
      break;
    }
    case UPDATE_DRIVER_TRANSACTION_SUCCESS: {
      const { transactionId } = payload || {};
      const driversArrayAfterUpdatingDriver =
        state.singleDriver?.transactions.map((transaction) => {
          if (transaction?.id === transactionId) {
            return payload;
          } else {
            return transaction;
          }
        });

      state = {
        ...state,
        loading: false,
        singleDriver: driversArrayAfterUpdatingDriver,
        error: "",
      };
      break;
    }
    case UPDATE_DRIVER_TRANSACTION_FAILURE: {
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    }
    case DELETE_DRIVER_TRANSACTION: {
      state = {
        ...state,
        loading: true,
      };
      break;
    }
    case DELETE_DRIVER_TRANSACTION_SUCCESS: {
      const driverTransactionsArrayAfterDeletingTransaction =
        state.singleDriver?.transactions.filter(
          (transaction) => transaction?.id !== payload
        );

      state = {
        ...state,
        loading: false,
        singleDriver: {
          ...state?.singleDriver,
          transactions: driverTransactionsArrayAfterDeletingTransaction,
        },
      };
      break;
    }
    case DELETE_DRIVER_TRANSACTION_FAILURE: {
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    }

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default drivers;
