import {
  GET_ALL_BANKS,
  GET_ALL_BANKS_SUCCESS,
  GET_ALL_BANKS_FAILURE,
  GET_SINGLE_BANK,
  GET_SINGLE_BANK_SUCCESS,
  GET_SINGLE_BANK_FAILURE,
  DELETE_BANK,
  DELETE_BANK_SUCCESS,
  DELETE_BANK_FAILURE,
  ADD_BANK,
  ADD_BANK_SUCCESS,
  ADD_BANK_FAILURE,
} from "./actionTypes";

const initialState = {
  banks: [],
  metaData: {},
  singleBank: {},
  loading: false,
  error: "",
};

const banks = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_BANKS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ALL_BANKS_SUCCESS: {
      const { banks, metadata: metaData } = payload || {};
      state = {
        ...state,
        loading: false,
        banks: banks,
        metaData: metaData,
      };
      break;
    }
    case GET_ALL_BANKS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    case GET_SINGLE_BANK:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_BANK_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleBank: payload.bank,
      };
      break;
    case GET_SINGLE_BANK_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
        singleBank: {},
      };
      break;

    case ADD_BANK:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_BANK_SUCCESS:
      const banksArrayAfterAddingBank = [...state?.banks, payload.bank];
      state = {
        ...state,
        loading: false,
        banks: banksArrayAfterAddingBank,
        error: "",
      };
      break;
    case ADD_BANK_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    case DELETE_BANK:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_BANK_SUCCESS:
      const banksArrayAfterDeletingBank = [
        ...state?.banks?.filter((bank) => bank?.id !== payload),
      ];
      state = {
        ...state,
        loading: false,
        banks: banksArrayAfterDeletingBank,
        metaData: {
          ...state?.metaData,
          totalResults: state?.metaData?.totalResults - 1,
        },
      };
      break;
    case DELETE_BANK_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default banks;
