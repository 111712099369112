import React, { useMemo, useState, forwardRef } from "react";
import DataTable from "react-data-table-component";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router";

import Filter from "components/shared/Filter";
import { projectsTableHead } from "data";
import { useDispatch, useSelector } from "react-redux";
import { deleteContact } from "store/actions";
import { checkIfUserHasPermission } from "helpers/functions";
const Table = (props) => {
  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  // const filteredItems = data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );
  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  // handle the permission checked
  const { permissions } = useSelector(
    (state) => state.authentication.user.role
  );


  // handle edit and delete buttons of the contacts
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleDeleteContactClick = (id) => {
    dispatch(deleteContact(id));
  };

  const columns = [
    {
      name: "الاسم",
      selector: (row) => row?.user?.name,
      sortable: true,
    },
    {
      name: "البريد الالكترونى",
      selector: (row) => row?.user?.email,
      sortable: true,
    },
    {
      name: "الرسالة",
      selector: (row) => row?.message,
      sortable: true,
    },

    {
      name: "",
      button: true,

      cell: (row) => (
        <>
          <div
            className="delete-btn"
            onClick={(e) => handleDeleteContactClick(row.id)}
          >
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.625 12.75H3.375C2.68464 12.75 2.125 12.1904 2.125 11.5V3.375H0.875V2.125H3.375V1.5C3.375 0.809644 3.93464 0.25 4.625 0.25H8.375C9.06536 0.25 9.625 0.809644 9.625 1.5V2.125H12.125V3.375H10.875V11.5C10.875 12.1904 10.3154 12.75 9.625 12.75ZM3.375 3.375V11.5H9.625V3.375H3.375ZM4.625 1.5V2.125H8.375V1.5H4.625Z"
                fill="white"
              />
            </svg>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>جدول  بيانات الاتصال </h4>
                <p>
                  الجدول يعرض بيانات الاتصال
                </p>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={filteredItems}
              defaultSortField="name"
              selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
              selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
