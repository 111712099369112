import server from "./server";

export const getAllSubscribersApi = async ({ search }) => {
  const response = await server().get(`/subscribers${search}`);
  return response.data;
};

export const getSingleSubscriberApi = async (id) => {
  const response = await server().get(`/subscribers/${id}`);
  return response.data;
};

export const addSubscriberApi = async ({ data }) => {
  const response = await server().post(`/subscribers`, data);
  return response.data;
};
export const deleteSubscriberApi = async (id) => {
  const response = await server().delete(`/subscribers/${id}`);
  return response.data;
};

export const editSubscriberApi = async ({ id, data }) => {
  const response = await server().put(`/subscribers/${id}`, data);
  return response.data;
};

// Regenerate subscriber Token

export const regenerateSubscriberTokenApi = async ({ id }) => {
  const response = await server().get(`/subscribers/generateNewToken/${id}`);
  return response.data;
};

// subscribers transaction

export const createSubscriberTransactionApi = async (payload) => {
  const response = await server().post(`/subscribers/transactions`, payload);
  return response.data;
};

export const updateSubscriberTransactionApi = async ({
  transactionId,
  data,
}) => {
  const response = await server().put(
    `/subscribers/transactions/${transactionId}`,
    data
  );
  return response.data;
};

export const deleteSubscriberTransactionApi = async (id) => {
  const response = await server().delete(`/subscribers/transactions/${id}`);
  return response.data;
};
