export const GET_ALL_PAYMENTS = "GET_ALL_PAYMENTS";
export const GET_ALL_PAYMENTS_SUCCESS = "GET_ALL_PAYMENTS_SUCCESS";
export const GET_ALL_PAYMENTS_FAILURE = "GET_ALL_PAYMENTS_FAILURE";

export const GET_SINGLE_PAYMENT = "GET_SINGLE_PAYMENT";
export const GET_SINGLE_PAYMENT_SUCCESS = "GET_SINGLE_PAYMENT_SUCCESS";
export const GET_SINGLE_PAYMENT_FAILURE = "GET_SINGLE_PAYMENT_FAILURE";

export const ADD_PAYMENT = "ADD_PAYMENT";
export const ADD_PAYMENT_SUCCESS = "ADD_PAYMENT_SUCCESS";
export const ADD_PAYMENT_FAILURE = "ADD_PAYMENT_FAILURE";

export const EDIT_PAYMENT = "EDIT_PAYMENT";
export const EDIT_PAYMENT_SUCCESS = "EDIT_PAYMENT_SUCCESS";
export const EDIT_PAYMENT_FAILURE = "EDIT_PAYMENT_FAILURE";

export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS";
export const DELETE_PAYMENT_FAILURE = "DELETE_PAYMENT_FAILURE";
