import { handleImage } from "helpers/functions";
import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const CustomerBlock = ({ customer }) => {
  const { name, address, logo, id } = customer || {};

  return (
    <>
      <Col xl={6} xs={6} style={{ marginBottom: "20px" }}>
        <Link
          to={`subscribers/showsubscriber/${id}`}
          style={{ display: "unset" }}
        >
          <div className="c-item">
            <div className="i-img">
              <img src={handleImage(logo)} width="104" height="104" alt="" />
            </div>
            <div className="i-data">
              <h4>{name}</h4>
              <p>{address}</p>
            </div>
          </div>
        </Link>
      </Col>
    </>
  );
};

export default CustomerBlock;
