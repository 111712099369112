import React from "react";
import styles from "./OrderHistory.module.css";
import OrderHeader from "components/OrderShow/OrderHeader/OrderHeader";
import OrderProduct from "components/OrderShow/OrderProduct/OrderProduct";
import OrderLocation from "../OrderLocation/OrderLocation";
import HistoryItem from "./HistoryItem/HistoryItem";
const OrderHistory = ({ order }) => {
  const {
    products,
    referenceId,
    totalAmount,
    distinationLocation: destinationLocation,
    originLocation,
    orderLogs,
  } = order || {};

  return (
    <div className="card">
      <div className={styles["card-header"]}>
        <h1>تاريخ الطلب</h1>
      </div>
      <div className={styles["card-content"]}>
        <div className={styles["orders"]}>
          {orderLogs?.map((item) => (
            <HistoryItem item={item} key={item?.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
