import React, { useMemo, useState, forwardRef } from "react";
import DataTable from "react-data-table-component";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router";

import Filter from "components/shared/Filter";
import { projectsTableHead } from "data";
import { useDispatch, useSelector } from "react-redux";
import { deleteBank } from "store/actions";
import { checkIfUserHasPermission } from "helpers/functions";
const Table = (props) => {
  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  // const filteredItems = data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );
  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  // handle the permission checked
  const { permissions } = useSelector(
    (state) => state.authentication.user.role
  );

  // handle edit and delete buttons of the banks
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleDeleteBankClick = (id) => {
    dispatch(deleteBank(id));
  };
  const handleEditBankClick = (id) => {
    navigate(`/settings/banks/${id}`);
  };
  const handleShowBankClick = (id) => {
    navigate(`/banks/showbank/${id}`);
  };

  console.log(props.data);
  const columns = [

    {
      name: "",
      selector: (row) => <img className="store-logo " src={row?.logo} alt="" />,
      sortable: true,
      width: "120px",
    },

    {
      name: projectsTableHead[0].name,
      selector: (row) => <> {row?.name} </>,
      sortable: true,
    },
    // {
    //   name: projectsTableHead[1].name,
    //   selector: (row) => row.address,
    //   sortable: true,
    // },
    // {
    //   name: "",
    //   sortable: true,
    //   cell: (row) =>
    //     row.showStatus ? (
    //       <>
    //         <span className={`status ${row.className}`}></span>
    //       </>
    //     ) : null,
    //   grow: 0,
    // },
    {
      name: "",
      button: true,

      cell: (row) => (
        <>
          {checkIfUserHasPermission(permissions, "banks-show") && (
            <div
              className="edit-btn"
              onClick={(e) => handleEditBankClick(row.id)}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.07833 11.0044C0.914695 11.0041 0.758703 10.9351 0.648413 10.8142C0.536089 10.6943 0.480275 10.5322 0.494996 10.3685L0.637913 8.79705L7.24008 2.19722L9.30333 4.25988L2.70291 10.8591L1.13141 11.002C1.11333 11.0038 1.09525 11.0044 1.07833 11.0044ZM9.71516 3.84747L7.6525 1.7848L8.88975 0.547549C8.99916 0.438012 9.14763 0.376465 9.30245 0.376465C9.45728 0.376465 9.60575 0.438012 9.71516 0.547549L10.9524 1.7848C11.0619 1.89421 11.1235 2.04268 11.1235 2.19751C11.1235 2.35233 11.0619 2.5008 10.9524 2.61022L9.71574 3.84688L9.71516 3.84747Z"
                  fill="white"
                />
              </svg>
            </div>
          )}
          {checkIfUserHasPermission(permissions, "banks-delete") && (
            <div
              className="delete-btn"
              onClick={(e) => handleDeleteBankClick(row.id)}
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.625 12.75H3.375C2.68464 12.75 2.125 12.1904 2.125 11.5V3.375H0.875V2.125H3.375V1.5C3.375 0.809644 3.93464 0.25 4.625 0.25H8.375C9.06536 0.25 9.625 0.809644 9.625 1.5V2.125H12.125V3.375H10.875V11.5C10.875 12.1904 10.3154 12.75 9.625 12.75ZM3.375 3.375V11.5H9.625V3.375H3.375ZM4.625 1.5V2.125H8.375V1.5H4.625Z"
                  fill="white"
                />
              </svg>
            </div>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>جدول البيانات </h4>
                <p>
                  يمكنك الجدول من عرض البيانات الخاصه والقيام بمختلف العمليات
                  حسب الصلاحيه المتاحه:
                </p>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={filteredItems}
              defaultSortField="name"
              selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
              selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
