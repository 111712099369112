import { useNavigate, useSearchParams } from "react-router-dom";
import { handleSearchParamsChange } from "helpers/functions";
import useWindowSize from "helpers/hooks/useGetScreenSizeHook";
import { useEffect } from "react";

const BootyPagination = ({ metaData }) => {
  const navigate = useNavigate();

  // hook for screen size
  const size = useWindowSize();

  const [searchParams] = useSearchParams();

  let { totalResults, pagination } = metaData || {};
  let { limit, page } = pagination || { page: 1, limit: 10 };

  page = parseInt(page) || 1;

  const onChangePage = (clickedPage, limit) => {
    const search = Object.fromEntries([...searchParams]);
    const params = {};
    if (clickedPage) params["page"] = clickedPage;
    if (limit) params["limit"] = limit;
    const newSearch = handleSearchParamsChange(search, params);
    navigate(window.location.pathname + newSearch, { replace: true });
  };

  const handleBackButtonClick = () => {
    onChangePage(page - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(page + 1);
  };

  const handlePageNumber = (e) => {
    onChangePage(Number(e.target.value));
  };

  const pages = getNumberOfPages(totalResults, limit);
  const pageItems = toPages(pages);
  const nextDisabled = page === pageItems.length;
  const previosDisabled = page === 1;

  function getNumberOfPages(totalResults, limit) {
    const numberOfPages = Math.ceil(Number(totalResults / limit));
    return numberOfPages;
  }

  function toPages(pages) {
    const results = [];

    for (let i = 0; i < pages; i++) {
      results.push(i + 1);
    }

    return results;
  }

  const getSelectedLimit = () => {
    const url = new URLSearchParams(searchParams);
    const limit = url.get("limit");
    return limit;
  };

  const showResponsivePagination = size?.width <= 625 || pageItems?.length > 10;

  const renderPaginationComponent = showResponsivePagination ? (
    <select onChange={handlePageNumber} className="pagination-select">
      {pageItems.map((pageItem) => {
        const className =
          pageItem === parseInt(page) ? "page-item active" : "page-item";

        return (
          <option
            key={pageItem}
            className={className}
            value={pageItem}
            onClick={handlePageNumber}
            selected={pageItem === page}
          >
            {pageItem}
          </option>
        );
      })}
    </select>
  ) : (
    pageItems.map((pageItem) => {
      const className =
        pageItem === parseInt(page) ? "page-item active" : "page-item";

      return (
        <li key={pageItem} className={className}>
          <button
            className="page-link"
            onClick={handlePageNumber}
            value={pageItem}
          >
            {pageItem}
          </button>
        </li>
      );
    })
  );

  return (
    <div className="pagination-wrapper  ">
      <nav className="table-pager">
        <ul className="pagination">
          <li className="page-item">
            <button
              className="page-link prev-link"
              onClick={handleBackButtonClick}
              disabled={previosDisabled}
              aria-disabled={previosDisabled}
              aria-label="previous page"
            >
              Previous
            </button>
          </li>

          {renderPaginationComponent}
          <li className="page-item">
            <button
              className="page-link nxt-link"
              onClick={handleNextButtonClick}
              disabled={nextDisabled}
              aria-disabled={nextDisabled}
              aria-label="next page"
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
      <div className="table-select">
        <select onChange={(e) => onChangePage("", e.target.value)}>
          <option selected={parseInt(getSelectedLimit()) === 5} value={5}>
            5
          </option>
          <option
            selected={
              parseInt(getSelectedLimit()) === 10 || getSelectedLimit() === null
            }
            value={10}
          >
            10
          </option>
          <option selected={parseInt(getSelectedLimit()) === 15} value={15}>
            15
          </option>

          <option selected={parseInt(getSelectedLimit()) === 20} value={20}>
            20
          </option>

          <option selected={parseInt(getSelectedLimit()) === 25} value={25}>
            25
          </option>
        </select>
      </div>
    </div>
  );
};

export default BootyPagination;
