import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addAdminApi,
  deleteAdminApi,
  editAdminApi,
  getAllAdminsApi,
  getSingleAdminApi,
} from "../../api/admins";

// Login Redux States
import {
  ADD_ADMIN,
  DELETE_ADMIN,
  EDIT_ADMIN,
  GET_ALL_ADMINS,
  GET_SINGLE_ADMIN,
} from "./actionTypes";
import {
  getAllAdminsSuccess,
  getAllAdminsFailure,
  getSingleAdminSuccess,
  getSingleAdminFailure,
  deleteAdminSuccess,
  deleteAdminFailure,
  editAdminSuccess,
  editAdminFailure,
  addAdminSuccess,
  addAdminFailure,
} from "./actions";
import { createToaster } from "../toastify/actions";

function* getAllAdmins({ payload }) {
  try {
    const { data } = yield call(getAllAdminsApi, payload);
    yield put(getAllAdminsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getAllAdminsFailure(error.response.data || error));
  }
}

function* getSingleAdmin({ payload }) {
  try {
    const { data } = yield call(getSingleAdminApi, payload);
    yield put(getSingleAdminSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleAdminFailure(error.response.data || error));
  }
}

function* addAdmin({ payload }) {
  const id = Math.random();

  try {
    const { data } = yield call(addAdminApi, payload);
    yield put(addAdminSuccess(data));

    yield payload.navigate("/admins");
  } catch (error) {
    console.log(error);
    yield put(addAdminFailure(error.response.data.message || error));
    yield put(
      createToaster({
        type: "error",
        message: error.response.data.errors[0].msg || "error",
        toasterID: id,
      })
    );
  }
}

function* deleteAdmin({ payload }) {
  const id = Math.random();

  try {
    yield call(deleteAdminApi, payload);
    yield put(deleteAdminSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteAdminFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: error.response.data.errors[0].msg || "error",
        toasterID: id,
      })
    );
  }
}

function* editAdmin({ payload }) {
  const id = Math.random();

  try {
    yield call(editAdminApi, payload);
    yield put(editAdminSuccess(payload));

    yield payload.navigate("/admins");
  } catch (error) {
    console.log(error);
    yield put(editAdminFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: error.response.data.errors[0].msg || "error",
        toasterID: id,
      })
    );
  }
}

export function* watchGetAllAdmins() {
  yield takeEvery(GET_ALL_ADMINS, getAllAdmins);
}
export function* watchGetSingleAdmin() {
  yield takeEvery(GET_SINGLE_ADMIN, getSingleAdmin);
}
export function* watchDeleteAdmin() {
  yield takeEvery(DELETE_ADMIN, deleteAdmin);
}
export function* watchAddAdmin() {
  yield takeEvery(ADD_ADMIN, addAdmin);
}
export function* watchEditAdmin() {
  yield takeEvery(EDIT_ADMIN, editAdmin);
}

function* adminsSaga() {
  yield all([fork(watchGetAllAdmins)]);
  yield all([fork(watchAddAdmin)]);
  yield all([fork(watchEditAdmin)]);
  yield all([fork(watchDeleteAdmin)]);
  yield all([fork(watchGetSingleAdmin)]);
}

export default adminsSaga;
