import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addDriverApi,
  approveDriverApi,
  blockDriverApi,
  createDriverTransactionApi,
  deleteDriverApi,
  deleteDriverTransactionApi,
  editDriverApi,
  getAllDriversApi,
  getAllDriversUnderReviewApi,
  getSingleDriverApi,
  updateDriverTransactionApi,
} from "api/drivers";

import {
  ADD_DRIVER,
  APPROVE_DRIVER,
  BLOCK_DRIVER,
  CREATE_DRIVER_TRANSACTION,
  DELETE_DRIVER,
  DELETE_DRIVER_TRANSACTION,
  EDIT_DRIVER,
  GET_ALL_DRIVERS,
  GET_SINGLE_DRIVER,
  GET_UNDER_REVIEW_DRIVERS,
  UPDATE_DRIVER_TRANSACTION,
} from "./actionTypes";
import {
  getAllDriversSuccess,
  getAllDriversFailure,
  getSingleDriverSuccess,
  getSingleDriverFailure,
  deleteDriverSuccess,
  deleteDriverFailure,
  editDriverSuccess,
  editDriverFailure,
  addDriverSuccess,
  addDriverFailure,
  getUnderReviewDriversSuccess,
  getUnderReviewDriversFailure,
  approveDriverSuccess,
  approveDriverFailure,
  createDriverTransactionSuccess,
  createDriverTransactionFailure,
  updateDriverTransactionSuccess,
  updateDriverTransactionFailure,
  deleteDriverTransactionSuccess,
  deleteDriverTransactionFailure,
} from "./actions";
import { createToaster } from "../toastify/actions";

const errorMessage = (error) =>
  error.response.data.errors[0].msg || error.response.data.errors || "error";

function* getAllDrivers({ payload }) {
  try {
    const { data } = yield call(getAllDriversApi, payload);
    yield put(getAllDriversSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getAllDriversFailure(error.response.data || error));
  }
}

function* getSingleDriver({ payload }) {
  try {
    const { data } = yield call(getSingleDriverApi, payload);
    yield put(getSingleDriverSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleDriverFailure(error.response.data || error));
  }
}

function* addDriver({ payload }) {
  const id = Math.random();

  try {
    const { data } = yield call(addDriverApi, payload.data);
    yield put(addDriverSuccess(data));

    yield payload.navigate("/drivers");
  } catch (error) {
    console.log(error);
    yield put(addDriverFailure(error.response.data.message || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

function* deleteDriver({ payload }) {
  const id = Math.random();

  try {
    yield call(deleteDriverApi, payload);
    yield put(deleteDriverSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteDriverFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

function* editDriver({ payload }) {
  const id = Math.random();

  try {
    yield call(editDriverApi, payload);
    yield put(editDriverSuccess(payload));

    yield payload.navigate("/drivers");
  } catch (error) {
    console.log(error);
    yield put(editDriverFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

// under review

function* getUnderReviewDrivers({ payload }) {
  const id = Math.random();

  try {
    const { data } = yield call(getAllDriversUnderReviewApi, payload);
    yield put(getUnderReviewDriversSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getUnderReviewDriversFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}
function* approveDriver({ payload }) {
  const id = Math.random();
  console.log("payload", payload);

  try {
    const { data } = yield call(approveDriverApi, payload);

    yield put(approveDriverSuccess(data));
    yield payload.navigate("/drivers");
  } catch (error) {
    console.log(error);
    yield put(approveDriverFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}
function* blockDriver({ payload }) {
  const id = Math.random();
  try {
    const { data } = yield call(blockDriverApi, payload);
    yield put(approveDriverSuccess(data));

    yield payload.navigate("/drivers");
  } catch (error) {
    console.log(error);
    yield put(approveDriverFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

// driver transaction

function* createDriverTransaction({ payload }) {
  const id = Math.random();
  try {
    const { data } = yield call(createDriverTransactionApi, payload.data);
    yield put(createDriverTransactionSuccess(data));

    yield payload.navigate(`/drivers/showdriver/${payload?.driverId}`);
  } catch (error) {
    console.log(error);
    yield put(
      createDriverTransactionFailure(error.response.data.message || error)
    );
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

function* updateDriverTransaction({ payload }) {
  const id = Math.random();

  try {
    yield call(updateDriverTransactionApi, payload);
    yield put(updateDriverTransactionSuccess(payload));
    yield payload.navigate(`/drivers/showdriver/${payload?.driverId}`);
  } catch (error) {
    console.log(error);
    yield put(updateDriverTransactionFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

function* deleteDriverTransaction({ payload }) {
  const id = Math.random();

  try {
    yield call(deleteDriverTransactionApi, payload);
    yield put(deleteDriverTransactionSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteDriverTransactionFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

export function* watchGetAllDrivers() {
  yield takeEvery(GET_ALL_DRIVERS, getAllDrivers);
}
export function* watchGetSingleDriver() {
  yield takeEvery(GET_SINGLE_DRIVER, getSingleDriver);
}
export function* watchDeleteDriver() {
  yield takeEvery(DELETE_DRIVER, deleteDriver);
}
export function* watchAddDriver() {
  yield takeEvery(ADD_DRIVER, addDriver);
}
export function* watchEditDriver() {
  yield takeEvery(EDIT_DRIVER, editDriver);
}

// under review
export function* watchGetUnderReviewDrivers() {
  yield takeEvery(GET_UNDER_REVIEW_DRIVERS, getUnderReviewDrivers);
}
// approve driver
export function* watchApproveDriver() {
  yield takeEvery(APPROVE_DRIVER, approveDriver);
}

// block driver
export function* watchBlockDriver() {
  yield takeEvery(BLOCK_DRIVER, blockDriver);
}

// driver transaction
export function* watchCreateDriverTransaction() {
  yield takeEvery(CREATE_DRIVER_TRANSACTION, createDriverTransaction);
}
export function* watchUpdateDriverTransaction() {
  yield takeEvery(UPDATE_DRIVER_TRANSACTION, updateDriverTransaction);
}
export function* watchDeleteDriverTransaction() {
  yield takeEvery(DELETE_DRIVER_TRANSACTION, deleteDriverTransaction);
}

function* driversSaga() {
  yield all([fork(watchGetAllDrivers)]);
  yield all([fork(watchAddDriver)]);
  yield all([fork(watchEditDriver)]);
  yield all([fork(watchDeleteDriver)]);
  yield all([fork(watchGetSingleDriver)]);

  yield all([fork(watchGetUnderReviewDrivers)]);
  yield all([fork(watchApproveDriver)]);
  yield all([fork(watchBlockDriver)]);

  // transaction
  yield all([fork(watchCreateDriverTransaction)]);
  yield all([fork(watchUpdateDriverTransaction)]);
  yield all([fork(watchDeleteDriverTransaction)]);
}

export default driversSaga;
