import React from "react";
import classNames from "classnames";

// helpers
import { checkIfUserHasPermission, handlePrice } from "helpers/functions";
// components
import DataTable from "react-data-table-component";
import { Col } from "react-bootstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import { deleteSubscriberTransaction } from "store/actions";

// styles
import styles from "./SubscriberTransactionDetails.module.css";
export const SubscriberTransactionDetails = ({ tableData }) => {
  const { subscriberId } = useParams();

  // handle the permission checked
  const { permissions } = useSelector(
    (state) => state.authentication.user.role
  );

  // handle edit and delete buttons of the subscribers
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleDeleteTransactionClick = (transactionId) => {
    dispatch(deleteSubscriberTransaction(transactionId));
  };
  const handleEditTransactionClick = (transactionId) => {
    navigate(`/subscribers/${subscriberId}/updatetransaction/${transactionId}`);
  };
  const handleShowTransactionClick = (transactionId) => {
    navigate(`/subscribers/${subscriberId}/showtransaction/${transactionId}`);
  };

  const columns = [
    {
      name: "دائن/مدين",
      selector: (row) => {
        let deptTypeText = classNames({
          [styles["dept-info-container"]]: true,
          [styles["green-border"]]: row?.type === "debit",
          [styles["red-border"]]: row?.type === "credit",
        });
        let deptTypeSvg = classNames({
          [styles["dept-svg"]]: true,
          [styles["green-svg"]]: row?.type === "debit",
          [styles["red-svg"]]: row?.type === "credit",
        });
        return (
          <>
            <div className={deptTypeText}>
              <div className={deptTypeSvg}>
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.20833 11.4538L11.7513 8.918L12.75 9.91675L8.5 14.1667L4.25 9.91675L5.24875 8.918L7.79167 11.4538L7.79167 2.83342L9.20833 2.83342L9.20833 11.4538Z"
                    fill="#fff"
                  />
                </svg>
              </div>
              <div className={styles["dept-text"]}>{row.type}</div>
            </div>
          </>
        );
      },
      sortable: true,
    },

    {
      name: "المبلغ",
      selector: (row) => handlePrice(row?.amount),
      sortable: true,
    },

    {
      name: "تعليق اضافي",
      selector: (row) => (
        <>
          <h6 className="ellipsis-text" title={row?.description}>
            {row?.description}
          </h6>
        </>
      ),

      sortable: true,
    },

    {
      name: "",
      button: true,
      minWidth: "150px",

      cell: (row) => (
        <>
          {checkIfUserHasPermission(permissions, "drivers-show") && (
            <div
              className="show-btn"
              onClick={(e) => handleShowTransactionClick(row.id)}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 20 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 14C8.35987 14.0204 6.7367 13.6664 5.254 12.965C4.10469 12.4042 3.07265 11.6297 2.213 10.683C1.30243 9.7041 0.585467 8.56164 0.1 7.31598L0 6.99998L0.105 6.68398C0.590815 5.43941 1.30624 4.29725 2.214 3.31698C3.07334 2.37029 4.10504 1.59584 5.254 1.03498C6.73671 0.333567 8.35988 -0.0204101 10 -2.11214e-05C11.6401 -0.0203749 13.2633 0.333601 14.746 1.03498C15.8953 1.59571 16.9274 2.37017 17.787 3.31698C18.6993 4.29453 19.4165 5.43734 19.9 6.68398L20 6.99998L19.895 7.31598C18.3262 11.3998 14.3742 14.0693 10 14ZM10 1.99998C6.59587 1.89331 3.47142 3.87507 2.117 6.99998C3.4712 10.1251 6.59579 12.1069 10 12C13.4041 12.1064 16.5284 10.1247 17.883 6.99998C16.5304 3.87356 13.4047 1.89106 10 1.99998ZM10 9.99998C8.55733 10.0095 7.30937 8.99734 7.02097 7.58375C6.73256 6.17017 7.48427 4.75 8.81538 4.19364C10.1465 3.63728 11.6852 4.10011 12.4885 5.29849C13.2919 6.49686 13.1354 8.09606 12.115 9.11598C11.5563 9.68124 10.7948 9.99954 10 9.99998Z"
                  fill="#fff"
                />
              </svg>
            </div>
          )}
          {checkIfUserHasPermission(permissions, "drivers-show") && (
            <div
              className="edit-btn"
              onClick={(e) => handleEditTransactionClick(row.id)}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.07833 11.0044C0.914695 11.0041 0.758703 10.9351 0.648413 10.8142C0.536089 10.6943 0.480275 10.5322 0.494996 10.3685L0.637913 8.79705L7.24008 2.19722L9.30333 4.25988L2.70291 10.8591L1.13141 11.002C1.11333 11.0038 1.09525 11.0044 1.07833 11.0044ZM9.71516 3.84747L7.6525 1.7848L8.88975 0.547549C8.99916 0.438012 9.14763 0.376465 9.30245 0.376465C9.45728 0.376465 9.60575 0.438012 9.71516 0.547549L10.9524 1.7848C11.0619 1.89421 11.1235 2.04268 11.1235 2.19751C11.1235 2.35233 11.0619 2.5008 10.9524 2.61022L9.71574 3.84688L9.71516 3.84747Z"
                  fill="white"
                />
              </svg>
            </div>
          )}
          {checkIfUserHasPermission(permissions, "drivers-delete") && (
            <div
              className="delete-btn"
              onClick={(e) => handleDeleteTransactionClick(row.id)}
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.625 12.75H3.375C2.68464 12.75 2.125 12.1904 2.125 11.5V3.375H0.875V2.125H3.375V1.5C3.375 0.809644 3.93464 0.25 4.625 0.25H8.375C9.06536 0.25 9.625 0.809644 9.625 1.5V2.125H12.125V3.375H10.875V11.5C10.875 12.1904 10.3154 12.75 9.625 12.75ZM3.375 3.375V11.5H9.625V3.375H3.375ZM4.625 1.5V2.125H8.375V1.5H4.625Z"
                  fill="white"
                />
              </svg>
            </div>
          )}
        </>
      ),
    },
  ];

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },

    cells: {
      style: {
        border: "none",
      },
    },
    headRow: {
      style: {
        border: "1px solid #F2F2F2",
        borderRadius: "8px",
      },
    },
    headCells: {
      style: {
        border: "none",
      },
    },
    rows: {
      style: {
        paddingRight: "25px",
        border: "1px solid #F2F2F2",
        borderRadius: "8px",
        marginTop: "10px",
        backgroundColor: "#fff !important",
      },
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244) !important",
        borderBottomColor: "#FFFFFF",
        borderRadius: "25px",
        outline: "1px solid #FFFFFF",
      },
    },
  };
  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={12} md={12} xs={12}>
            <div className="card-head">
              <div className={styles["table-header"]}>
                <h4>المحفظه </h4>
                <NavLink
                  to={`/subscribers/${subscriberId}/addtransaction`}
                  className="btn btn-blue custom-btn-blue "
                  data-animation="ripple"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.4286 6.39286H9.60714V1.57143C9.60714 0.979799 9.12734 0.5 8.53571 0.5H7.46429C6.87266 0.5 6.39286 0.979799 6.39286 1.57143V6.39286H1.57143C0.979799 6.39286 0.5 6.87266 0.5 7.46429V8.53571C0.5 9.12734 0.979799 9.60714 1.57143 9.60714H6.39286V14.4286C6.39286 15.0202 6.87266 15.5 7.46429 15.5H8.53571C9.12734 15.5 9.60714 15.0202 9.60714 14.4286V9.60714H14.4286C15.0202 9.60714 15.5 9.12734 15.5 8.53571V7.46429C15.5 6.87266 15.0202 6.39286 14.4286 6.39286Z"
                      fill="white"
                    />
                  </svg>
                  {"اضافه عمليه"}
                </NavLink>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              customStyles={customStyles}
              columns={columns}
              data={tableData}
              defaultSortField="name"
              subHeader
              // selectableRowsComponent={BootyCheckbox}
              highlightOnHover
              pointerOnHover
              // responsible for checkbox appearance
              selectableRows={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};
