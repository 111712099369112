import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addRoleApi,
  deleteRoleApi,
  editRoleApi,
  getAllRolesApi,
  getSingleRoleApi,
} from "../../api/roles";

// Login Redux States
import {
  ADD_ROLE,
  DELETE_ROLE,
  EDIT_ROLE,
  GET_ALL_ROLES,
  GET_SINGLE_ROLE,
} from "./actionTypes";
import {
  getAllRolesSuccess,
  getAllRolesFailure,
  getSingleRoleSuccess,
  getSingleRoleFailure,
  deleteRoleSuccess,
  deleteRoleFailure,
  editRoleSuccess,
  editRoleFailure,
  addRoleSuccess,
  addRoleFailure,
} from "./actions";
import { createToaster } from "../toastify/actions";

function* getAllRoles({ payload }) {
  try {
    const { data } = yield call(getAllRolesApi, payload);
    yield put(getAllRolesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getAllRolesFailure(error.response.data || error));
  }
}

function* getSingleRole({ payload }) {
  try {
    const { data } = yield call(getSingleRoleApi, payload);
    yield put(getSingleRoleSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleRoleFailure(error.response.data || error));
  }
}

function* addRole({ payload }) {
  const id = Math.random();

  try {
    const { data } = yield call(addRoleApi, payload);
    yield put(addRoleSuccess(data));

    yield payload.navigate("/settings/roles");
  } catch (error) {
    console.log(error);
    yield put(addRoleFailure(error.response.data.message || error));
    yield put(
      createToaster({ type: "error", message: "error", toasterID: id })
    );
  }
}

function* deleteRole({ payload }) {
  const id = Math.random();

  try {
    yield call(deleteRoleApi, payload);
    yield put(deleteRoleSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteRoleFailure(error.response.data || error));
    yield put(
      createToaster({ type: "error", message: "error", toasterID: id })
    );
  }
}

function* editRole({ payload }) {
  const id = Math.random();

  try {
    yield call(editRoleApi, payload);
    yield put(editRoleSuccess(payload));

    yield payload.navigate("/settings/roles");
  } catch (error) {
    console.log(error);
    yield put(editRoleFailure(error.response.data || error));
    yield put(
      createToaster({ type: "error", message: "error", toasterID: id })
    );
  }
}

export function* watchGetAllRoles() {
  yield takeEvery(GET_ALL_ROLES, getAllRoles);
}
export function* watchGetSingleRole() {
  yield takeEvery(GET_SINGLE_ROLE, getSingleRole);
}
export function* watchDeleteRole() {
  yield takeEvery(DELETE_ROLE, deleteRole);
}
export function* watchAddRole() {
  yield takeEvery(ADD_ROLE, addRole);
}
export function* watchEditRole() {
  yield takeEvery(EDIT_ROLE, editRole);
}

function* rolesSaga() {
  yield all([fork(watchGetAllRoles)]);
  yield all([fork(watchAddRole)]);
  yield all([fork(watchEditRole)]);
  yield all([fork(watchDeleteRole)]);
  yield all([fork(watchGetSingleRole)]);
}

export default rolesSaga;
