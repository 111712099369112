import server from "./server";

export const getAllPaymentsApi = async () => {
  const response = await server().get("/payments");
  return response.data;
};

export const getSinglePaymentApi = async (id) => {
  const response = await server().get(`/payments/${id}`);
  return response.data;
};

export const addPaymentApi = async (payload) => {
  const response = await server().post(`/payments`, payload);
  return response.data;
};
export const deletePaymentApi = async (id) => {
  const response = await server().delete(`/payments/${id}`);
  return response.data;
};

export const editPaymentApi = async ({ id, data }) => {
  const response = await server().put(`/payments/${id}`, data);
  return response.data;
};
