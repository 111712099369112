import React from "react";
import { Modal } from "react-bootstrap";
const ModalFailure = (props) => {
  const { show, handleClose, icon, title, children } = props;
  // Success Modal
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modal-wrp">
            <div className="modal-icon">
              <img src={icon} alt="" />
            </div>
            <h4>{title}</h4>

            <div>{children}</div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalFailure;
