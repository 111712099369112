import {
  GET_ALL_CONTACTS,
  GET_ALL_CONTACTS_SUCCESS,
  GET_ALL_CONTACTS_FAILURE,
  DELETE_CONTACT,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
  GET_SINGLE_CONTACT,
  GET_SINGLE_CONTACT_SUCCESS,
  GET_SINGLE_CONTACT_FAILURE,
  ADD_CONTACT,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAILURE,
  EDIT_CONTACT,
  EDIT_CONTACT_SUCCESS,
  EDIT_CONTACT_FAILURE,
} from "./actionTypes";

export const getAllContacts = (search) => {
  return {
    type: GET_ALL_CONTACTS,
    payload: search,
  };
};

export const getAllContactsSuccess = (contacts) => {
  return {
    type: GET_ALL_CONTACTS_SUCCESS,
    payload: contacts,
  };
};

export const getAllContactsFailure = (error) => {
  return {
    type: GET_ALL_CONTACTS_FAILURE,
    payload: error,
  };
};

export const getSingleContact = (id) => {
  return {
    type: GET_SINGLE_CONTACT,
    payload: id,
  };
};

export const getSingleContactSuccess = (contact) => {
  return {
    type: GET_SINGLE_CONTACT_SUCCESS,
    payload: contact,
  };
};

export const getSingleContactFailure = (error) => {
  return {
    type: GET_SINGLE_CONTACT_FAILURE,
    payload: error,
  };
};

export const addContact = (payload) => {
  return {
    type: ADD_CONTACT,
    payload,
  };
};

export const addContactSuccess = (payload) => {
  return {
    type: ADD_CONTACT_SUCCESS,
    payload,
  };
};

export const addContactFailure = (error) => {
  return {
    type: ADD_CONTACT_FAILURE,
    payload: error,
  };
};

export const editContact = (payload) => {
  return {
    type: EDIT_CONTACT,
    payload,
  };
};

export const editContactSuccess = (payload) => {
  return {
    type: EDIT_CONTACT_SUCCESS,
    payload,
  };
};

export const editContactFailure = (error) => {
  return {
    type: EDIT_CONTACT_FAILURE,
    payload: error,
  };
};

export const deleteContact = (id) => {
  return {
    type: DELETE_CONTACT,
    payload: id,
  };
};

export const deleteContactSuccess = (id) => {
  return {
    type: DELETE_CONTACT_SUCCESS,
    payload: id,
  };
};

export const deleteContactFailure = (error) => {
  return {
    type: DELETE_CONTACT_FAILURE,
    payload: error,
  };
};
