import {
  GET_ALL_TRANSACTION_REQUESTS,
  GET_ALL_TRANSACTION_REQUESTS_SUCCESS,
  GET_ALL_TRANSACTION_REQUESTS_FAILURE,
  GET_SINGLE_TRANSACTION_REQUEST,
  GET_SINGLE_TRANSACTION_REQUEST_SUCCESS,
  GET_SINGLE_TRANSACTION_REQUEST_FAILURE,
  APPROVE_TRANSACTION_REQUEST,
  APPROVE_TRANSACTION_REQUEST_SUCCESS,
  APPROVE_TRANSACTION_REQUEST_FAILURE,
  REJECT_TRANSACTION_REQUEST,
  REJECT_TRANSACTION_REQUEST_SUCCESS,
  REJECT_TRANSACTION_REQUEST_FAILURE,
} from "./actionTypes";

const initialState = {
  transactionRequests: [],
  metaData: {},
  singleTransactionRequest: {},
  loading: false,
  error: "",
};

const transactionRequests = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_TRANSACTION_REQUESTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ALL_TRANSACTION_REQUESTS_SUCCESS: {
      const { requests: transactionRequests, metadata: metaData } =
        payload || {};
      state = {
        ...state,
        loading: false,
        transactionRequests: transactionRequests,
        metaData: metaData,
      };
      break;
    }
    case GET_ALL_TRANSACTION_REQUESTS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    case GET_SINGLE_TRANSACTION_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_TRANSACTION_REQUEST_SUCCESS: {
      const { request: transactionRequests } = payload;

      state = {
        ...state,
        loading: false,
        singleTransactionRequest: transactionRequests,
      };
      break;
    }
    case GET_SINGLE_TRANSACTION_REQUEST_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
        singleTransactionRequest: {},
      };
      break;

    // approve transactionRequest
    case APPROVE_TRANSACTION_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case APPROVE_TRANSACTION_REQUEST_SUCCESS: {
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    case APPROVE_TRANSACTION_REQUEST_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    // reject transactionRequest
    case REJECT_TRANSACTION_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case REJECT_TRANSACTION_REQUEST_SUCCESS: {
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    case REJECT_TRANSACTION_REQUEST_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default transactionRequests;
