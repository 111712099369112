import React from "react";
import { handleImage } from "helpers/functions";
import { Link } from "react-router-dom";
const EmployeeBlock = ({ employee }) => {
  const { driver, status } = employee || {};
  const { name, image, id } = driver || {};

  return (
    <Link
      to={`drivers/showdriver/${id}`}
      style={{ display: "unset", marginBottom: "30px" }}
    >
      <div className="employee-item">
        <div className="i-img">
          <img
            src={handleImage(image)}
            width={36}
            height={36}
            alt={name ?? ""}
          />
        </div>
        <div className="i-info">
          <h4>{name}</h4>
          <p>{status}</p>
        </div>
      </div>
    </Link>
  );
};

export default EmployeeBlock;
