import server from "./server";

export const getAllRolesApi = async () => {
  const response = await server().get("/roles");
  return response.data;
};

export const getSingleRoleApi = async (id) => {
  const response = await server().get(`/roles/${id}`);
  return response.data;
};

export const addRoleApi = async ({ data }) => {
  const response = await server().post(`/roles`, data);
  return response.data;
};
export const deleteRoleApi = async (id) => {
  const response = await server().delete(`/roles/${id}`);
  return response.data;
};

export const editRoleApi = async ({ id, data }) => {
  const response = await server().put(`/roles/${id}`, data);
  return response.data;
};
