import {
  GET_ALL_TRANSACTION_REQUESTS,
  GET_ALL_TRANSACTION_REQUESTS_SUCCESS,
  GET_ALL_TRANSACTION_REQUESTS_FAILURE,
  GET_SINGLE_TRANSACTION_REQUEST,
  GET_SINGLE_TRANSACTION_REQUEST_SUCCESS,
  GET_SINGLE_TRANSACTION_REQUEST_FAILURE,
  APPROVE_TRANSACTION_REQUEST,
  APPROVE_TRANSACTION_REQUEST_SUCCESS,
  APPROVE_TRANSACTION_REQUEST_FAILURE,
  REJECT_TRANSACTION_REQUEST,
  REJECT_TRANSACTION_REQUEST_SUCCESS,
  REJECT_TRANSACTION_REQUEST_FAILURE,
} from "./actionTypes";

export const getAllTransactionRequests = (search) => {
  return {
    type: GET_ALL_TRANSACTION_REQUESTS,
    payload: search,
  };
};

export const getAllTransactionRequestsSuccess = (transactionRequests) => {
  return {
    type: GET_ALL_TRANSACTION_REQUESTS_SUCCESS,
    payload: transactionRequests,
  };
};

export const getAllTransactionRequestsFailure = (error) => {
  return {
    type: GET_ALL_TRANSACTION_REQUESTS_FAILURE,
    payload: error,
  };
};

export const getSingleTransactionRequest = (id) => {
  return {
    type: GET_SINGLE_TRANSACTION_REQUEST,
    payload: id,
  };
};

export const getSingleTransactionRequestSuccess = (transactionRequest) => {
  return {
    type: GET_SINGLE_TRANSACTION_REQUEST_SUCCESS,
    payload: transactionRequest,
  };
};

export const getSingleTransactionRequestFailure = (error) => {
  return {
    type: GET_SINGLE_TRANSACTION_REQUEST_FAILURE,
    payload: error,
  };
};

// approve transactionRequest

export const approveTransactionRequest = (payload) => {
  return {
    type: APPROVE_TRANSACTION_REQUEST,
    payload,
  };
};

export const approveTransactionRequestSuccess = (payload) => {
  return {
    type: APPROVE_TRANSACTION_REQUEST_SUCCESS,
    payload,
  };
};

export const approveTransactionRequestFailure = (error) => {
  return {
    type: APPROVE_TRANSACTION_REQUEST_FAILURE,
    payload: error,
  };
};

// reject transactionRequest
export const rejectTransactionRequest = (payload) => {
  return {
    type: REJECT_TRANSACTION_REQUEST,
    payload,
  };
};

export const rejectTransactionRequestSuccess = (payload) => {
  return {
    type: REJECT_TRANSACTION_REQUEST_SUCCESS,
    payload,
  };
};

export const rejectTransactionRequestFailure = (error) => {
  return {
    type: REJECT_TRANSACTION_REQUEST_FAILURE,
    payload: error,
  };
};
