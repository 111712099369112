import React, { useEffect, useState } from "react";
import Loader from "components/shared/Loader";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import { editSetting, getSingleSetting } from "store/actions";

//assets
import imageUpload from "assets/svgs/imageUpload.svg";
// api
import upload from "api/upload";

const GeneralSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: "",
  });
  const slugOfGeneralSetting = "main";
  useEffect(() => {
    dispatch(getSingleSetting(slugOfGeneralSetting));
  }, [dispatch]);

  const { singleSetting, loading } =
    useSelector((state) => state?.settings) || [];
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    singleSetting.forEach((element) => {
      if (element?.title === "appLogo") {
        setSelectedImage({
          preview: element?.value,
          path: "",
        });
      }
    });
  }, [singleSetting]);

  // setValue of the input fields when data is fetched
  useEffect(() => {
    Object?.entries(singleSetting)?.map(([key, valueData]) => {
      const { title, value } = valueData;
      setValue(title, value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleSetting]);

  const onSubmit = async (data) => {
    if (selectedImage && selectedImage.path) data.appLogo = selectedImage?.path;
    const modifiedData = Object.entries(data).map(([title, value]) => {
      return { title, value };
    });

    dispatch(
      editSetting({ id: slugOfGeneralSetting, data: modifiedData, navigate })
    );
  };

  const imageChange = async (e) => {
    const fileInput = e.target.files[0];
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage({ ...selectedImage, preview: fileInput });
    }
    const formData = new FormData();
    formData.append("file", fileInput);
    try {
      setDisableSubmitButton(true);
      const uploadImage = await upload.post(`/upload`, formData);
      setSelectedImage({
        preview: fileInput,
        path: uploadImage?.data?.data?.files[0]?.path,
      });
      setDisableSubmitButton(false);
    } catch (error) {
      console.log(error);
      setDisableSubmitButton(false);
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage({
      preview: null,
      path: "",
    });
  };

  const renderFormInputs = () => {
    return (
      <Row>
        {singleSetting?.length >= 1 &&
          singleSetting?.map((item) => (
            <Col md={12} xs={12} key={item?.title}>
              <div className="form-group required">
                <h5> {item?.name?.ar} </h5>
                <div>
                  <svg
                    width="13"
                    height="16"
                    viewBox="0 0 13 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.5415 4.33333C2.5415 2.14721 4.31371 0.375 6.49984 0.375C8.68596 0.375 10.4582 2.14721 10.4582 4.33333C10.4582 6.51946 8.68596 8.29167 6.49984 8.29167C4.31371 8.29167 2.5415 6.51946 2.5415 4.33333ZM6.49984 6.70833C7.81151 6.70833 8.87484 5.64501 8.87484 4.33333C8.87484 3.02166 7.81151 1.95833 6.49984 1.95833C5.18816 1.95833 4.12484 3.02166 4.12484 4.33333C4.12484 5.64501 5.18816 6.70833 6.49984 6.70833Z"
                      fill="#838383"
                    />
                    <path
                      d="M2.02149 10.9383C0.833764 12.1261 0.166504 13.737 0.166504 15.4167H1.74984C1.74984 14.1569 2.25028 12.9487 3.14108 12.0579C4.03188 11.1671 5.24006 10.6667 6.49984 10.6667C7.75962 10.6667 8.9678 11.1671 9.85859 12.0579C10.7494 12.9487 11.2498 14.1569 11.2498 15.4167H12.8332C12.8332 13.737 12.1659 12.1261 10.9782 10.9383C9.79045 9.75059 8.17954 9.08333 6.49984 9.08333C4.82013 9.08333 3.20922 9.75059 2.02149 10.9383Z"
                      fill="#838383"
                    />
                  </svg>
                  {item?.type === "file" ? (
                    <>
                      <Col lg={12}>
                        <div className="image-uploader">
                          <div className="img-pat">
                            <img
                              src={item?.value ?? imageUpload}
                              width={46}
                              height={46}
                              alt="app-logo"
                            />
                          </div>
                          <label>
                            <input
                              accept="image/*"
                              type="file"
                              onChange={imageChange}
                              id="picture"
                            />

                            {selectedImage && selectedImage.preview && (
                              <div>
                                <img
                                  src={
                                    typeof selectedImage?.preview === "object"
                                      ? URL.createObjectURL(
                                        selectedImage?.preview
                                      )
                                      : selectedImage?.preview
                                  }
                                  width={160}
                                  height={160}
                                  alt=""
                                />
                                <button
                                  className="removeImg"
                                  onClick={removeSelectedImage}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-x-lg"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                    />
                                  </svg>
                                </button>
                              </div>
                            )}
                          </label>
                        </div>
                      </Col>
                    </>
                  ) : (
                    <input
                      type="text"
                      className="form-control form-outline"
                      placeholder={item?.name?.ar}
                      {...register(`${item?.title}`, {
                        required: true,
                      })}
                    />
                  )}
                </div>
              </div>
            </Col>
          ))}
      </Row>
    );
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="add-project-wrp">
        <div className="add-form">
          <div className="card">
            <div className="card-head">
              <h4> الاعدادات الرئيسية </h4>
            </div>
            <div className="card-body">
              <form action="#" method="get" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col lg={8} xs={12}>
                    {renderFormInputs()}
                  </Col>
                </Row>
                <Row>

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-blue"
                        disabled={disableSubmitButton}
                      >
                        {disableSubmitButton ? "... loading " : "حفظ"}
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralSettings;
