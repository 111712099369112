import {
  GET_ALL_ADMINS,
  GET_ALL_ADMINS_SUCCESS,
  GET_ALL_ADMINS_FAILURE,
  GET_SINGLE_ADMIN,
  GET_SINGLE_ADMIN_SUCCESS,
  GET_SINGLE_ADMIN_FAILURE,
  DELETE_ADMIN,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILURE,
  ADD_ADMIN,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAILURE,
} from "./actionTypes";

const initialState = {
  admins: [],
  metaData: {},
  singleAdmin: {},
  loading: false,
  error: "",
};

const admins = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_ADMINS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ALL_ADMINS_SUCCESS:
      const { admins, metadata: metaData } = payload;

      state = {
        ...state,
        loading: false,
        metaData: metaData,
        admins: admins,
        error: false,
      };
      break;
    case GET_ALL_ADMINS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    case GET_SINGLE_ADMIN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_ADMIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleAdmin: payload.admin,
        error: false,
      };
      break;
    case GET_SINGLE_ADMIN_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
        singleAdmin: {},
      };
      break;

    case ADD_ADMIN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_ADMIN_SUCCESS:
      const adminsArrayAfterAddingAdmin = [...state?.admins, payload.admin];
      state = {
        ...state,
        loading: false,
        admins: adminsArrayAfterAddingAdmin,
        error: "",
      };
      break;
    case ADD_ADMIN_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    case DELETE_ADMIN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_ADMIN_SUCCESS:
      const adminsArrayAfterDeletingAdmin = [
        ...state?.admins?.filter((admin) => admin?.id !== payload),
      ];
      state = {
        ...state,
        loading: false,
        admins: adminsArrayAfterDeletingAdmin,
        error: false,
      };
      break;
    case DELETE_ADMIN_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default admins;
