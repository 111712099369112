import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import ControlArea from "components/Layout/ControlArea";
import ProjectsIcon from "assets/svgs/projectsIcon.svg";
import { getAllOrders } from "store/actions";
import Loader from "components/shared/Loader";
import BootyPagination from "components/shared/Pagination";
import { useLocation } from "react-router-dom";
import Table from "views/orders/Table";

const Orders = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  let { search } = useLocation();

  const { loading } = useSelector((state) => state?.orders) || {};

  const { orders, metaData } = useSelector((state) => state?.orders) || {};
  const { totalResults } = metaData || {};
  useEffect(() => {
    dispatch(getAllOrders({ search }));
  }, [dispatch, search]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Table data={orders} />
      {totalResults > 10 && <BootyPagination metaData={metaData} />}
    </>
  );
};

export default Orders;
