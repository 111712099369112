import server from "./server";

export const getAllDriversApi = async (search) => {
  const response = await server().get(`/drivers${search}`);
  return response.data;
};

export const getSingleDriverApi = async (id) => {
  const response = await server().get(`/drivers/${id}`);
  return response.data;
};

export const addDriverApi = async (payload) => {
  const response = await server().post(`/drivers`, payload);
  return response.data;
};
export const deleteDriverApi = async (id) => {
  const response = await server().delete(`/drivers/${id}`);
  return response.data;
};

export const editDriverApi = async ({ id, data }) => {
  const response = await server().put(`/drivers/${id}`, data);
  return response.data;
};
// under review drivers
export const getAllDriversUnderReviewApi = async () => {
  const response = await server().get(`/drivers/getUnderReviewDrivers`);
  return response.data;
};

// approve driver api
export const approveDriverApi = async (data) => {
  const response = await server().post(`/drivers/approve/${data?.id}`);
  return response.data;
};

// block driver api
export const blockDriverApi = async ({ id }) => {
  const response = await server().post(`/drivers/block/${id}`);
  return response.data;
};

// drivers transaction

export const createDriverTransactionApi = async (payload) => {
  const response = await server().post(`/drivers/transactions`, payload);
  return response.data;
};

export const updateDriverTransactionApi = async ({ transactionId, data }) => {
  const response = await server().put(
    `/drivers/transactions/${transactionId}`,
    data
  );
  return response.data;
};

export const deleteDriverTransactionApi = async (id) => {
  const response = await server().delete(`/drivers/transactions/${id}`);
  return response.data;
};
