export const GET_ALL_SETTINGS = "GET_ALL_SETTINGS";
export const GET_ALL_SETTINGS_SUCCESS = "GET_ALL_SETTINGS_SUCCESS";
export const GET_ALL_SETTINGS_FAILURE = "GET_ALL_SETTINGS_FAILURE";

export const GET_SINGLE_SETTING = "GET_SINGLE_SETTING";
export const GET_SINGLE_SETTING_SUCCESS = "GET_SINGLE_SETTING_SUCCESS";
export const GET_SINGLE_SETTING_FAILURE = "GET_SINGLE_SETTING_FAILURE";

export const ADD_SETTING = "ADD_SETTING";
export const ADD_SETTING_SUCCESS = "ADD_SETTING_SUCCESS";
export const ADD_SETTING_FAILURE = "ADD_SETTING_FAILURE";

export const EDIT_SETTING = "EDIT_SETTING";
export const EDIT_SETTING_SUCCESS = "EDIT_SETTING_SUCCESS";
export const EDIT_SETTING_FAILURE = "EDIT_SETTING_FAILURE";

export const DELETE_SETTING = "DELETE_SETTING";
export const DELETE_SETTING_SUCCESS = "DELETE_SETTING_SUCCESS";
export const DELETE_SETTING_FAILURE = "DELETE_SETTING_FAILURE";
