import React from "react";
import styles from "./DriverOrders.module.css";
const DriverOrders = ({ color, number, typeOfOrder }) => {
  return (
    <div className={`card ${styles["card-margin"]}`}>
      <div className={styles["card-content"]}>
        <div className={styles["info"]}>
          <h4>
            <span>{number}</span> طلبات
          </h4>
          <h6>{typeOfOrder} </h6>
        </div>

        <div
          className={styles["icon"]}
          style={{ backgroundColor: `${color}10` }}
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            xmlns="http://www.w3.org/2000/svg"
            style={{ fill: color }}
          >
            <path d="M9.40072 6.3797C9.40643 6.30541 9.46838 6.24805 9.54289 6.24805H10.173C10.2462 6.24805 10.3076 6.30355 10.3148 6.37644L10.7476 10.7038L13.7948 12.4451C13.8392 12.4705 13.8666 12.5177 13.8666 12.5689V13.1906C13.8666 13.2846 13.7772 13.3529 13.6865 13.3282L9.07896 12.0716C9.01297 12.0536 8.96906 11.9913 8.97431 11.9231L9.40072 6.3797Z" />
            <path d="M5.19364 0.366941C5.11832 0.277184 4.9729 0.311539 4.94572 0.425511L3.7059 5.62331C3.68375 5.71617 3.75734 5.80415 3.85266 5.79875L9.19969 5.49574C9.31687 5.4891 9.37629 5.35164 9.30085 5.26173L7.85099 3.53385C8.62943 3.26789 9.45487 3.12898 10.3024 3.12898C14.4859 3.12898 17.8773 6.52034 17.8773 10.7038C17.8773 14.8873 14.4859 18.2786 10.3024 18.2786C6.11898 18.2786 2.72762 14.8873 2.72762 10.7038C2.72762 10.0015 2.82273 9.31365 3.00817 8.65255L1.29209 8.17119C1.06615 8.97668 0.945312 9.82613 0.945312 10.7038C0.945312 15.8716 5.13464 20.0609 10.3024 20.0609C15.4702 20.0609 19.6596 15.8716 19.6596 10.7038C19.6596 5.536 15.4702 1.34667 10.3024 1.34667C9.00269 1.34667 7.76483 1.61168 6.63996 2.0906L5.19364 0.366941Z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default DriverOrders;
