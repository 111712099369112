import {
  GET_ALL_ROLES,
  GET_ALL_ROLES_SUCCESS,
  GET_ALL_ROLES_FAILURE,
  GET_SINGLE_ROLE,
  GET_SINGLE_ROLE_SUCCESS,
  GET_SINGLE_ROLE_FAILURE,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  ADD_ROLE,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILURE,
} from "./actionTypes";

const initialState = {
  roles: [],
  singleRole: {},
  loading: false,
  error: "",
};

const roles = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_ROLES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ALL_ROLES_SUCCESS:
      state = {
        ...state,
        loading: false,
        roles: payload.roles,
      };
      break;
    case GET_ALL_ROLES_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    case GET_SINGLE_ROLE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_ROLE_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleRole: payload.role,
      };
      break;
    case GET_SINGLE_ROLE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
        singleRole: {},
      };
      break;

    case ADD_ROLE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_ROLE_SUCCESS:
      const rolesArrayAfterAddingRole = [...state?.roles, payload.role];
      state = {
        ...state,
        loading: false,
        roles: rolesArrayAfterAddingRole,
        error: "",
      };
      break;
    case ADD_ROLE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    case DELETE_ROLE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_ROLE_SUCCESS:
      const rolesArrayAfterDeletingRole = [
        ...state?.roles?.filter((role) => role?.id !== payload),
      ];
      state = {
        ...state,
        loading: false,
        roles: rolesArrayAfterDeletingRole,
      };
      break;
    case DELETE_ROLE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default roles;
