import {
  GET_ALL_SUBSCRIBERS,
  GET_ALL_SUBSCRIBERS_SUCCESS,
  GET_ALL_SUBSCRIBERS_FAILURE,
  DELETE_SUBSCRIBER,
  DELETE_SUBSCRIBER_SUCCESS,
  DELETE_SUBSCRIBER_FAILURE,
  GET_SINGLE_SUBSCRIBER,
  GET_SINGLE_SUBSCRIBER_SUCCESS,
  GET_SINGLE_SUBSCRIBER_FAILURE,
  ADD_SUBSCRIBER,
  ADD_SUBSCRIBER_SUCCESS,
  ADD_SUBSCRIBER_FAILURE,
  EDIT_SUBSCRIBER,
  EDIT_SUBSCRIBER_SUCCESS,
  EDIT_SUBSCRIBER_FAILURE,
  REGENERATE_SUBSCRIBER_TOKEN_FAILURE,
  REGENERATE_SUBSCRIBER_TOKEN_SUCCESS,
  REGENERATE_SUBSCRIBER_TOKEN,
  CREATE_SUBSCRIBER_TRANSACTION,
  CREATE_SUBSCRIBER_TRANSACTION_SUCCESS,
  CREATE_SUBSCRIBER_TRANSACTION_FAILURE,
  UPDATE_SUBSCRIBER_TRANSACTION,
  UPDATE_SUBSCRIBER_TRANSACTION_SUCCESS,
  UPDATE_SUBSCRIBER_TRANSACTION_FAILURE,
  DELETE_SUBSCRIBER_TRANSACTION,
  DELETE_SUBSCRIBER_TRANSACTION_SUCCESS,
  DELETE_SUBSCRIBER_TRANSACTION_FAILURE,
} from "./actionTypes";

export const getAllSubscribers = (payload) => {
  return {
    type: GET_ALL_SUBSCRIBERS,
    payload,
  };
};

export const getAllSubscribersSuccess = (subscribers) => {
  return {
    type: GET_ALL_SUBSCRIBERS_SUCCESS,
    payload: subscribers,
  };
};

export const getAllSubscribersFailure = (error) => {
  return {
    type: GET_ALL_SUBSCRIBERS_FAILURE,
    payload: error,
  };
};

export const getSingleSubscriber = (id) => {
  return {
    type: GET_SINGLE_SUBSCRIBER,
    payload: id,
  };
};

export const getSingleSubscriberSuccess = (subscriber) => {
  return {
    type: GET_SINGLE_SUBSCRIBER_SUCCESS,
    payload: subscriber,
  };
};

export const getSingleSubscriberFailure = (error) => {
  return {
    type: GET_SINGLE_SUBSCRIBER_FAILURE,
    payload: error,
  };
};

export const addSubscriber = (payload) => {
  return {
    type: ADD_SUBSCRIBER,
    payload,
  };
};

export const addSubscriberSuccess = (payload) => {
  return {
    type: ADD_SUBSCRIBER_SUCCESS,
    payload,
  };
};

export const addSubscriberFailure = (error) => {
  return {
    type: ADD_SUBSCRIBER_FAILURE,
    payload: error,
  };
};

export const editSubscriber = (payload) => {
  return {
    type: EDIT_SUBSCRIBER,
    payload,
  };
};

export const editSubscriberSuccess = (payload) => {
  return {
    type: EDIT_SUBSCRIBER_SUCCESS,
    payload,
  };
};

export const editSubscriberFailure = (error) => {
  return {
    type: EDIT_SUBSCRIBER_FAILURE,
    payload: error,
  };
};

export const deleteSubscriber = (id) => {
  return {
    type: DELETE_SUBSCRIBER,
    payload: id,
  };
};

export const deleteSubscriberSuccess = (id) => {
  return {
    type: DELETE_SUBSCRIBER_SUCCESS,
    payload: id,
  };
};

export const deleteSubscriberFailure = (error) => {
  return {
    type: DELETE_SUBSCRIBER_FAILURE,
    payload: error,
  };
};

// token generation

export const regenerateSubscriberToken = (id) => {
  return {
    type: REGENERATE_SUBSCRIBER_TOKEN,
    payload: id,
  };
};

export const regenerateSubscriberTokenSuccess = (subscriber) => {
  return {
    type: REGENERATE_SUBSCRIBER_TOKEN_SUCCESS,
    payload: subscriber,
  };
};

export const regenerateSubscriberTokenFailure = (error) => {
  return {
    type: REGENERATE_SUBSCRIBER_TOKEN_FAILURE,
    payload: error,
  };
};

//  subscriber transaction

export const createSubscriberTransaction = (payload) => {
  return {
    type: CREATE_SUBSCRIBER_TRANSACTION,
    payload,
  };
};

export const createSubscriberTransactionSuccess = (payload) => {
  return {
    type: CREATE_SUBSCRIBER_TRANSACTION_SUCCESS,
    payload,
  };
};

export const createSubscriberTransactionFailure = (error) => {
  return {
    type: CREATE_SUBSCRIBER_TRANSACTION_FAILURE,
    payload: error,
  };
};

export const updateSubscriberTransaction = (payload) => {
  return {
    type: UPDATE_SUBSCRIBER_TRANSACTION,
    payload,
  };
};

export const updateSubscriberTransactionSuccess = (payload) => {
  return {
    type: UPDATE_SUBSCRIBER_TRANSACTION_SUCCESS,
    payload,
  };
};

export const updateSubscriberTransactionFailure = (error) => {
  return {
    type: UPDATE_SUBSCRIBER_TRANSACTION_FAILURE,
    payload: error,
  };
};

export const deleteSubscriberTransaction = (payload) => {
  return {
    type: DELETE_SUBSCRIBER_TRANSACTION,
    payload,
  };
};

export const deleteSubscriberTransactionSuccess = (payload) => {
  return {
    type: DELETE_SUBSCRIBER_TRANSACTION_SUCCESS,
    payload,
  };
};

export const deleteSubscriberTransactionFailure = (error) => {
  return {
    type: DELETE_SUBSCRIBER_TRANSACTION_FAILURE,
    payload: error,
  };
};
