import React from "react";
import EmployeeBlock from "./EmployeeBlock";
// import { activeUsersData } from "../../data";

const EmployeesList = ({ employeesList }) => {
  const employeesListItems = employeesList?.map((employee) => {
    return <EmployeeBlock key={employee?.id} employee={employee} />;
  });
  return <>{employeesListItems}</>;
};

export default EmployeesList;
