import { all } from "redux-saga/effects";

//public
import AuthenticationSaga from "./authentication/saga";
import DriversSaga from "./drivers/saga";
import AdminsSaga from "./admins/saga";
import PaymentsSaga from "./payments/saga";
import ordersSaga from "./orders/saga";
import rolesSaga from "./roles/saga";
import subscribersSaga from "./subscribers/saga";
import ToasterSaga from "./toastify/saga";
import settingsSaga from "./settings/saga";
import banksSaga from "./banks/saga";
import contactsSaga from "./contacts/saga";
import transactionRequestsSaga from "./transactionRequests/saga";
import permissionsSaga from "./permissions/saga";
import homeSaga from "./home/saga";

export default function* rootSaga() {
  yield all([
    AuthenticationSaga(),
    DriversSaga(),
    PaymentsSaga(),
    AdminsSaga(),
    ordersSaga(),
    permissionsSaga(),
    rolesSaga(),
    subscribersSaga(),
    homeSaga(),
    settingsSaga(),
    banksSaga(),
    contactsSaga(),
    transactionRequestsSaga(),
    ToasterSaga(),
  ]);
}
