import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import Table from "views/banks/Table";
import ControlArea from "components/Layout/ControlArea";
import ProjectsIcon from "assets/svgs/projectsIcon.svg";
import { getAllBanks } from "store/actions";
import Loader from "components/shared/Loader";
import { useLocation } from "react-router-dom";
import BootyPagination from "components/shared/Pagination";

const Banks = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const { loading } = useSelector((state) => state?.banks) || {};
  const { banks, metaData } = useSelector((state) => state?.banks) || {};
  const { totalResults } = metaData || {};

  useEffect(() => {
    dispatch(getAllBanks(search));
  }, [dispatch, search]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <ControlArea
        btnTxt="اضافه بنك"
        cardTxt={`${intl.formatMessage({
          id: "total-banks",
        })} ${totalResults ?? ""} `}
        icon={ProjectsIcon}
        url="addbank"
      />
      <Table data={banks} />
      {totalResults > 10 && <BootyPagination metaData={metaData} />}
    </>
  );
};

export default Banks;
