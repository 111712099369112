import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProjectsIcon from "assets/svgs/projectsIcon.svg";

import Table from "./Table";
import { getAllSubscribers } from "store/actions";
import Loader from "components/shared/Loader";
import ControlArea from "components/Layout/ControlArea";
import BootyPagination from "components/shared/Pagination";
import { useLocation } from "react-router-dom";

const Subscribers = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { loading } = useSelector((state) => state.subscribers);

  const { subscribers, metaData } = useSelector((state) => state.subscribers);
  const { totalResults } = metaData || {};

  useEffect(() => {
    dispatch(getAllSubscribers({ search }));
  }, [dispatch, search]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <ControlArea
        btnTxt="اضافه مشترك"
        cardTxt={`اجمالي المشتركين ${totalResults ?? ""}`}
        icon={ProjectsIcon}
        url="addsubscriber"
      />
      <Table data={subscribers} />
      {totalResults > 10 && <BootyPagination metaData={metaData} />}
    </>
  );
};

export default Subscribers;
