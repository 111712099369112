import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Table from "./Table";
import ControlArea from "components/Layout/ControlArea";
import ProjectsIcon from "assets/svgs/projectsIcon.svg";
import Loader from "components/shared/Loader";
import { getAllAdmins } from "store/admins/actions";
import BootyPagination from "components/shared/Pagination";
import { useLocation } from "react-router-dom";

const Admins = () => {
  const dispatch = useDispatch();
  let { search } = useLocation();

  const { loading } = useSelector((state) => state?.admins);
  const { admins, metaData } = useSelector((state) => state?.admins);
  const { totalResults } = metaData || {};
  useEffect(() => {
    dispatch(getAllAdmins(search));
  }, [dispatch, search]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <ControlArea
        btnTxt="اضافه مدير"
        cardTxt={`عدد المديرين ${totalResults ?? ""}`}
        icon={ProjectsIcon}
        url="addadmin"
      />
      <Table data={admins} />
      {totalResults > 10 && <BootyPagination metaData={metaData} />}
    </>
  );
};

export default Admins;
