import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createSubscriberTransaction,
  getSingleSubscriber,
} from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import { operationTypes, paymentTypes } from "data";
import { errorMessage } from "helpers/functions";

const CreateSubscriberTransaction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { subscriberId } = useParams();

  // fetch subscriber data
  useEffect(() => {
    dispatch(getSingleSubscriber(subscriberId));
  }, [dispatch, subscriberId]);

  const { singleSubscriber } = useSelector((state) => state?.subscribers) || {};
  const { bankAccounts, name } = singleSubscriber || {};

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      subscriber: name,
    },
  });

  const watchOperationInput = watch("operation");

  const transferOperation = watchOperationInput === "transfer";

  const noBankAccounts = bankAccounts?.length === 0;

  useEffect(() => {
    setValue("subscriber", name);
  }, [name, setValue, singleSubscriber]);

  const onSubmit = async (data) => {
    data["subscriber"] = subscriberId;
    if (!transferOperation) delete data["bankAccount"];

    dispatch(createSubscriberTransaction({ data, navigate, subscriberId }));
  };

  return (
    <>
      <div className="add-project-wrp">
        <div className="add-form">
          <div className="card">
            <div className="card-head">
              <h4> اجراء تحويل</h4>
            </div>
            <div className="card-body">
              <form action="#" method="get" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md={6} xs={12}>
                    <div className="form-group required">
                      <h5>المشترك </h5>
                      <div>
                        <svg
                          width="13"
                          height="16"
                          viewBox="0 0 13 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.5415 4.33333C2.5415 2.14721 4.31371 0.375 6.49984 0.375C8.68596 0.375 10.4582 2.14721 10.4582 4.33333C10.4582 6.51946 8.68596 8.29167 6.49984 8.29167C4.31371 8.29167 2.5415 6.51946 2.5415 4.33333ZM6.49984 6.70833C7.81151 6.70833 8.87484 5.64501 8.87484 4.33333C8.87484 3.02166 7.81151 1.95833 6.49984 1.95833C5.18816 1.95833 4.12484 3.02166 4.12484 4.33333C4.12484 5.64501 5.18816 6.70833 6.49984 6.70833Z"
                            fill="#838383"
                          />
                          <path
                            d="M2.02149 10.9383C0.833764 12.1261 0.166504 13.737 0.166504 15.4167H1.74984C1.74984 14.1569 2.25028 12.9487 3.14108 12.0579C4.03188 11.1671 5.24006 10.6667 6.49984 10.6667C7.75962 10.6667 8.9678 11.1671 9.85859 12.0579C10.7494 12.9487 11.2498 14.1569 11.2498 15.4167H12.8332C12.8332 13.737 12.1659 12.1261 10.9782 10.9383C9.79045 9.75059 8.17954 9.08333 6.49984 9.08333C4.82013 9.08333 3.20922 9.75059 2.02149 10.9383Z"
                            fill="#838383"
                          />
                        </svg>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={name}
                          {...register("subscriber", {
                            required: errorMessage,
                            disabled: true,
                          })}
                        />
                      </div>
                    </div>
                    {errors?.subscriber && (
                      <p className="error-message">
                        {errors?.subscriber?.message}
                      </p>
                    )}
                  </Col>
                  <Col md={6} xs={12}>
                    <div className="form-group required">
                      <h5>المبلغ </h5>
                      <div>
                        <svg
                          width="13"
                          height="16"
                          viewBox="0 0 13 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.5415 4.33333C2.5415 2.14721 4.31371 0.375 6.49984 0.375C8.68596 0.375 10.4582 2.14721 10.4582 4.33333C10.4582 6.51946 8.68596 8.29167 6.49984 8.29167C4.31371 8.29167 2.5415 6.51946 2.5415 4.33333ZM6.49984 6.70833C7.81151 6.70833 8.87484 5.64501 8.87484 4.33333C8.87484 3.02166 7.81151 1.95833 6.49984 1.95833C5.18816 1.95833 4.12484 3.02166 4.12484 4.33333C4.12484 5.64501 5.18816 6.70833 6.49984 6.70833Z"
                            fill="#838383"
                          />
                          <path
                            d="M2.02149 10.9383C0.833764 12.1261 0.166504 13.737 0.166504 15.4167H1.74984C1.74984 14.1569 2.25028 12.9487 3.14108 12.0579C4.03188 11.1671 5.24006 10.6667 6.49984 10.6667C7.75962 10.6667 8.9678 11.1671 9.85859 12.0579C10.7494 12.9487 11.2498 14.1569 11.2498 15.4167H12.8332C12.8332 13.737 12.1659 12.1261 10.9782 10.9383C9.79045 9.75059 8.17954 9.08333 6.49984 9.08333C4.82013 9.08333 3.20922 9.75059 2.02149 10.9383Z"
                            fill="#838383"
                          />
                        </svg>
                        <input
                          type="number"
                          className="form-control form-outline"
                          placeholder=" المبلغ  "
                          {...register("amount", { required: errorMessage })}
                        />
                      </div>
                    </div>
                    {errors?.amount && (
                      <p className="error-message">{errors?.amount?.message}</p>
                    )}
                  </Col>
                  <Col md={6} xs={12}>
                    <div className="form-group required">
                      <h5> نوع العمليه</h5>
                      <div>
                        <select
                          className="form-control "
                          {...register("type", { required: errorMessage })}
                        >
                          <option value="" selected disabled hidden>
                            اختر
                          </option>
                          {paymentTypes?.map((paymentType) => (
                            <option
                              key={paymentType?.id}
                              value={paymentType?.type}
                            >
                              {paymentType?.placeholder}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {errors?.type && (
                      <p className="error-message">{errors?.type?.message}</p>
                    )}
                  </Col>

                  <Col md={6} xs={12}>
                    <div className="form-group required">
                      <h5>تعليق اضافي </h5>
                      <div>
                        <svg
                          width="13"
                          height="16"
                          viewBox="0 0 13 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.5415 4.33333C2.5415 2.14721 4.31371 0.375 6.49984 0.375C8.68596 0.375 10.4582 2.14721 10.4582 4.33333C10.4582 6.51946 8.68596 8.29167 6.49984 8.29167C4.31371 8.29167 2.5415 6.51946 2.5415 4.33333ZM6.49984 6.70833C7.81151 6.70833 8.87484 5.64501 8.87484 4.33333C8.87484 3.02166 7.81151 1.95833 6.49984 1.95833C5.18816 1.95833 4.12484 3.02166 4.12484 4.33333C4.12484 5.64501 5.18816 6.70833 6.49984 6.70833Z"
                            fill="#838383"
                          />
                          <path
                            d="M2.02149 10.9383C0.833764 12.1261 0.166504 13.737 0.166504 15.4167H1.74984C1.74984 14.1569 2.25028 12.9487 3.14108 12.0579C4.03188 11.1671 5.24006 10.6667 6.49984 10.6667C7.75962 10.6667 8.9678 11.1671 9.85859 12.0579C10.7494 12.9487 11.2498 14.1569 11.2498 15.4167H12.8332C12.8332 13.737 12.1659 12.1261 10.9782 10.9383C9.79045 9.75059 8.17954 9.08333 6.49984 9.08333C4.82013 9.08333 3.20922 9.75059 2.02149 10.9383Z"
                            fill="#838383"
                          />
                        </svg>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder=" تعليق اضافي  "
                          {...register("description", {
                            required: errorMessage,
                          })}
                        />
                      </div>
                    </div>
                    {errors?.description && (
                      <p className="error-message">
                        {errors?.description?.message}
                      </p>
                    )}
                  </Col>

                  <Col md={6} xs={12}>
                    <div className="form-group required">
                      <h5> طريقه التحويل</h5>
                      <div>
                        <select
                          className="form-control "
                          {...register("operation", { required: errorMessage })}
                        >
                          <option value="" selected disabled hidden>
                            اختر
                          </option>

                          {operationTypes?.map((operationType) => (
                            <option
                              key={operationType?.id}
                              value={`${operationType?.type}`}
                            >
                              {`${operationType?.placeholder}`}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {errors?.operation && (
                      <p className="error-message">
                        {errors?.operation?.message}
                      </p>
                    )}
                  </Col>

                  {transferOperation && (
                    <Col md={6} xs={12}>
                      <div className="form-group required">
                        <h5> الحساب البنكي</h5>
                        <div>
                          <select
                            name="bankAccount"
                            className="form-control "
                            {...register("bankAccount", {
                              required: errorMessage,
                            })}
                          >
                            {bankAccounts?.map((bankAccount) => (
                              <option
                                key={Math?.random() * Date?.now()}
                                value={bankAccount?._id}
                              >
                                {`name: ${bankAccount?.bank?.name} `}
                                &nbsp; &nbsp; &nbsp;
                                {`id: ${bankAccount?.bank?.id} `}
                              </option>
                            ))}
                          </select>
                        </div>
                        {noBankAccounts ? (
                          <p className="error-message">
                            {"لايوجد حسابات بنكيه "}
                          </p>
                        ) : null}

                        {errors?.bankAccount && (
                          <p style={{ color: "red", fontSize: "15px" }}>
                            {errors?.bankAccount?.message}
                          </p>
                        )}
                      </div>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col lg={3} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSubscriberTransaction;
