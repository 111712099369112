import StatusLabel from "components/shared/StatusLabel/StatusLabel";
import { handleImage } from "helpers/functions";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./DriverPersonalInfo.module.css";
const DriverPersonalInfo = ({ singleDriver }) => {
  const { name, email, phone, balance, image, type, address } =
    singleDriver || {};
  const { status } = singleDriver?.location || {};

  const statusLabelColor =
    status === "available" ? "green" : status === "inOrder" ? "orange" : "red";

  return (
    <div className={styles["card-container"]}>
      <div className={`card ${styles["card-margin"]}`}>
        <Row>
          <Col>
            <div className={styles["card-header"]}>
              <div className={styles["wrapper"]}>
                <div className={styles["image"]}>
                  <img src={handleImage(image)} alt="driver" />
                </div>
                <div className={styles["info"]}>
                  <h4>{name}</h4>
                  <p>{type}</p>
                </div>
              </div>
              <div>
                <StatusLabel text={"inOrder"} color={statusLabelColor} />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={5} md={6} xs={12} style={{ marginTop: "10px" }}>
            <div className={styles["details"]}>
              <h4>{name}</h4>
              <p>{type}</p>
            </div>
            <div className={`${styles["details"]} ${styles["top-margin"]}  `}>
              <h4>العنوان : </h4>
              <p>{address}</p>
            </div>
          </Col>
          <Col lg={5} md={6} xs={12} style={{ marginTop: "10px" }}>
            <div className={styles["details"]}>
              <h4>البريد الالكتروني</h4>
              <p>{email}</p>
            </div>

            <div className={`${styles["details"]} ${styles["top-margin"]}  `}>
              <h4>الهاتف : </h4>
              <p>{phone}</p>
            </div>
          </Col>
          <Col lg={2} md={6} xs={12} style={{ marginTop: "10px" }}>
            <div className={`${styles["details"]} ${styles["orange-text"]}`}>
              <h4>الرصيد : </h4>
              <p>{balance}</p>
            </div>
            <div
              className={`${styles["details"]} ${styles["red-text"]} ${styles["top-margin"]}`}
            >
              <h4>الرصيد : </h4>
              <p>{balance}</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DriverPersonalInfo;
