import React from "react";
import CustomerBlock from "./CustomerBlock";
// import { activeCustomersData } from "data";

const CustomersList = ({ customerList }) => {
  const customersListItems = customerList?.map((customer) => {
    return <CustomerBlock key={customer?.id} customer={customer} />;
  });
  return <>{customersListItems}</>;
};

export default CustomersList;
