import React, { useEffect } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSingleSubscriber } from "store/actions";
import { useParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import SubscriberPersonalInfo from "components/SubscriberProfile/SubscriberPersonalInfo/SubscriberPersonalInfo";
import { SubscriberTransactionDetails } from "components/SubscriberProfile/SubscriberTransactionDetails/SubscriberTransactionDetails";
import { SubscriberBankAccountsDetails } from "components/SubscriberProfile/SubscriberBankAccountsDetails/SubscriberBankAccountsDetails";
import { SubscriberOrdersDetails } from "components/SubscriberProfile/SubscriberOrdersDetails/SubscriberOrdersDetails";

const ShowSubscriber = () => {
  // instances
  const dispatch = useDispatch();
  const { subscriberId } = useParams();

  const { loading, error, singleSubscriber } = useSelector(
    (state) => state?.subscribers
  );

  // fetch subscriber data
  useEffect(() => {
    dispatch(getSingleSubscriber(subscriberId));
  }, [dispatch, subscriberId]);

  const { transactions, credentialFiles, bankAccounts, orders } =
    singleSubscriber || {};

  return (
    <>
      {error ? (
        "error"
      ) : loading ? (
        <Loader />
      ) : (
        <div className="add-project-wrp">
          <div className="add-form">
            <div className="">
              <Container>
                <Row style={{ marginBottom: "20px" }}>
                  <Col lg={12} xs={12}>
                    <SubscriberPersonalInfo
                      singleSubscriber={singleSubscriber}
                    />
                  </Col>
                </Row>

                {credentialFiles && credentialFiles.length >= 1 && (
                  <Col xs={12}>
                    <div className="form-group card">
                      <h4> الملفات </h4>
                      <Row className="my-5 ">
                        {credentialFiles?.map((file) => (
                          <Col xs={12} lg={4} key={file?.path}>
                            <a
                              href={file?.path}
                              target="_blank"
                              rel="noreferrer"
                              download={file?.filename}
                              className="download-file my-3"
                            >
                              <span>{file?.originalname}</span>
                              <span>
                                <svg
                                  width="24"
                                  height="16"
                                  viewBox="0 0 24 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19 16.0001H6.00004C2.87441 16.002 0.270931 13.604 0.0164008 10.4887C-0.238129 7.37347 1.94156 4.58465 5.02604 4.07911C6.44575 1.562 9.11015 0.00362534 12 5.65992e-05C13.8021 -0.00675485 15.5525 0.601415 16.962 1.72411C18.3461 2.82197 19.3301 4.34509 19.762 6.05811C22.3459 6.45514 24.1878 8.77563 23.9881 11.3822C23.7883 13.9888 21.6143 16.0015 19 16.0001ZM12 2.0001C9.83175 2.00267 7.83271 3.17221 6.76804 5.06111L6.30004 5.90011L5.35104 6.05511C3.30132 6.39852 1.85605 8.25441 2.02525 10.3258C2.19446 12.3972 3.92176 13.9939 6.00004 14.0001H19C20.5686 14.0017 21.8736 12.7947 21.9942 11.2308C22.1149 9.66685 21.0103 8.27401 19.46 8.03511L18.144 7.83511L17.822 6.54311C17.1573 3.86992 14.7546 1.99507 12 2.0001ZM13.45 12.0001H10.55V9.00011H8.00004L12 5.00011L16 9.00011H13.45V12.0001Z"
                                    fill="#2E3A59"
                                  />
                                </svg>
                              </span>
                            </a>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </Col>
                )}

                <Tabs
                  defaultActiveKey="transications"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="transications" title="المحفظه">
                    <SubscriberTransactionDetails tableData={transactions} />
                  </Tab>
                  <Tab eventKey="orders" title="عمليات التوصيل">
                    <SubscriberOrdersDetails tableData={orders} />
                  </Tab>
                  <Tab eventKey="bankAccounts" title="الحسابات البنكيه"   >
                    <SubscriberBankAccountsDetails tableData={bankAccounts} />
                  </Tab>

                </Tabs>
                {/* <Row>
                  <SubscriberTransactionDetails tableData={transactions} />
                </Row>
                <Row>
                  <SubscriberOrdersDetails tableData={orders} />
                </Row>
                <Row>
                  <SubscriberBankAccountsDetails tableData={bankAccounts} />
                </Row> */}
              </Container>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShowSubscriber;
