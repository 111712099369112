export const GET_ALL_PERMISSIONS = "GET_ALL_PERMISSIONS";
export const GET_ALL_PERMISSIONS_SUCCESS = "GET_ALL_PERMISSIONS_SUCCESS";
export const GET_ALL_PERMISSIONS_FAILURE = "GET_ALL_PERMISSIONS_FAILURE";

export const GET_SINGLE_PERMISSION = "GET_SINGLE_PERMISSION";
export const GET_SINGLE_PERMISSION_SUCCESS = "GET_SINGLE_PERMISSION_SUCCESS";
export const GET_SINGLE_PERMISSION_FAILURE = "GET_SINGLE_PERMISSION_FAILURE";

export const ADD_PERMISSION = "ADD_PERMISSION";
export const ADD_PERMISSION_SUCCESS = "ADD_PERMISSION_SUCCESS";
export const ADD_PERMISSION_FAILURE = "ADD_PERMISSION_FAILURE";

export const EDIT_PERMISSION = "EDIT_PERMISSION";
export const EDIT_PERMISSION_SUCCESS = "EDIT_PERMISSION_SUCCESS";
export const EDIT_PERMISSION_FAILURE = "EDIT_PERMISSION_FAILURE";

export const DELETE_PERMISSION = "DELETE_PERMISSION";
export const DELETE_PERMISSION_SUCCESS = "DELETE_PERMISSION_SUCCESS";
export const DELETE_PERMISSION_FAILURE = "DELETE_PERMISSION_FAILURE";
