import {
  GET_ALL_SETTINGS,
  GET_ALL_SETTINGS_SUCCESS,
  GET_ALL_SETTINGS_FAILURE,
  GET_SINGLE_SETTING,
  GET_SINGLE_SETTING_SUCCESS,
  GET_SINGLE_SETTING_FAILURE,
  DELETE_SETTING,
  DELETE_SETTING_SUCCESS,
  DELETE_SETTING_FAILURE,
  ADD_SETTING,
  ADD_SETTING_SUCCESS,
  ADD_SETTING_FAILURE,
  EDIT_SETTING,
  EDIT_SETTING_FAILURE,
  EDIT_SETTING_SUCCESS,
} from "./actionTypes";

const initialState = {
  settings: [],
  singleSetting: [],
  loading: false,
  error: "",
};

const setting = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_SETTINGS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ALL_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        settings: payload.settings,
      };
      break;
    case GET_ALL_SETTINGS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    case GET_SINGLE_SETTING:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_SETTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleSetting: payload.settings,
      };
      break;
    case GET_SINGLE_SETTING_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
        singleSetting: {},
      };
      break;

    case ADD_SETTING:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_SETTING_SUCCESS:
      const settingArrayAfterAddingSetting = [
        ...state?.settings,
        payload.settings,
      ];
      state = {
        ...state,
        loading: false,
        settings: settingArrayAfterAddingSetting,
        error: "",
      };
      break;
    case ADD_SETTING_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    case EDIT_SETTING:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_SETTING_SUCCESS: {
      const settingArrayAfterEditingSetting = payload.settings;
      state = {
        ...state,
        loading: false,
        singleSetting: settingArrayAfterEditingSetting,
      };
      break;
    }
    case EDIT_SETTING_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    case DELETE_SETTING:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_SETTING_SUCCESS:
      const settingArrayAfterDeletingSetting = [
        ...state?.settings?.filter((setting) => setting?.id !== payload),
      ];
      state = {
        ...state,
        loading: false,
        settings: settingArrayAfterDeletingSetting,
      };
      break;
    case DELETE_SETTING_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default setting;
