import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = (props) => {
  const sidebarToggle = (e) => {

    if (window.innerWidth > 1024 && !e.target.closest(".toggleSidebar")) {
      return
    }
    document.querySelector("body").classList.toggle("sidebarToggled");
    document.querySelector(".overlay-s").classList.toggle("fire");
    document.querySelector("html").classList.toggle("offScroll");
  };

  return (
    <div className="main-wrapper">
      <Header logo={props?.logo} sidebarToggle={sidebarToggle} />
      <Sidebar logo={props?.logo} sidebarToggle={sidebarToggle} />
      <div className="content-wrap" id="content-wrapper">
        {props.children}
        <Outlet />
      </div>
    </div>
  );
};
export default Layout;
