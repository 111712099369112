import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSingleOrder } from "store/actions";
import { useParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import OrdersList from "components/OrderShow/OrdersList/OrdersList";
import { OrderDetails } from "components/OrderShow/OrderDetails/OrderDetails";
import OrderHistory from "components/OrderShow/OrderHistory/OrderHistory";

const ShowOrder = () => {
  // instances
  const dispatch = useDispatch();
  const { id } = useParams();

  // fetch order data
  useEffect(() => {
    dispatch(getSingleOrder(id));
  }, [dispatch, id]);

  const {
    loading,
    error,
    singleOrder: order,
  } = useSelector((state) => state?.orders);

  return (
    <>
      {error ? (
        "error"
      ) : loading ? (
        <Loader />
      ) : (
        <div className="add-project-wrp">
          <div className="add-form">
            <div className="">
              <Container>
                <Row>
                  <Col xs={12} lg={12}>
                    <OrdersList order={order} />
                  </Col>
                  <Col xs={12} lg={6}>
                    <OrderDetails order={order} />
                  </Col>
                  <Col xs={12} lg={6}>
                    <OrderHistory order={order} />
                  </Col>
                </Row>

              </Container>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShowOrder;
