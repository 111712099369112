import React from "react";
import { Col, Row } from "react-bootstrap";

// helper
import { handleImage, handlePrice } from "helpers/functions";

// styles
import styles from "./HistoryItem.module.css";
import { Link } from "react-router-dom";
const HistoryItem = ({ item }) => {
  const { driver, status } = item || {};
  const { image, name, id } = driver || {};

  return (
    <div className={`${styles["wrapper"]} order-wrapper `}>
      <div className={styles["product"]}>
        <Row>
          <Col xs={6} lg={2} md={3}>
            <div className={styles["image"]}>
              <img src={handleImage(image)} alt="product" />
            </div>
          </Col>
          <Col xs={6} lg={4} md={3}>
            <Link to={`/drivers/showdriver/${id}`}>
              <p>{"اسم السائق"} </p>
              <span>{name}</span>
            </Link>
          </Col>
          {/* // TODO  fix the display of this column  */}
          <Col xs={6} lg={6} md={6}>
            <div className="">
              <p>حاله الطلب : </p>
              <span>{status}</span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HistoryItem;
