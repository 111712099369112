import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Table from "./Table";
import ControlArea from "components/Layout/ControlArea";
import ProjectsIcon from "assets/svgs/projectsIcon.svg";
import Loader from "components/shared/Loader";
import { getAllRoles } from "store/roles/actions";

const Roles = () => {
  const dispatch = useDispatch();

  const { roles, loading } = useSelector((state) => state.roles);
  const rolesLength = roles?.length;

  useEffect(() => {
    dispatch(getAllRoles());
  }, [dispatch]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <ControlArea
        btnTxt="اضافه صلاحيه"
        cardTxt={`عدد الصلاحيات ${rolesLength}`}
        icon={ProjectsIcon}
        url="addrole"
      />
      <Table data={roles} />
    </>
  );
};

export default Roles;
