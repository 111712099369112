// import { handleImage } from "@/helpers/functions";
import { handleImage } from "helpers/functions";
import React from "react";
import { Col } from "react-bootstrap";

const PaymentCard = ({ payment }) => {
  const { name, image } = payment;
  // useEffect(() => {
  //   const checkst = document.querySelector(".switcher-input");

  //   if (checkst.checked == true) {
  //     document.querySelector(".card").classList.add("active");
  //   }
  // }, []);

  return (
    <>
      <Col xl={4} lg={4} md={6} sm={6} xs={12}>
        <div className="card">
          <div className="card-inner">
            <div className="c-img">
              <img src={handleImage(image)} width={120} height={40} alt="" />
            </div>
            <div className="mt-4">
              <h3>{name.ar} </h3>
            </div>
            <div className="switcher">
              <input
                type="checkbox"
                className="switcher-input"
                defaultChecked={false}
              />
              <div className="knobs"></div>
              <div className="layer"></div>
            </div>
            <div className="checker">
              <input type="checkbox" />
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default PaymentCard;
