import React from "react";
import { Col, Dropdown } from "react-bootstrap";

const ProjectBlockItem = ({ proj }) => {
  const { title, count, className } = proj || {};

  const showComponent = count || count === 0;

  return (
    <>
      {showComponent ? (
        <div className={`card ${className}`}>
          <div className="b-info">
            <h4>{title}</h4>
            <p>{count}</p>
          </div>
          {/* <div className="b-action">
            <Dropdown>
              <Dropdown.Toggle>
                <svg
                  width="19"
                  height="6"
                  viewBox="0 0 19 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.0083 0.955872C17.2277 0.955872 18.2255 1.9536 18.2255 3.17304C18.2255 4.39248 17.2277 5.3902 16.0083 5.3902C14.7889 5.3902 13.7911 4.39248 13.7911 3.17304C13.7911 1.9536 14.7889 0.955872 16.0083 0.955872ZM2.7053 0.955872C3.92474 0.955872 4.92247 1.9536 4.92247 3.17304C4.92247 4.39248 3.92474 5.3902 2.7053 5.3902C1.48586 5.3902 0.488134 4.39248 0.488134 3.17304C0.488134 1.9536 1.48586 0.955872 2.7053 0.955872ZM9.3568 0.955872C10.5762 0.955872 11.574 1.9536 11.574 3.17304C11.574 4.39248 10.5762 5.3902 9.3568 5.3902C8.13736 5.3902 7.13963 4.39248 7.13963 3.17304C7.13963 1.9536 8.13736 0.955872 9.3568 0.955872Z"
                    fill="white"
                  ></path>
                </svg>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
          <svg
            width="69"
            height="22"
            viewBox="0 0 69 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M66.8945 9.86943C66.1159 13.8603 63.0145 21.2878 55.8087 20.7335C48.6029 20.1793 47.7398 1.88763 35.1257 1.88763C22.5116 1.88763 22.5116 22.9824 1.48812 4.73828"
              stroke="white"
              strokeWidth="2.21717"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </div>
      ) : null}
    </>
  );
};

export default ProjectBlockItem;
