import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSingleDriver, getSingleTransactionRequest } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import { useIntl } from "react-intl";

const ShowTransactionRequest = () => {
  // instances
  const navigate = useNavigate();
  const intl = useIntl();

  const dispatch = useDispatch();
  const { transactionRequestId } = useParams();

  const { loading, error, singleTransactionRequest } = useSelector(
    (state) => state?.transactionRequests || {}
  );

  // fetch transactionRequest data
  useEffect(() => {
    dispatch(getSingleTransactionRequest(transactionRequestId));
  }, [dispatch, transactionRequestId]);

  const { amount, bankAccount, status, driver } =
    singleTransactionRequest || {};

  const { bank, userName: bankAccountUserName } = bankAccount || {};
  const { id: bankId, name: bankName, logo: bankLogo } = bank || {};

  useEffect(() => {
    dispatch(getSingleDriver(driver));
  }, [dispatch, driver]);

  const { singleDriver } = useSelector((state) => state?.drivers || {});
  const { name, email, phone } = singleDriver || {};

  const onSubmit = (data) => {
    navigate(`/transactionrequests`);
  };

  const statusOfTransactionRequest = intl.formatMessage({ id: `${status}` });

  return (
    <>
      {error ? (
        "error"
      ) : loading ? (
        <Loader />
      ) : (
        <div className="add-project-wrp">
          <div className="add-form">
            <div className="card">
              <div className="card-head">
                <h4> بيانات التحويل</h4>
              </div>
              <div className="card-body">
                <form action="#" method="get" onSubmit={onSubmit}>
                  <Row>
                    <Col lg={6} xs={12}>
                      <div className="form-group required">
                        <h5>الإسم </h5>
                        <div>
                          <svg
                            width="13"
                            height="16"
                            viewBox="0 0 13 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5415 4.33333C2.5415 2.14721 4.31371 0.375 6.49984 0.375C8.68596 0.375 10.4582 2.14721 10.4582 4.33333C10.4582 6.51946 8.68596 8.29167 6.49984 8.29167C4.31371 8.29167 2.5415 6.51946 2.5415 4.33333ZM6.49984 6.70833C7.81151 6.70833 8.87484 5.64501 8.87484 4.33333C8.87484 3.02166 7.81151 1.95833 6.49984 1.95833C5.18816 1.95833 4.12484 3.02166 4.12484 4.33333C4.12484 5.64501 5.18816 6.70833 6.49984 6.70833Z"
                              fill="#838383"
                            />
                            <path
                              d="M2.02149 10.9383C0.833764 12.1261 0.166504 13.737 0.166504 15.4167H1.74984C1.74984 14.1569 2.25028 12.9487 3.14108 12.0579C4.03188 11.1671 5.24006 10.6667 6.49984 10.6667C7.75962 10.6667 8.9678 11.1671 9.85859 12.0579C10.7494 12.9487 11.2498 14.1569 11.2498 15.4167H12.8332C12.8332 13.737 12.1659 12.1261 10.9782 10.9383C9.79045 9.75059 8.17954 9.08333 6.49984 9.08333C4.82013 9.08333 3.20922 9.75059 2.02149 10.9383Z"
                              fill="#838383"
                            />
                          </svg>
                          <input
                            type="text"
                            className="form-control form-outline"
                            value={name}
                            readOnly
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} xs={12}>
                      <div className="form-group required">
                        <h5>رقم الهاتف </h5>
                        <div>
                          <svg
                            width="13"
                            height="16"
                            viewBox="0 0 13 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5415 4.33333C2.5415 2.14721 4.31371 0.375 6.49984 0.375C8.68596 0.375 10.4582 2.14721 10.4582 4.33333C10.4582 6.51946 8.68596 8.29167 6.49984 8.29167C4.31371 8.29167 2.5415 6.51946 2.5415 4.33333ZM6.49984 6.70833C7.81151 6.70833 8.87484 5.64501 8.87484 4.33333C8.87484 3.02166 7.81151 1.95833 6.49984 1.95833C5.18816 1.95833 4.12484 3.02166 4.12484 4.33333C4.12484 5.64501 5.18816 6.70833 6.49984 6.70833Z"
                              fill="#838383"
                            />
                            <path
                              d="M2.02149 10.9383C0.833764 12.1261 0.166504 13.737 0.166504 15.4167H1.74984C1.74984 14.1569 2.25028 12.9487 3.14108 12.0579C4.03188 11.1671 5.24006 10.6667 6.49984 10.6667C7.75962 10.6667 8.9678 11.1671 9.85859 12.0579C10.7494 12.9487 11.2498 14.1569 11.2498 15.4167H12.8332C12.8332 13.737 12.1659 12.1261 10.9782 10.9383C9.79045 9.75059 8.17954 9.08333 6.49984 9.08333C4.82013 9.08333 3.20922 9.75059 2.02149 10.9383Z"
                              fill="#838383"
                            />
                          </svg>
                          <input
                            type="text"
                            className="form-control form-outline"
                            value={phone}
                            readOnly
                          />
                        </div>
                      </div>
                    </Col>

                    <Col lg={6} xs={12}>
                      <div className="form-group required">
                        <h5>المبلغ </h5>
                        <div>
                          <svg
                            width="13"
                            height="16"
                            viewBox="0 0 13 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5415 4.33333C2.5415 2.14721 4.31371 0.375 6.49984 0.375C8.68596 0.375 10.4582 2.14721 10.4582 4.33333C10.4582 6.51946 8.68596 8.29167 6.49984 8.29167C4.31371 8.29167 2.5415 6.51946 2.5415 4.33333ZM6.49984 6.70833C7.81151 6.70833 8.87484 5.64501 8.87484 4.33333C8.87484 3.02166 7.81151 1.95833 6.49984 1.95833C5.18816 1.95833 4.12484 3.02166 4.12484 4.33333C4.12484 5.64501 5.18816 6.70833 6.49984 6.70833Z"
                              fill="#838383"
                            />
                            <path
                              d="M2.02149 10.9383C0.833764 12.1261 0.166504 13.737 0.166504 15.4167H1.74984C1.74984 14.1569 2.25028 12.9487 3.14108 12.0579C4.03188 11.1671 5.24006 10.6667 6.49984 10.6667C7.75962 10.6667 8.9678 11.1671 9.85859 12.0579C10.7494 12.9487 11.2498 14.1569 11.2498 15.4167H12.8332C12.8332 13.737 12.1659 12.1261 10.9782 10.9383C9.79045 9.75059 8.17954 9.08333 6.49984 9.08333C4.82013 9.08333 3.20922 9.75059 2.02149 10.9383Z"
                              fill="#838383"
                            />
                          </svg>
                          <input
                            type="text"
                            className="form-control form-outline"
                            value={amount}
                            readOnly
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} xs={12}>
                      <div className="form-group required">
                        <h5>الحساب البنكي </h5>
                        <div>
                          <svg
                            width="13"
                            height="16"
                            viewBox="0 0 13 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5415 4.33333C2.5415 2.14721 4.31371 0.375 6.49984 0.375C8.68596 0.375 10.4582 2.14721 10.4582 4.33333C10.4582 6.51946 8.68596 8.29167 6.49984 8.29167C4.31371 8.29167 2.5415 6.51946 2.5415 4.33333ZM6.49984 6.70833C7.81151 6.70833 8.87484 5.64501 8.87484 4.33333C8.87484 3.02166 7.81151 1.95833 6.49984 1.95833C5.18816 1.95833 4.12484 3.02166 4.12484 4.33333C4.12484 5.64501 5.18816 6.70833 6.49984 6.70833Z"
                              fill="#838383"
                            />
                            <path
                              d="M2.02149 10.9383C0.833764 12.1261 0.166504 13.737 0.166504 15.4167H1.74984C1.74984 14.1569 2.25028 12.9487 3.14108 12.0579C4.03188 11.1671 5.24006 10.6667 6.49984 10.6667C7.75962 10.6667 8.9678 11.1671 9.85859 12.0579C10.7494 12.9487 11.2498 14.1569 11.2498 15.4167H12.8332C12.8332 13.737 12.1659 12.1261 10.9782 10.9383C9.79045 9.75059 8.17954 9.08333 6.49984 9.08333C4.82013 9.08333 3.20922 9.75059 2.02149 10.9383Z"
                              fill="#838383"
                            />
                          </svg>
                          <input
                            type="text"
                            className="form-control form-outline"
                            value={bankName}
                            readOnly
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} xs={12}>
                      <div className="form-group required">
                        <h5>اسم مستخدم الحساب البنكي </h5>
                        <div>
                          <svg
                            width="13"
                            height="16"
                            viewBox="0 0 13 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5415 4.33333C2.5415 2.14721 4.31371 0.375 6.49984 0.375C8.68596 0.375 10.4582 2.14721 10.4582 4.33333C10.4582 6.51946 8.68596 8.29167 6.49984 8.29167C4.31371 8.29167 2.5415 6.51946 2.5415 4.33333ZM6.49984 6.70833C7.81151 6.70833 8.87484 5.64501 8.87484 4.33333C8.87484 3.02166 7.81151 1.95833 6.49984 1.95833C5.18816 1.95833 4.12484 3.02166 4.12484 4.33333C4.12484 5.64501 5.18816 6.70833 6.49984 6.70833Z"
                              fill="#838383"
                            />
                            <path
                              d="M2.02149 10.9383C0.833764 12.1261 0.166504 13.737 0.166504 15.4167H1.74984C1.74984 14.1569 2.25028 12.9487 3.14108 12.0579C4.03188 11.1671 5.24006 10.6667 6.49984 10.6667C7.75962 10.6667 8.9678 11.1671 9.85859 12.0579C10.7494 12.9487 11.2498 14.1569 11.2498 15.4167H12.8332C12.8332 13.737 12.1659 12.1261 10.9782 10.9383C9.79045 9.75059 8.17954 9.08333 6.49984 9.08333C4.82013 9.08333 3.20922 9.75059 2.02149 10.9383Z"
                              fill="#838383"
                            />
                          </svg>
                          <input
                            type="text"
                            className="form-control form-outline"
                            value={bankAccountUserName}
                            readOnly
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} xs={12}>
                      <div className="form-group required">
                        <h5>الحاله </h5>
                        <div>
                          <svg
                            width="13"
                            height="16"
                            viewBox="0 0 13 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5415 4.33333C2.5415 2.14721 4.31371 0.375 6.49984 0.375C8.68596 0.375 10.4582 2.14721 10.4582 4.33333C10.4582 6.51946 8.68596 8.29167 6.49984 8.29167C4.31371 8.29167 2.5415 6.51946 2.5415 4.33333ZM6.49984 6.70833C7.81151 6.70833 8.87484 5.64501 8.87484 4.33333C8.87484 3.02166 7.81151 1.95833 6.49984 1.95833C5.18816 1.95833 4.12484 3.02166 4.12484 4.33333C4.12484 5.64501 5.18816 6.70833 6.49984 6.70833Z"
                              fill="#838383"
                            />
                            <path
                              d="M2.02149 10.9383C0.833764 12.1261 0.166504 13.737 0.166504 15.4167H1.74984C1.74984 14.1569 2.25028 12.9487 3.14108 12.0579C4.03188 11.1671 5.24006 10.6667 6.49984 10.6667C7.75962 10.6667 8.9678 11.1671 9.85859 12.0579C10.7494 12.9487 11.2498 14.1569 11.2498 15.4167H12.8332C12.8332 13.737 12.1659 12.1261 10.9782 10.9383C9.79045 9.75059 8.17954 9.08333 6.49984 9.08333C4.82013 9.08333 3.20922 9.75059 2.02149 10.9383Z"
                              fill="#838383"
                            />
                          </svg>
                          <input
                            type="text"
                            className="form-control form-outline"
                            value={statusOfTransactionRequest}
                            readOnly
                          />
                        </div>
                      </div>
                    </Col>

                    <Col lg={3} xs={12}>
                      <div className="form-group">
                        <button type="submit" className="btn btn-blue">
                          {"العوده"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShowTransactionRequest;
