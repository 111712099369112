import React, { useEffect } from "react";
import BlocksTop from "components/Home/BlocksTop";
import ProjectsBlocks from "components/Projects/ProjectsBlocks";
import ReportBlocks from "components/Reports/ReportBlocks";
// import InProgressProjects from "components/Projects/InProgressProjects";
import UsersArea from "components/Home/UsersArea";
import { useDispatch, useSelector } from "react-redux";
import { getHomeData } from "store/home/actions";
import Loader from "components/shared/Loader";
const Dashboard = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state?.home) || {};
  const { statistics } = data || {};

  useEffect(() => {
    dispatch(getHomeData());
  }, [dispatch]);

  if (loading) return <Loader />
  return (
    <div>
      {/* <BlocksTop /> */}
      <ProjectsBlocks statistics={statistics} />
      <ReportBlocks />
      {/* <InProgressProjects /> */}
      <UsersArea />
    </div>
  );
};

export default Dashboard;
