import server from "./server";

export const getAllSettingsApi = async () => {
  const response = await server().get("/settings");
  return response.data;
};

export const getSingleSettingApi = async (slug) => {

  const response = await server().get(`/settings/${slug}`);
  return response.data;
};

export const addSettingApi = async ({ data }) => {
  const response = await server().post(`/settings`, data);
  return response.data;
};
export const deleteSettingApi = async (id) => {
  const response = await server().delete(`/settings/${id}`);
  return response.data;
};

export const editSettingApi = async (params) => {
  const { id, data } = params || {};
  const response = await server().put(`/settings/${id}`, data);
  return response.data;
};
