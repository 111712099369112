import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addSubscriberApi,
  createSubscriberTransactionApi,
  deleteSubscriberApi,
  deleteSubscriberTransactionApi,
  editSubscriberApi,
  getAllSubscribersApi,
  getSingleSubscriberApi,
  regenerateSubscriberTokenApi,
  updateSubscriberTransactionApi,
} from "api/subscribers";

// Login Redux States
import {
  ADD_SUBSCRIBER,
  CREATE_SUBSCRIBER_TRANSACTION,
  DELETE_SUBSCRIBER,
  DELETE_SUBSCRIBER_TRANSACTION,
  EDIT_SUBSCRIBER,
  GET_ALL_SUBSCRIBERS,
  GET_SINGLE_SUBSCRIBER,
  REGENERATE_SUBSCRIBER_TOKEN,
  UPDATE_SUBSCRIBER_TRANSACTION,
} from "./actionTypes";
import {
  getAllSubscribersSuccess,
  getAllSubscribersFailure,
  getSingleSubscriberSuccess,
  getSingleSubscriberFailure,
  deleteSubscriberSuccess,
  deleteSubscriberFailure,
  editSubscriberSuccess,
  editSubscriberFailure,
  addSubscriberSuccess,
  addSubscriberFailure,
  regenerateSubscriberTokenSuccess,
  regenerateSubscriberTokenFailure,
  createSubscriberTransactionSuccess,
  createSubscriberTransactionFailure,
  updateSubscriberTransactionSuccess,
  updateSubscriberTransactionFailure,
  deleteSubscriberTransactionSuccess,
  deleteSubscriberTransactionFailure,
} from "./actions";
import { createToaster } from "../toastify/actions";

const errorMessage = (error) =>
  error.response.data.errors[0].msg || error.response.data.errors || "error";

function* getAllSubscribers({ payload }) {
  try {
    const { data } = yield call(getAllSubscribersApi, payload);
    yield put(getAllSubscribersSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getAllSubscribersFailure(error.response.data || error));
  }
}

function* getSingleSubscriber({ payload }) {
  try {
    const { data } = yield call(getSingleSubscriberApi, payload);
    yield put(getSingleSubscriberSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleSubscriberFailure(error.response.data || error));
  }
}

function* addSubscriber({ payload }) {
  const id = Math.random();

  try {
    const { data } = yield call(addSubscriberApi, payload);
    yield put(addSubscriberSuccess(data));

    yield payload.navigate("/subscribers");
  } catch (error) {
    console.log(error);
    yield put(addSubscriberFailure(error.response.data.message || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

function* deleteSubscriber({ payload }) {
  const id = Math.random();

  try {
    yield call(deleteSubscriberApi, payload);
    yield put(deleteSubscriberSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteSubscriberFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

function* editSubscriber({ payload }) {
  const id = Math.random();

  try {
    yield call(editSubscriberApi, payload);
    yield put(editSubscriberSuccess(payload));

    yield payload.navigate("/subscribers");
  } catch (error) {
    console.log(error);
    yield put(editSubscriberFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

// token generation
function* regenerateSubscriberToken({ payload }) {
  const id = Math.random();

  try {
    yield call(regenerateSubscriberTokenApi, payload);
    yield put(regenerateSubscriberTokenSuccess(payload));

    yield payload.navigate("/subscribers");
  } catch (error) {
    console.log(error);
    yield put(regenerateSubscriberTokenFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

// subscriber transaction

function* createSubscriberTransaction({ payload }) {
  const id = Math.random();
  try {
    const { data } = yield call(createSubscriberTransactionApi, payload.data);
    yield put(createSubscriberTransactionSuccess(data));

    yield payload.navigate(
      `/subscribers/showsubscriber/${payload?.subscriberId}`
    );
  } catch (error) {
    console.log(error);
    yield put(
      createSubscriberTransactionFailure(error.response.data.message || error)
    );
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

function* updateSubscriberTransaction({ payload }) {
  const id = Math.random();

  try {
    yield call(updateSubscriberTransactionApi, payload);
    yield put(updateSubscriberTransactionSuccess(payload));
    yield payload.navigate(
      `/subscribers/showsubscriber/${payload?.subscriberId}`
    );
  } catch (error) {
    console.log(error);
    yield put(updateSubscriberTransactionFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

function* deleteSubscriberTransaction({ payload }) {
  const id = Math.random();

  try {
    yield call(deleteSubscriberTransactionApi, payload);
    yield put(deleteSubscriberTransactionSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteSubscriberTransactionFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

export function* watchGetAllSubscribers() {
  yield takeEvery(GET_ALL_SUBSCRIBERS, getAllSubscribers);
}
export function* watchGetSingleSubscriber() {
  yield takeEvery(GET_SINGLE_SUBSCRIBER, getSingleSubscriber);
}
export function* watchDeleteSubscriber() {
  yield takeEvery(DELETE_SUBSCRIBER, deleteSubscriber);
}
export function* watchAddSubscriber() {
  yield takeEvery(ADD_SUBSCRIBER, addSubscriber);
}
export function* watchEditSubscriber() {
  yield takeEvery(EDIT_SUBSCRIBER, editSubscriber);
}
export function* watchRegenerateSubscriberToken() {
  yield takeEvery(REGENERATE_SUBSCRIBER_TOKEN, regenerateSubscriberToken);
}

// subscriber transaction
export function* watchCreateSubscriberTransaction() {
  yield takeEvery(CREATE_SUBSCRIBER_TRANSACTION, createSubscriberTransaction);
}
export function* watchUpdateSubscriberTransaction() {
  yield takeEvery(UPDATE_SUBSCRIBER_TRANSACTION, updateSubscriberTransaction);
}
export function* watchDeleteSubscriberTransaction() {
  yield takeEvery(DELETE_SUBSCRIBER_TRANSACTION, deleteSubscriberTransaction);
}

function* subscribersSaga() {
  yield all([fork(watchGetAllSubscribers)]);
  yield all([fork(watchAddSubscriber)]);
  yield all([fork(watchEditSubscriber)]);
  yield all([fork(watchDeleteSubscriber)]);
  yield all([fork(watchGetSingleSubscriber)]);
  yield all([fork(watchRegenerateSubscriberToken)]);

  // transaction
  yield all([fork(watchCreateSubscriberTransaction)]);
  yield all([fork(watchUpdateSubscriberTransaction)]);
  yield all([fork(watchDeleteSubscriberTransaction)]);
}

export default subscribersSaga;
