export const GET_ALL_CONTACTS = "GET_ALL_CONTACTS";
export const GET_ALL_CONTACTS_SUCCESS = "GET_ALL_CONTACTS_SUCCESS";
export const GET_ALL_CONTACTS_FAILURE = "GET_ALL_CONTACTS_FAILURE";

export const GET_SINGLE_CONTACT = "GET_SINGLE_CONTACT";
export const GET_SINGLE_CONTACT_SUCCESS = "GET_SINGLE_CONTACT_SUCCESS";
export const GET_SINGLE_CONTACT_FAILURE = "GET_SINGLE_CONTACT_FAILURE";

export const ADD_CONTACT = "ADD_CONTACT";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_FAILURE = "ADD_CONTACT_FAILURE";

export const EDIT_CONTACT = "EDIT_CONTACT";
export const EDIT_CONTACT_SUCCESS = "EDIT_CONTACT_SUCCESS";
export const EDIT_CONTACT_FAILURE = "EDIT_CONTACT_FAILURE";

export const DELETE_CONTACT = "DELETE_CONTACT";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_FAILURE = "DELETE_CONTACT_FAILURE";
