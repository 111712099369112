import {
  GET_ALL_PERMISSIONS,
  GET_ALL_PERMISSIONS_SUCCESS,
  GET_ALL_PERMISSIONS_FAILURE,
  GET_SINGLE_PERMISSION,
  GET_SINGLE_PERMISSION_SUCCESS,
  GET_SINGLE_PERMISSION_FAILURE,
  DELETE_PERMISSION,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAILURE,
  ADD_PERMISSION,
  ADD_PERMISSION_SUCCESS,
  ADD_PERMISSION_FAILURE,
} from "./actionTypes";

const initialState = {
  permissions: [],
  singlePermission: {},
  loading: false,
  error: "",
};

const permissions = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_PERMISSIONS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ALL_PERMISSIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        permissions: payload.permissions,
      };
      break;
    case GET_ALL_PERMISSIONS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    case GET_SINGLE_PERMISSION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_PERMISSION_SUCCESS:
      state = {
        ...state,
        loading: false,
        singlePermission: payload.permission,
      };
      break;
    case GET_SINGLE_PERMISSION_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
        singlePermission: {},
      };
      break;

    case ADD_PERMISSION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_PERMISSION_SUCCESS:
      const permissionsArrayAfterAddingPermission = [
        ...state?.permissions,
        payload.permission,
      ];
      state = {
        ...state,
        loading: false,
        permissions: permissionsArrayAfterAddingPermission,
        error: "",
      };
      break;
    case ADD_PERMISSION_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    case DELETE_PERMISSION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_PERMISSION_SUCCESS:
      const permissionsArrayAfterDeletingPermission = [
        ...state?.permissions?.filter(
          (permission) => permission?.id !== payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        permissions: permissionsArrayAfterDeletingPermission,
      };
      break;
    case DELETE_PERMISSION_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default permissions;
