import {
  takeEvery,
  takeLatest,
  fork,
  put,
  all,
  call,
} from "redux-saga/effects";

// Import Api files
import {
  approveTransactionRequestApi,
  rejectTransactionRequestApi,
  getAllTransactionRequestsApi,
  getSingleTransactionRequestApi,
} from "api/transactionRequests";

import {
  APPROVE_TRANSACTION_REQUEST,
  REJECT_TRANSACTION_REQUEST,
  GET_ALL_TRANSACTION_REQUESTS,
  GET_SINGLE_TRANSACTION_REQUEST,
} from "./actionTypes";
import {
  getAllTransactionRequestsSuccess,
  getAllTransactionRequestsFailure,
  getSingleTransactionRequestSuccess,
  getSingleTransactionRequestFailure,
  approveTransactionRequestSuccess,
  approveTransactionRequestFailure,
  rejectTransactionRequestSuccess,
  getAllTransactionRequests,
} from "./actions";
import { createToaster } from "../toastify/actions";

const errorMessage = (error) =>
  error.response.data.errors[0].msg || error.response.data.errors || "error";

function* getAllTransactionRequestsSaga({ payload }) {
  try {
    const { data } = yield call(getAllTransactionRequestsApi, payload);
    yield put(getAllTransactionRequestsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getAllTransactionRequestsFailure(error.response.data || error));
  }
}

function* getSingleTransactionRequest({ payload }) {
  try {
    const { data } = yield call(getSingleTransactionRequestApi, payload);
    yield put(getSingleTransactionRequestSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleTransactionRequestFailure(error.response.data || error));
  }
}

function* approveTransactionRequest({ payload }) {
  const id = Math.random();

  try {
    const { data } = yield call(approveTransactionRequestApi, payload);
    yield put(approveTransactionRequestSuccess(data));
    yield put(getAllTransactionRequests());
    yield payload.navigate("/transactionRequests");
  } catch (error) {
    console.log(error);
    yield put(approveTransactionRequestFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}
function* rejectTransactionRequest({ payload }) {
  const id = Math.random();
  try {
    const { data } = yield call(rejectTransactionRequestApi, payload);
    yield put(rejectTransactionRequestSuccess(data));
    yield put(getAllTransactionRequests());

    yield payload.navigate("/transactionRequests");
  } catch (error) {
    console.log(error);
    yield put(approveTransactionRequestFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

export function* watchGetAllTransactionRequests() {
  yield takeEvery(GET_ALL_TRANSACTION_REQUESTS, getAllTransactionRequestsSaga);
}
export function* watchGetSingleTransactionRequest() {
  yield takeEvery(GET_SINGLE_TRANSACTION_REQUEST, getSingleTransactionRequest);
}

// approve transaction request
export function* watchApproveTransactionRequest() {
  yield takeLatest(APPROVE_TRANSACTION_REQUEST, approveTransactionRequest);
}

// reject transaction request
export function* watchBlockTransactionRequest() {
  yield takeLatest(REJECT_TRANSACTION_REQUEST, rejectTransactionRequest);
}

function* transactionRequestsSaga() {
  yield all([fork(watchGetAllTransactionRequests)]);

  yield all([fork(watchGetSingleTransactionRequest)]);

  yield all([fork(watchApproveTransactionRequest)]);
  yield all([fork(watchBlockTransactionRequest)]);
}

export default transactionRequestsSaga;
